import React from 'react'

import { useGlobalContext } from '../Context'

function Search() {
  const { query, setQuery } = useGlobalContext()
  return (
    <div className=' max-w-xs sm:max-w-md md:max-w-3xl lg:max-w-4xl xl:max-w-6xl 2xl:max-w-7xl w-60 sm:w-96'>
      <form
        action=''
        className='flex  justify-between items-center gap-1 sm:gap-3 border-b border-babyblack  w-full '
      >
        <input
          type='text'
          placeholder='search all products'
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          className=' font-sans outline-none placeholder:text-xs placeholder:text-center w-full text-xs sm:placeholder:text-sm sm:text-sm md:placeholder:text-base md:text-base lg:placeholder:text-lg lg:text-lg xl:placeholder:text-xl xl:text-xl '
        />
      </form>
    </div>
  )
}

export default Search
