import React, { Component } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Testimonies } from '../Utlis/Testimonies'
import { IoIosArrowBack } from 'react-icons/io'
import { IoIosArrowForward } from 'react-icons/io'

function SampleNextArrow(props) {
  const { style, onClick } = props
  return (
    <div
      className='w-10 h-10 absolute -right-10  md:-right-5 xl:-right-5 rounded-full flex justify-center items-center bg-babygreen z-20 '
      style={{
        ...style,
        display: 'flex',
        top: '50%',
        transform: 'translateY(-50%)',
      }}
      onClick={onClick}
    >
      <IoIosArrowForward className=' w-max text-babyblack text-bold ' />
    </div>
  )
}

function SamplePrevArrow(props) {
  const { style, onClick } = props
  return (
    <div
      className='w-10 h-10 absolute -left-10 md:-left-5 xl:-left-5 rounded-full flex justify-center items-center bg-babygreen z-20 '
      style={{
        ...style,
        display: 'flex',
        top: '50%',
        transform: 'translateY(-50%)',
      }}
      onClick={onClick}
    >
      <IoIosArrowBack className=' w-max text-babyblack text-bold ' />
    </div>
  )
}

export default class SimpleSlider extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1700,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 2,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 1500,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 790,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
          },
        },
      ],
    }

    return (
      <div className='w-full relative '>
        <Slider {...settings}>
          {Testimonies.map((item, index) => {
            return (
              <div key={index}>
                <div
                  key={index}
                  className='w-full mx-auto h-72 flex justify-center items-center z-10 pt-10'
                >
                  <div className='relative bg-white rounded-md shadow-md shadow-babyblack px-4 py-4 w-72 xl:w-80 xl:h-60 h-64 flex flex-col justify-center items-center  '>
                    {/* text */}
                    <div className='flex flex-col justify-center items-center space-y-3 mt-0 text-center'>
                      {/* boddy */}
                      <p className='text-xs text-center'>{item.description}</p>
                      {/* header */}
                      <div className=''>
                        <h1 className='text-sm lg:text-base font-bold text-babyblack'>
                          {item.name}
                        </h1>
                        <h2 className='text-xs font-medium text-babyblack'>
                          {' '}
                          {item.location}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </Slider>
      </div>
    )
  }
}
