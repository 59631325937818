import React, { useEffect } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { useGlobalContext } from '../Context'

function Brands() {
  const {
    setCurrentpage,
    displayedbrand,
    brandstate,
    setBrandstate,
    setHomestate,
    homestate,
  } = useGlobalContext()
  useEffect(() => {
    setCurrentpage('others')
  }, [setCurrentpage])

  return (
    <>
      <Header />
      {/* brands */}
      <section className='section-center space-y-6 md:space-y-12 xl:space-y-20 '>
        <div className='space-y-6 md:space-y-12 xl:space-y-20'>
          {/* header */}
          <div className='text-center space-y-2'>
            <h1 className='font-bold font-mono sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl'>
              Our Featured Products
            </h1>
            <p className='text-xs sm:text-sm md:text-base md:max-w-sm flex justify-center items-center mx-auto xl:text-lg lg:max-w-full '>
              Please filter through our list of featured products to get started
            </p>
          </div>
          {/* mobile tite */}
          <div className='border w-max  sm:w-full flex justify-center items-center mx-auto xl:hidden'>
            <select
              type='text'
              placeholder='Please select a brand'
              value={brandstate}
              onChange={(e) => setBrandstate(e.target.value)}
              className='px-2 py-2 md:py-3 flex justify-between items-center outline-none  mx-auto'
            >
              {/* <option value='Homepoint'>Please select a brand</option> */}
              <option value='Homepoint'> Homepoint Products</option>
              <option value='Pimak'>PiMAK Products</option>
              <option value='Dampak'>Dampak products</option>
              <option value='Bogazici'>Bogazici products</option>
              <option value='Domus'>Domus products</option>
              <option value='Iberital'> Iberital products</option>
            </select>
          </div>

          {/* title */}
          <div className='hidden xl:flex  justify-center gap-6 items-center w-full'>
            <button
              onClick={() => setBrandstate('Homepoint')}
              className={`${
                brandstate === 'Homepoint'
                  ? 'bg-babyblack shadow-xl text-white px-4 py-4 rounded '
                  : 'border border-babyblack px-4 py-4 rounded '
              }`}
            >
              Homepoint Products
            </button>
            <button
              onClick={() => setBrandstate('Pimak')}
              className={`${
                brandstate === 'Pimak'
                  ? 'bg-babyblack shadow-xl text-white px-4 py-4 rounded '
                  : 'border border-babyblack px-4 py-4 rounded '
              }`}
            >
              PiMAK Products
            </button>
            <button
              onClick={() => setBrandstate('Dampak')}
              className={`${
                brandstate === 'Dampak'
                  ? 'bg-babyblack shadow-xl text-white px-4 py-4 rounded '
                  : 'border border-babyblack px-4 py-4 rounded '
              }`}
            >
              Dampak products
            </button>
            <button
              onClick={() => setBrandstate('Bogazici')}
              className={`${
                brandstate === 'Bogazici'
                  ? 'bg-babyblack shadow-xl text-white px-4 py-4 rounded '
                  : 'border border-babyblack px-4 py-4 rounded '
              }`}
            >
              Bogazici products
            </button>
            <button
              onClick={() => setBrandstate('Domus')}
              className={`${
                brandstate === 'Domus'
                  ? 'bg-babyblack shadow-xl text-white px-4 py-4 rounded '
                  : 'border border-babyblack px-4 py-4 rounded '
              }`}
            >
              Domus products
            </button>
            <button
              onClick={() => setBrandstate('Iberital')}
              className={`${
                brandstate === 'Iberital'
                  ? 'bg-babyblack shadow-xl text-white px-4 py-4 rounded '
                  : 'border border-babyblack px-4 py-4 rounded '
              }`}
            >
              Iberital products
            </button>
          </div>
          {/* filter homepoint */}
          <div className='space-y-2'>
            {brandstate === 'Homepoint' && (
              <h1 className='text-xs md:text-sm text-center xl:text-left font-bold'>
                Filter homepoint products
              </h1>
            )}
            {brandstate === 'Homepoint' ? (
              <div className='border w-max sm:w-max flex justify-center items-center xl:mx-0 mx-auto '>
                <select
                  type='text'
                  placeholder='Please select a brand'
                  value={homestate}
                  onChange={(e) => setHomestate(e.target.value)}
                  className='px-2 py-2 md:py-3 flex justify-center items-center outline-none  mx-auto'
                >
                  {/* <option value='Homepoint'>Please select a brand</option> */}
                  <option value='CATERING'>CATERING / KITCHEN</option>
                  <option value='BAKERY'>BAKERY</option>
                  <option value='SUPERMARKET'>SUPERMARKET</option>
                  <option value='STAINLESS STEEL PRODUCTS'>
                    STAINLESS STEEL
                  </option>
                  <option value='LAUNDRY'> LAUNDRY</option>
                  <option value='NEPA POWER DISTRIBUTION TRANSFORMERS'>
                    TRANSFORMERS
                  </option>
                </select>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        {/* product */}
        <div className=' flex flex-col justify-center items-center space-y-6 sm:grid sm:grid-cols-2 sm:space-y-0 sm:gap-10 md:grid-cols-3 md:gap-10 xl:grid-cols-4'>
          {displayedbrand?.map((product) => {
            return (
              <div
                key={product.id}
                className='bg-white shadow-md px-4 py-6 flex  flex-col justify-center items-center space-y-6 w-60 sm:w-52 sm:h-96 md:w-60 md:h-[26rem]  lg:w-64 xl:w-64 '
              >
                {/* image */}
                <div className='  relative flex justify-center items-center'>
                  <img
                    src={product.image}
                    alt='product'
                    width={1000}
                    height={1000}
                    className='w-40  h-40 object-contain md:w-48 md:h-48 xl:w-48 '
                  />
                </div>
                {/* title */}
                <h1 className='font-bold text-xs text-center'>
                  {product.name}
                </h1>
                <h1 className='font-bold text-xs text-center text-babyblue'>
                  {product.brand} product
                </h1>
                <a
                  href={`/Singleproduct/${product.id}`}
                  className='bg-babyblack text-white text-xs px-6 py-2 flex justify-center items-center mx-auto rounded'
                >
                  {' '}
                  View Details
                </a>
              </div>
            )
          })}
        </div>
      </section>
      {/* footer */}
      <Footer />
    </>
  )
}

export default Brands
