import React, { useState, useContext, useEffect } from 'react'
import { Products } from './Utlis/Product'
const AppContext = React.createContext()
const AppProvider = ({ children }) => {
  const [currentpage, setCurrentpage] = useState('home')
  const [allproducts, setAllproducts] = useState(Products)
  const [theproducts, setTheproducts] = useState(Products)

  const [query, setQuery] = useState('')
  const [brandproducts, setBrandproducts] = useState([])
  const [displayedbrand, setDisplayedbrand] = useState([])
  const [brandstate, setBrandstate] = useState('Homepoint')
  const [homestate, setHomestate] = useState('CATERING')
  const [productpage, setProductpage] = useState(true)

  useEffect(() => {
    if (brandstate === 'Homepoint') {
      const filtered = allproducts.filter((item) => item.brand === 'Homepoint')
      setDisplayedbrand(
        filtered.filter((item) => item.category.trim() === homestate.trim())
      )
      console.log(
        filtered.filter((item) => item.category.trim() === homestate.trim())
      )
    } else {
      setDisplayedbrand(allproducts.filter((item) => item.brand === brandstate))
    }
  }, [brandstate, allproducts, homestate])

  return (
    <AppContext.Provider
      value={{
        currentpage,
        setCurrentpage,
        allproducts,
        displayedbrand,
        brandstate,
        setBrandstate,
        setDisplayedbrand,
        theproducts,
        setTheproducts,
        brandproducts,
        setBrandproducts,
        query,
        setQuery,
        setProductpage,
        productpage,
        setAllproducts,
        homestate,
        setHomestate,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}
export const useGlobalContext = () => {
  return useContext(AppContext)
}
export { AppContext, AppProvider }
