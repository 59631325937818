import React from 'react'
import { FaTiktok } from 'react-icons/fa'
import { FaInstagramSquare } from 'react-icons/fa'
import logo2 from '../images/logo2.png'
import { Link } from 'react-router-dom'
import thread2 from '../images/thread2.png'
function Footer() {
  return (
    <div className=' space-y-12 md:space-y-16 xl:space-y-20 py-10 bg-babyblack text-white'>
      {/* container */}
      <div className='px-4 md:px-6  lg:px-8 flex flex-col lg:flex-row justify-between  mx-auto space-y-10 lg:space-y-0 font-sans text-babyblack'>
        {/* logo */}
        <div className='relative flex flex-col justify-center items-center mx-auto space-y-3 lg:hidden'>
          <img
            src={logo2}
            alt='logo'
            width={1000}
            height={1000}
            className='object-cover w-24  '
          />
          <p className='font-black text-3xl text-center  text-white'>
            MightyKens <br />
            Intl Limited
          </p>
        </div>
        {/* group */}
        <div className='grid grid-cols-2 gap-x-14  sm:gap-x-24 gap-y-12 md:gap-x-24 md:gap-y-12 lg:gap-x-16 mx-auto lg:grid-cols-5 '>
          <div className='relative hidden flex-col justify-center items-center mx-auto space-y-3 lg:block'>
            <img
              src={logo2}
              alt='logo'
              width={1000}
              height={1000}
              className='object-cover w-24 mx-auto  '
            />
            <p className='font-black text-3xl text-center  text-white'>
              MightyKens <br />
              Intl Limited
            </p>
          </div>
          {/* contact Information */}
          <div className=' space-y-4 '>
            <h1 className='font-bold font-babyblack text-sm lg:text-lg text-white '>
              Office Address
            </h1>
            {/* hours */}
            <div className='space-y-2'>
              <p className='text-white text-xs lg:text-sm max-w-xs'>
                HOMEPOINT PLAZA ,KM 34
              </p>
              <p className='text-white text-xs lg:text-sm max-w-xs'>
                Lekki Epe Expressway
              </p>
              <p className='text-white text-xs lg:text-sm max-w-xs'>
                Oribanwa Phase II
              </p>
              <p className='text-white text-xs lg:text-sm max-w-xs'>
                Ibeju Lekki, Lagos
              </p>
            </div>
          </div>
          {/* social media Information */}
          <div className=' space-y-4 '>
            <h1 className='font-bold font-babyblack text-sm lg:text-lg text-white'>
              Contact Information
            </h1>
            {/* hours */}
            <div className='space-y-2 '>
              <p className='text-white text-xs lg:text-sm max-w-xs'>
                mightykens@gmail.com
              </p>
              <p className='text-white text-xs lg:text-sm max-w-xs'>
                +2347010347348
              </p>
              <p className='text-white text-xs lg:text-sm max-w-xs'>
                +2347065399592
              </p>
              <div className='flex justify-start items-center gap-4'>
                {/* instagram */}
                <a
                  href='https://instagram.com/mightykens56?igshid=MzNlNGNkZWQ4Mg=='
                  rel='noreferrer'
                  target='_blank'
                  className='block'
                >
                  <div className='flex items-center gap-2'>
                    <FaInstagramSquare className='text-white rounded-full text-sm lg:text-xl' />
                  </div>
                </a>
                {/* twitter */}
                <a
                  href='https://www.tiktok.com/@mightykens?_t=8dgjdRZ81Ti&_r=1'
                  rel='noreferrer'
                  target='_blank'
                  className='block'
                >
                  <div className='flex items-center gap-2'>
                    <FaTiktok className='text-white rounded-full text-sm lg:text-xl' />
                  </div>
                </a>
                {/* instagram */}
                <a
                  href='https://instagram.com/pimak_nigeria?igshid=MzNlNGNkZWQ4Mg=='
                  rel='noreferrer'
                  target='_blank'
                  className='block'
                >
                  <div className='flex items-center gap-2'>
                    <FaInstagramSquare className='text-white rounded-full text-sm lg:text-xl' />
                  </div>
                </a>
                {/* thread*/}
                <a
                  href='https://www.threads.net/@mightykens56'
                  rel='noreferrer'
                  target='_blank'
                  className='block'
                >
                  <div className='flex items-center gap-2'>
                    <img
                      src={thread2}
                      alt='logo'
                      width={1000}
                      height={1000}
                      className='object-cover w-4 lg:w-6 rounded '
                    />
                  </div>
                </a>
              </div>
            </div>
          </div>
          {/* office address */}
          <div className='  space-y-4 '>
            <h1 className=' font-bold font-babyblack text-sm lg:text-lg text-white'>
              Office Hours
            </h1>
            {/* hours */}
            <div className='space-y-2'>
              <p className='text-white text-xs lg:text-sm max-w-xs'>
                8am-5pm Weekdays
              </p>
              <p className='text-white text-xs lg:text-sm max-w-xs'>
                10am - 5pm Saturdays
              </p>
              <p className='text-white text-sm max-w-xs'>Closed - Sundays</p>
            </div>
          </div>

          {/* Quick links */}
          <div className=' space-y-4  '>
            <h1 className='font-bold font-babyblack text-sm lg:text-lg text-white'>
              Quick Links
            </h1>
            {/* hours */}
            <div className='space-y-2 text-white'>
              <p className='text-white text-xs lg:text-sm max-w-xs cursor-pointer '>
                <Link to='/Aboutus'>About US</Link>
              </p>

              <p className='text-white text-xs lg:text-sm max-w-xs'>
                <Link to='/Allproducts'>All Products</Link>
              </p>
              <p className='text-white text-xs lg:text-sm max-w-xs'>
                <Link to='/Brands'>Brands</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* divider */}
      {/* <div className='bg-babyblue h-[0.5px] w-full'></div> */}
      {/* date */}
      <div className='text-center text-white font-sans max-w-xs md:max-w-full flex flex-col justify-center mx-auto space-y-2 '>
        <p className='text-xs'>
          {' '}
          copyright @ 2022- {new Date().getFullYear()} MightyKens All Rights
          Reserved
        </p>
      </div>
    </div>
  )
}

export default Footer
