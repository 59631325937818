export const Testimonies = [
  {
    id: 1,
    name: 'Mercy Bassey',
    location: 'Ikot Ekpene, Uyo',
    description: `“I've been a customer of MightyKen for years, and they never disappoint. The wide range of products, competitive prices, and exceptional customer service keep me coming back. I recently purchased a new industrial oven , and it exceeded my expectations. I highly recommend MightyKen to anyone looking for quality electronics”`,
  },
  {
    id: 2,
    name: 'Oge Eze',
    location: 'AAgbani, Enugu',

    description: `“I was searching for a reliable online electronics store, and I stumbled upon yours. The website was easy to navigate, and the ordering process was seamless. I purchased a PIMAK COMMERCIAL GAS STOCK POT and was impressed with the fast shipping and careful packaging. The product arrived in perfect condition. t”`,
  },
  {
    id: 3,
    name: 'Edoh Chinedu',
    location: 'Ojota, Lagos',

    description: `“I can't say enough good things about MightyKen I recently visited their physical store, and the customer service was exceptional. The staff members were friendly, patient, and went above and beyond to help me find the right ELECTRIC GRIDDLE for my business They even offered valuable advice on setting it up. ”`,
  },
  {
    id: 4,
    name: 'Akinlolu Awodiran',
    location: 'Akure , Ondo',

    description: `“I've been a loyal customer of MightyKens for several years now, and I keep coming back because of the exceptional service and quality products they offer. Whether I need power tools, baking equipment, or Cooking machinery, I can always rely on this store to have what I need. Keep it up guys ”`,
  },
  {
    id: 5,
    name: 'Bako Ibrahim',
    location: 'Sango, Kaduna',

    description: `“I can't say enough good things about MightyKens. They have an incredible selection of machines and equipment for all kinds of industries. The store is well-organized, making it easy to find what you're looking for. The staff is friendly and always willing to assist with any questions or concerns. Plus, the prices are competitive, and the quality of the products is outstanding. ”`,
  },
  {
    id: 6,
    name: 'Boluwatife Akhindele',
    location: 'Ph, Rivers',

    description: `“This store is a hidden gem for all your industrial home equipment. I was in search of a reliable Oven for my Bakery, and I found exactly what I needed here. The staff was friendly and provided excellent customer service, helping me choose the right model within my budget. The Oven has been a game-changer for my confectionaries, and I'm extremely satisfied with my purchase. I highly recommend this store for its wide range of quality equipment and outstanding service.”`,
  },
]
