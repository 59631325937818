import React, { useEffect, useState } from 'react'
import { useGlobalContext } from '../Context'
import Header from '../components/Header'
import { FiSearch } from 'react-icons/fi'
import { AiOutlineSelect } from 'react-icons/ai'
import { MdOutlineConnectWithoutContact } from 'react-icons/md'
import Footer from '../components/Footer'
import { Link } from 'react-scroll'
import ReactPlayer from 'react-player/lazy'
import { useNavigate } from 'react-router-dom'
import hero from '../images/hero.jpeg'
import brand1 from '../images/brand1.png'
import brand22 from '../images/brand22.png'
import brand3 from '../images/brand3.png'
import brand4 from '../images/brand4.png'
import brand5 from '../images/brand5.png'
import brand6 from '../images/brand6.png'
import brand7 from '../images/brand7.png'
import shipping from '../images/shipping.png'
import migt from '../videos/migt.mp4'

export default function Home() {
  const [domLoaded, setDomLoaded] = useState(false)
  const navigate = useNavigate()
  const { setCurrentpage, setBrandstate, allproducts } = useGlobalContext()
  useEffect(() => {
    setCurrentpage('home')
    setDomLoaded(true)
  }, [setCurrentpage])

  return (
    <>
      {/* hero */}
      <section className='   h-96 sm:h-[26em] md:h-[34rem] relative  lg:h-[38rem] xl:h-[40rem] '>
        <img
          src={hero}
          alt='logo'
          width={1000}
          height={1000}
          className=' w-full h-96 sm:h-[26em] md:h-[34rem] lg:h-[38rem] xl:h-[40rem]  '
        />
        <div className=' absolute top-0 left-0 bottom-0 right-0 bg-black bg-opacity-70'>
          <Header />
          <div className='text-white mt-6 md:mt-10 lg:mt-14 flex flex-col justify-center items-center space-y-6'>
            <div className='space-y-1 md:space-y-1'>
              <p className='font-sans text-xs sm:text-sm text-center md:text-base lg:text-lg xl:text-xl '>
                Welcome to
              </p>
              <h1 className='font-mono text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl text-center md:leading-[4rem] lg:leading-[5rem] xl:leading-[6rem]'>
                MightyKens <br />
                International Ltd
              </h1>
              <p className='font-sans text-center flex justify-center items-center mx-auto text-xs sm:text-sm max-w-[14rem]  sm:max-w-[18rem] md:max-w-sm  md:text-base  lg:text-xl xl:text-2xl xl:max-w-md '>
                Your one-stop shop for all the industrial machinery you will
                every need
              </p>
            </div>

            <button className='font-sans px-4 py-2 bg-white text-babyblack rounded-sm font-bold text-xs sm:text-sm md:px-5 md:py-3 lg:text-base xl:text-lg xl:rounded-md  xl:py-3 xl:px-6'>
              <Link
                activeClass='active'
                to='shopping'
                spy={true}
                smooth={true}
                offset={-90}
                duration={500}
              >
                Start Shopping
              </Link>
            </button>
          </div>
        </div>
      </section>
      {/* how to get started */}
      <section className='section-center space-y-6 md:space-y-12 xl:space-y-20'>
        {/* header */}
        <div className='text-center space-y-2'>
          <h1 className='font-bold font-mono sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl'>
            How to get Started
          </h1>
          <p className='text-xs sm:text-sm md:text-base md:max-w-sm flex justify-center items-center mx-auto xl:text-lg lg:max-w-md '>
            Here’s a quick guide on how to purchase your first product with us
          </p>
        </div>
        {/* how */}
        <div className='flex flex-col justify-center items-center space-y-6 sm:grid sm:grid-cols-2 sm:space-y-0 sm:gap-6 md:grid-cols-3'>
          {/* how1 */}
          <div className='bg-white shadow-md px-4 py-6 space-y-2 w-60 lg:w-64 xl:w-full  sm:w-52 flex flex-col justify-center items-center'>
            {/* icon */}
            <div className='bg-babyblack  w-10 h-10 rounded-full flex justify-center items-center'>
              <FiSearch className='text-white text-xl' />
            </div>
            <h1 className='font-bold text-lg lg:text-xl text-center '>
              Browse
            </h1>
            <p className='text-center text-xs w-40 xl:w-full lg:text-base  '>
              Browse through all our products listed on our platform
            </p>
          </div>
          {/* how2 */}
          <div className='bg-white shadow-md px-4 py-6 space-y-2 w-60 sm:w-52 lg:w-64 xl:w-full flex flex-col justify-center items-center'>
            {/* icon */}
            <div className='bg-babyblack  w-10 h-10 rounded-full flex justify-center items-center'>
              <AiOutlineSelect className='text-white text-xl' />
            </div>
            <h1 className='font-bold text-lg text-center lg:text-xl'>
              Select a Product
            </h1>
            <p className='text-center text-xs w-full lg:text-base '>
              Identify a product you are particularly interested in from our
              platform
            </p>
          </div>
          {/* how3 */}
          <div className='bg-white shadow-md px-4 py-6 space-y-2 w-60 sm:w-52 lg:w-64 xl:w-full  flex flex-col justify-center items-center'>
            {/* icon */}
            <div className='bg-babyblack  w-10 h-10 rounded-full flex justify-center items-center'>
              <MdOutlineConnectWithoutContact className='text-white text-xl' />
            </div>
            <h1 className='font-bold text-lg lg:text-xl text-center'>
              Reach out to Us
            </h1>
            <p className='text-center text-xs lg:text-base w-40 lg:w-full'>
              Reach out to us via our WhatsApp quick chat and an agent will
              respond
            </p>
          </div>
        </div>
      </section>
      {/* showroom */}
      <section className='section-center space-y-6 md:space-y-12 xl:space-y-20'>
        {/* header */}
        <div className='text-center space-y-2'>
          <h1 className='font-bold font-mono sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl'>
            Explore our Showroom
          </h1>
          <p className='text-xs sm:text-sm md:text-base md:max-w-sm flex justify-center items-center mx-auto xl:text-lg lg:max-w-md '>
            Here's a short video of our showroom
          </p>
        </div>
        {/* title & content */}
        {domLoaded && (
          <div className='player-wrapper '>
            <ReactPlayer
              className='react-player  '
              url={migt}
              width='100%'
              height='50%'
              controls={true}
              playing={true}
              light={
                <img
                  src={hero}
                  alt='Thumbnail'
                  width={1000}
                  height={1000}
                  className='w-full h-36 sm:h-44  md:h-72 lg:h-96 xl:h-[26rem]  object-cover object-center'
                />
              }
            />
          </div>
        )}
      </section>
      {/* Products */}
      <section
        id='shopping'
        className='section-center space-y-6 md:space-y-12 xl:space-y-20'
      >
        {/* header */}
        <div className='text-center space-y-2'>
          <h1 className='font-bold font-mono sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl'>
            Our List of Products
          </h1>
          <p className='text-xs sm:text-sm md:text-base md:max-w-sm flex justify-center items-center mx-auto xl:text-lg lg:max-w-md '>
            Take a quick glance through our product list and find a product that
            meets your demand today
          </p>
        </div>
        {/* title & content */}
        <div className='space-y-10 sm:space-y-12 md:space-y-14 lg:space-y-16 xl:space-y-20 '>
          {/* product */}
          <div className=' flex flex-col justify-center items-center space-y-6 sm:grid sm:grid-cols-2 sm:space-y-0 sm:gap-10 md:grid-cols-3 md:gap-10 xl:grid-cols-4'>
            {allproducts
              ?.sort((a, b) => (a.name > b.name ? 1 : -1))
              .slice(0, 8)
              .map((product) => {
                return (
                  <div
                    key={product.id}
                    className='bg-white shadow-md px-4 py-6 flex  flex-col justify-center items-center space-y-6 w-60 sm:w-52 sm:h-96 md:w-60 md:h-[26rem]  lg:w-64 xl:w-64 '
                  >
                    {/* image */}
                    <div className='  relative flex justify-center items-center'>
                      <img
                        src={product.image}
                        alt='product'
                        width={1000}
                        height={1000}
                        className='w-40  h-40 object-contain md:w-48 md:h-48 xl:w-48 '
                      />
                    </div>
                    {/* title */}
                    <h1 className='font-bold text-xs text-center'>
                      {product.name}
                    </h1>
                    <h1 className='font-bold text-xs text-center text-babyblue'>
                      {product.brand} product
                    </h1>
                    <a
                      href={`/Singleproduct/${product.id}`}
                      className='bg-babyblack text-white text-xs px-6 py-2 flex justify-center items-center mx-auto rounded'
                    >
                      {' '}
                      View Details
                    </a>
                  </div>
                )
              })}
          </div>
          <button
            onClick={() => {
              navigate('/Allproducts')
            }}
            className='bg-babyblack flex justify-center items-center mx-auto text-white  px-4 py-2 text-xs rounded sm:px-6 sm:py-3  cursor-pointer md:px-10 font-bold '
          >
            View All
          </button>
        </div>
      </section>
      {/* brands */}
      <section className='section-center space-y-6 md:space-y-12 xl:space-y-20'>
        {/* header */}
        <div className='text-center space-y-2'>
          <h1 className='font-bold font-mono sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl'>
            Explore by Brand
          </h1>
          <p className='text-xs sm:text-sm md:text-base md:max-w-sm flex justify-center items-center mx-auto xl:text-lg lg:max-w-md '>
            Sort quickly through our products by filtering through our available
            brands
          </p>
        </div>
        {/* brands */}
        <div className='flex flex-col justify-center items-center space-y-6 sm:space-y-0 sm:grid sm:grid-cols-2  sm:gap-6 md:grid-cols-3 mx-auto'>
          {/* brand1 */}
          <div
            onClick={() => {
              setBrandstate('Homepoint')
              navigate(`/Brands`)
            }}
            className='  cursor-pointer relative flex justify-center items-center w-60 sm:w-52 md:w-56 lg:w-60  xl:w-80  group/item'
          >
            <img
              src={brand1}
              alt='logo'
              width={1000}
              height={1000}
              className='   '
            />
            <div className='absolute top-0 left-0 right-0 bottom-0 bg-babyblack bg-opacity-60 flex justify-start px-2 py-2 text-white items-end invisible group-hover/item:visible rounded-md'>
              <h1>Homepoint products</h1>
            </div>
          </div>
          {/* brand2 */}
          <div
            onClick={() => {
              setBrandstate('Pimak')
              navigate(`/Brands`)
            }}
            className=' cursor-pointer relative flex justify-center items-center w-60 sm:w-52 md:w-56 lg:w-60 xl:w-80  group/item'
          >
            <img
              src={brand22}
              alt='logo'
              width={1000}
              height={1000}
              className='   '
            />
            <div className='absolute top-0 left-0 right-0 bottom-0 bg-babyblack bg-opacity-60 flex justify-start px-2 py-2 text-white items-end invisible group-hover/item:visible rounded-md'>
              <h1>PiMAK products</h1>
            </div>
          </div>
          {/* brand3 */}
          <div
            onClick={() => {
              setBrandstate('Dampak')
              navigate(`/Brands`)
            }}
            className=' cursor-pointer  relative flex justify-center items-center w-60 sm:w-52 md:w-56 lg:w-60 xl:w-80  group/item'
          >
            <img
              src={brand3}
              alt='logo'
              width={1000}
              height={1000}
              className='   '
            />
            <div className='absolute top-0 left-0 right-0 bottom-0 bg-babyblack bg-opacity-60 flex justify-start px-2 py-2 text-white items-end invisible group-hover/item:visible rounded-md '>
              <h1>Dampak products</h1>
            </div>
          </div>
          {/* brand4 */}
          <div
            onClick={() => {
              setBrandstate('Bogazici')
              navigate(`/Brands`)
            }}
            className='  cursor-pointer relative flex justify-center items-center w-60 sm:w-52 md:w-56 lg:w-60 xl:w-80  group/item'
          >
            <img
              src={brand4}
              alt='logo'
              width={1000}
              height={1000}
              className='   '
            />
            <div className='absolute top-0 left-0 right-0 bottom-0 bg-babyblack bg-opacity-60 flex justify-start px-2 py-2 text-white items-end invisible group-hover/item:visible rounded-md '>
              <h1>Bogazici products</h1>
            </div>
          </div>
          {/* brand5 */}
          <div
            onClick={() => {
              setBrandstate('Domus')
              navigate(`/Brands`)
            }}
            className=' cursor-pointer  relative flex justify-center items-center w-60 sm:w-52 md:w-56 lg:w-60  xl:w-80 group/item'
          >
            <img
              src={brand5}
              alt='logo'
              width={1000}
              height={1000}
              className='   '
            />
            <div className='absolute top-0 left-0 right-0 bottom-0 bg-babyblack bg-opacity-60 flex justify-start px-2 py-2 text-white items-end invisible group-hover/item:visible rounded-md '>
              <h1>Domus products</h1>
            </div>
          </div>
          {/* brand6 */}
          <div
            onClick={() => {
              setBrandstate('Iberital')
              navigate(`/Brands`)
            }}
            className=' cursor-pointer relative flex justify-center items-center w-60 sm:w-52 md:w-56 lg:w-60 xl:w-80 group/item'
          >
            <img
              src={brand6}
              alt='logo'
              width={1000}
              height={1000}
              className='   '
            />
            <div className='absolute top-0 left-0 right-0 bottom-0 bg-babyblack bg-opacity-60 flex justify-start px-2 py-2 text-white items-end invisible group-hover/item:visible rounded-md '>
              <h1>Iberital products</h1>
            </div>
          </div>
          {/* brand7 */}
          <div
            onClick={() => {
              navigate(`/Aboutus`)
            }}
            className=' cursor-pointer  relative flex justify-center items-center w-60 sm:w-52 md:w-56 lg:w-60 xl:w-80 group/item'
          >
            <img
              src={brand7}
              alt='logo'
              width={1000}
              height={1000}
              className='   '
            />

            <div className='absolute top-0 left-0 right-0 bottom-0 bg-babyblack bg-opacity-60 flex justify-start px-2 py-2 text-white items-end invisible group-hover/item:visible rounded-md '>
              <h1>Our Shipping Brand</h1>
            </div>
          </div>
        </div>
      </section>
      {/* shipping */}
      <section className='section-center space-y-6 md:space-y-12 xl:space-y-20'>
        {/* header */}
        <div className='text-center space-y-2'>
          <h1 className='font-bold font-mono sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl'>
            Shipping Services
          </h1>
          <p className='text-xs sm:text-sm md:text-base md:max-w-sm flex justify-center items-center mx-auto xl:text-lg lg:max-w-md '>
            We offer groupage and shipping services
          </p>
        </div>
        {/* content */}
        <div
          data-aos='zoom-in-down'
          data-aos-duration='2000'
          data-aos-delay='100'
          className=' overflow-x-hidden my-20 space-y-10 md:flex md:justify-between md:items-center md:space-y-0 md:gap-10 lg:gap-14 xl:gap-20'
        >
          {/* image */}
          <div className=' relative md:w-1/2'>
            <img
              src={shipping}
              alt='logo'
              width={1000}
              height={1000}
              className='w-72 rounded-lg md:rounded-xl  sm:w-full md:w-full max-w-xs sm:max-w-md  md:max-w-2xl xl:max-w-md mx-auto shadow-sm '
            />
          </div>
          {/* text */}
          <div className='font-sans flex flex-col justify-center items-center mx-auto md:mx-0 md:items-start space-y-6  md:w-1/2 '>
            {/* paragraph */}
            <p className='text-babyblack text-xs text-justify  sm:text-sm md:text-sm lg:text-base  md:text-left  xl:text-lg xl:max-w-md'>
              Effortlessly ship your products with us Today!. We specialize in
              streamlining your shipping process, ensuring your items reach
              their destination safely and on time. Say goodbye to logistics
              headaches and hello to seamless shipping solutions. Let us handle
              the details while you focus on growing your business. Discover the
              power of hassle-free shipping with MightyKens International
              Limited today!
            </p>
            <button className='bg-babyblack text-white px-6 py-2 text-xs  md:text-sm  xl:text-base sm:px-8 sm:py-3 rounded shadow flex justify-center items-center mx-auto  md:mx-0  md:py-2 md:px-8 xl:py-3 md:shadow-md  xl:px-10 xl:rounded-md '>
              <a
                href='https://wa.me/2347065399592'
                target='_blank'
                rel='noopener noreferrer'
              >
                Get in touch
              </a>
            </button>
          </div>
        </div>
      </section>
      {/* Footer */}
      <Footer />
    </>
  )
}
