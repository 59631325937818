import React, { useState, useEffect } from 'react'
import { useGlobalContext } from '../Context'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Search from '../components/Search'

function Allproducts() {
  const [brand, setBrand] = useState('')
  const [loading, setLoading] = useState(true)
  const [searchedproducts, setSearchedproducts] = useState([])

  const {
    setCurrentpage,
    theproducts,
    query,
    setBrandproducts,
    brandproducts,
  } = useGlobalContext()
  useEffect(() => {
    setCurrentpage('others')
    if (!searchedproducts.length > 0) {
      setTimeout(() => {
        setLoading(false)
      }, 4000)
    }
  }, [searchedproducts, setCurrentpage])

  useEffect(() => {
    if (query.trim() !== '') {
      let searchedData = theproducts.filter((item) =>
        item.name.toLowerCase().includes(query.toLowerCase())
      )
      setSearchedproducts(searchedData)
    } else {
      setSearchedproducts(theproducts)
    }
  }, [query, theproducts])

  useEffect(() => {
    if (brand !== '') {
      const filteredData = searchedproducts.filter(
        (item) => item.brand === brand
      )
      setBrandproducts(filteredData)
    } else {
      setBrandproducts(searchedproducts)
    }
  }, [brand, searchedproducts, setBrandproducts])

  return (
    <>
      <Header />
      <section className='section-center space-y-6 md:space-y-12 xl:space-y-20'>
        {/* title */}
        <div className='space-y-10  flex flex-col justify-center  md:flex-row md:justify-between items-center md:space-y-0'>
          <div className='flex justify-center items-center'>
            <Search />
          </div>

          <div className=' border md:border-babyblack  sm:max-w-sm lg:max-w-md md:w-60   '>
            <select
              type='text'
              placeholder='Please select a brand'
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
              className=':px-2 py-1 sm:py-1 md:py-3 flex justify-between items-center   outline-none  mx-auto'
            >
              <option value=''>Filter by brand</option>
              <option value='Homepoint'> Homepoint Products</option>
              <option value='Pimak'>PiMAK Products</option>
              <option value='Dampak'>Dampak products</option>
              <option value='Bogazici'>Bogazici products</option>
              <option value='Domus'>Domus products</option>
              <option value='Iberital'> Iberital products</option>
            </select>
          </div>
        </div>
        {/* product */}
        {brandproducts.length > 0 ? (
          <div className=' flex flex-col justify-center items-center space-y-6 sm:grid sm:grid-cols-2 sm:space-y-0 sm:gap-10 md:grid-cols-3 md:gap-10 xl:grid-cols-4'>
            {brandproducts
              ?.sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((product) => {
                return (
                  <div
                    key={product.id}
                    className='bg-white shadow-md px-4 py-6 flex  flex-col justify-center items-center space-y-6 w-60 sm:w-52 sm:h-96 md:w-60 md:h-[26rem]  lg:w-64 xl:w-64 '
                  >
                    {/* image */}
                    <div className='  relative flex justify-center items-center'>
                      <img
                        src={product.image}
                        alt='product'
                        width={1000}
                        height={1000}
                        className='w-40  h-40 object-contain md:w-40 xl:w-40 '
                      />
                    </div>
                    {/* title */}
                    <h1 className='font-bold text-xs text-center'>
                      {product.name}
                    </h1>
                    <h1 className='font-bold text-xs text-center text-babyblue'>
                      {product.brand} product
                    </h1>

                    <a
                      href={`/Singleproduct/${product.id}`}
                      className='bg-babyblack text-white text-xs px-6 py-2 flex justify-center items-center mx-auto rounded'
                    >
                      {' '}
                      View Details
                    </a>
                  </div>
                )
              })}
          </div>
        ) : loading ? (
          <div className='flex justify-center items-center pt-10 sm:pt-20 mx-auto'>
            <div className='lds-ring  '>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        ) : (
          <div className='flex justify-center items-center pt-10 mx-auto'>
            <h1 className='text-center text-xs text-red-500 sm:text-sm font-bold lg:text-base'>
              {' '}
              We do not have the product your are looking ! <br />
              please update your search parameter{' '}
            </h1>
          </div>
        )}
      </section>
      <Footer />
    </>
  )
}

export default Allproducts
