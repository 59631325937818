import React, { useEffect } from 'react'
import Header from '../components/Header'
import { useGlobalContext } from '../Context'
import { MdProductionQuantityLimits } from 'react-icons/md'
import { ImPriceTags } from 'react-icons/im'
import { MdOutlineSupportAgent } from 'react-icons/md'
import { SiTrustpilot } from 'react-icons/si'
import { GiConvergenceTarget } from 'react-icons/gi'
import { BsFillPersonCheckFill } from 'react-icons/bs'
import { HiPhoneMissedCall } from 'react-icons/hi'
import { ImLocation2 } from 'react-icons/im'
import { MdMarkEmailUnread } from 'react-icons/md'
import Footer from '../components/Footer'
import Carousel from '../components/Carousel'
import about from '../images/about.png'
import shipping from '../images/shipping.png'
import ship from '../images/ship.png'
import why10 from '../images/why10.png'
import why100 from '../images/why100.png'
import why101 from '../images/why101.png'
import why9 from '../images/why9.png'
import why2 from '../images/why2.png'
import why11 from '../images/why11.png'
import why1 from '../images/why1.png'
import why3 from '../images/why3.png'
import why4 from '../images/why4.png'
import why5 from '../images/why5.png'
import why66 from '../images/why66.png'
import why88 from '../images/why88.png'
import why8 from '../images/why8.png'
import why777 from '../images/why777.png'
import why7 from '../images/why7.png'
function Aboutus() {
  const { setCurrentpage } = useGlobalContext()
  useEffect(() => {
    setCurrentpage('home')
  }, [setCurrentpage])
  return (
    <>
      {/* hero */}
      <section className='   h-96 sm:h-[26em] md:h-[34rem] lg:h-[38rem] xl:h-[40rem] relative'>
        <img
          src={about}
          alt='logo'
          width={1000}
          height={1000}
          className=' bg-center bg-no-repeat bg-cover w-full h-96 sm:h-[26em] md:h-[34rem] lg:h-[38rem] xl:h-[40rem]  '
        />
        <div className=' absolute top-0 left-0 bottom-0 right-0 bg-black bg-opacity-70'>
          <Header />
          <div className='text-white mt-8 md:mt-16 lg:mt-24 flex flex-col justify-center items-center space-y-2 md:space-y-4 xl:space-y-6'>
            <h1 className='font-mono  text-lg sm:text-xl md:text-3xl lg:text-4xl xl:text-5xl'>
              Who are we?
            </h1>
            <div className='space-y-1 sm:space-y-2 md:space-y-3 max-w-sm sm:max-w-sm md:max-w-md  lg:max-w-xl xl:max-w-3xl text-center border border-white px-3 py-6 s'>
              <p className='text-sm sm:text-base text-center md:text-lg lg:text-2xl xl:text-3xl '>
                Importer and distributor of all kinds of industrial machines
                such as: bakery, kitchen, laundry, catering, supermarket, power
                distribution transformers etc. <br />
                We welocme you to purchase from us today.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Wy purchase from us */}
      <section className='section-center space-y-6 md:space-y-12 xl:space-y-20'>
        {/* header */}
        <div className='text-center space-y-2'>
          <h1 className='font-bold font-mono sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl'>
            Why Purchase from Us
          </h1>
          <p className='text-xs sm:text-sm md:text-base md:max-w-sm flex justify-center items-center mx-auto xl:text-lg lg:max-w-md '>
            Here are reasons why you would want to purchase your next product
            from us
          </p>
        </div>
        {/* content */}
        <div
          data-aos='zoom-in-down'
          data-aos-duration='2000'
          data-aos-delay='100'
          className='mx-auto  flex flex-col justify-center space-y-12 md:grid md:grid-cols-2 xl:grid-cols-3 md:space-y-0  md:gap-x-4 md:gap-y-8 lg:gap-x-6 lg:gap-y-10 w-full xl:gap-x-8 xl:gap-y-14'
        >
          {/* cont1 */}
          <div className='flex flex-col justify-center items-center  space-y-6 mx-auto md:flex-row md:items-start md:space-y-0 md:gap-4 w-full'>
            {/* icon */}
            <div className=' px-4 py-4 bg-babyblack rounded-full flex justify-center items-cente '>
              <MdProductionQuantityLimits className=' text-white  text-base xl:text-2xl text-center mx-auto' />
            </div>
            {/* text */}
            <div className='space-y-2 text-center  md:text-left'>
              <h3 className=' font-bold text-base sm:text-lg'>
                Wide range of products
              </h3>
              <h5 className='text-xs max-w-xs leading-5 sm:text-sm'>
                MightyKen offers a diverse range of products, catering to
                various needs and preferences of customers. This variety
                increases the likelihood of customers finding exactly what they
                need.
              </h5>
            </div>
          </div>
          {/* cont2 */}
          <div className='flex flex-col justify-center items-center  space-y-6 mx-auto md:flex-row md:items-start md:space-y-0 md:gap-4 w-full'>
            {/* icon */}
            <div className=' px-4 py-4 bg-babyblack rounded-full flex justify-center items-center '>
              <ImPriceTags className=' text-white  text-base xl:text-2xl text-center mx-auto' />
            </div>
            {/* text */}
            <div className='space-y-2 text-center  md:text-left'>
              <h3 className=' font-bold text-base sm:text-lg'>
                Competitive Pricing
              </h3>
              <h5 className='text-xs max-w-xs leading-5 sm:text-sm'>
                We price our products at a fair and reasonable amount so you do
                not have to break the bank in order to get the appliance you
                truly desire
              </h5>
            </div>
          </div>
          {/* cont3 */}
          <div className='flex flex-col justify-center items-center  space-y-6 mx-auto md:flex-row md:items-start md:space-y-0 md:gap-4 w-full'>
            {/* icon */}
            <div className=' px-4 py-4 bg-babyblack rounded-full flex justify-center items-center  '>
              <MdOutlineSupportAgent className=' text-white  text-base xl:text-2xl text-center mx-auto' />
            </div>
            {/* text */}
            <div className='space-y-2 text-center  md:text-left'>
              <h3 className=' font-bold text-base sm:text-lg'>
                Warranty and After-Sales Support
              </h3>
              <h5 className='text-xs max-w-xs leading-5 sm:text-sm'>
                We offer warranty options and reliable after-sales support.
                Thereby giving customers peace of mind knowing that they are
                covered if any issues arise with their purchased products.
              </h5>
            </div>
          </div>
          {/* cont4 */}
          <div className='flex flex-col justify-center items-center  space-y-6 mx-auto md:flex-row md:items-start md:space-y-0 md:gap-4 w-full'>
            {/* icon */}
            <div className=' px-4 py-4 bg-babyblack rounded-full flex justify-center items-center  '>
              <SiTrustpilot className=' text-white  text-base xl:text-2xl text-center mx-auto' />
            </div>
            {/* text */}
            <div className='space-y-2 text-center  md:text-left'>
              <h3 className=' font-bold text-base sm:text-lg'>Reputation</h3>
              <h5 className='text-xs max-w-xs leading-5 sm:text-sm'>
                Over the years, MightyKen has built a high reputation in the
                electronics industry that various customers of ours can attest
                to. Purchasing from us welcomes you to this group of satisfied
                customers
              </h5>
            </div>
          </div>
          {/* cont5 */}
          <div className='flex flex-col justify-center items-center  space-y-6 mx-auto md:flex-row md:items-start md:space-y-0 md:gap-4 w-full'>
            {/* icon */}
            <div className=' px-4 py-4 bg-babyblack rounded-full flex justify-center items-center  '>
              <GiConvergenceTarget className=' text-white  text-base xl:text-2xl text-center mx-auto' />
            </div>
            {/* text */}
            <div className='space-y-2 text-center  md:text-left'>
              <h3 className=' font-bold text-base sm:text-lg'>
                Convenient Shopping Experience
              </h3>
              <h5 className='text-xs max-w-xs leading-5 sm:text-sm'>
                We always focus on providing a convenient and hassle-free
                shopping experience. This includes user-friendly online
                platforms for browsing and purchasing, and fast and reliable
                shipping
              </h5>
            </div>
          </div>
          {/* cont6 */}
          <div className='flex flex-col justify-center items-center  space-y-6 mx-auto md:flex-row md:items-start md:space-y-0 md:gap-4 w-full'>
            {/* icon */}
            <div className=' px-4 py-4 bg-babyblack rounded-full flex justify-center items-center  '>
              <BsFillPersonCheckFill className=' text-white  text-base xl:text-2xl text-center mx-auto' />
            </div>
            {/* text */}
            <div className='space-y-2 text-center  md:text-left'>
              <h3 className=' font-bold text-base sm:text-lg'>
                Knowledgeable Staff
              </h3>
              <h5 className='text-xs max-w-xs leading-5 sm:text-sm'>
                We employ knowledgeable and well-trained staff members who
                provide expert advice and guidance to customers. our staff is
                always ready to assist, ensuring that you have a positive
                shopping experience.
              </h5>
            </div>
          </div>
        </div>
      </section>
      {/* shipping */}
      <section className='section-center space-y-6 md:space-y-12 xl:space-y-20'>
        {/* header */}
        <div className='text-center space-y-2'>
          <h1 className='font-bold font-mono sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl'>
            Shipping Services
          </h1>
          <p className='text-xs sm:text-sm md:text-base md:max-w-sm flex justify-center items-center mx-auto xl:text-lg lg:max-w-md '>
            We offer groupage and shipping services
          </p>
        </div>
        {/* content */}
        <div
          data-aos='zoom-in-down'
          data-aos-duration='2000'
          data-aos-delay='100'
          className=' overflow-x-hidden my-20 space-y-10 md:flex md:justify-between md:items-center md:space-y-0 md:gap-10 lg:gap-14 xl:gap-20'
        >
          {/* image */}
          <div className=' relative md:w-1/2'>
            <img
              src={shipping}
              alt='logo'
              width={1000}
              height={1000}
              className='w-72 rounded-lg md:rounded-xl  sm:w-full md:w-full max-w-xs sm:max-w-md  md:max-w-2xl xl:max-w-md mx-auto shadow-sm '
            />
          </div>
          {/* text */}
          <div className='font-sans flex flex-col justify-center items-center mx-auto md:mx-0 md:items-start space-y-6  md:w-1/2 '>
            {/* paragraph */}
            <p className='text-babyblack text-xs text-justify  sm:text-sm md:text-sm lg:text-base  md:text-left  xl:text-lg xl:max-w-md'>
              Effortlessly ship your products with us Today!. We specialize in
              streamlining your shipping process, ensuring your items reach
              their destination safely and on time. Say goodbye to logistics
              headaches and hello to seamless shipping solutions. Let us handle
              the details while you focus on growing your business. Discover the
              power of hassle-free shipping with MightyKens International
              Limited today!
            </p>
            <button className='bg-babyblack text-white px-6 py-2 text-xs  md:text-sm  xl:text-base sm:px-8 sm:py-3 rounded shadow flex justify-center items-center mx-auto  md:mx-0  md:py-2 md:px-8 xl:py-3 md:shadow-md  xl:px-10 xl:rounded-md '>
              <a
                href='https://wa.me/2347065399592'
                target='_blank'
                rel='noopener noreferrer'
              >
                Get in touch
              </a>
            </button>
          </div>
        </div>
        {/* image */}
        <div id='shipp' className=' relative flex justify-center items-center'>
          <img
            src={ship}
            alt='logo'
            width={1000}
            height={1000}
            className='w-72  sm:w-full md:w-96 max-w-xs sm:max-w-md  md:max-w-2xl xl:w-full xl:max-w-md mx-auto  '
          />
        </div>
      </section>
      {/* our gallery */}
      <section className='section-center space-y-6 md:space-y-12 xl:space-y-20'>
        {/* header */}
        <div className='text-center space-y-2'>
          <h1 className='font-bold font-mono sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl'>
            Our Gallery
          </h1>
          <p className='text-xs sm:text-sm md:text-base md:max-w-sm flex justify-center items-center mx-auto xl:text-lg lg:max-w-md '>
            These are pictures of our visit to our partner sites and warehouses
            abroad
          </p>
        </div>
        {/* content */}
        <div className='flex flex-col justify-center items-center sm:grid sm:grid-cols-2 sm:gap-6 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 lg:gap-x-10 xl:gap-x-6 xl:gap-y-10 space-y-6 sm:space-y-0'>
          {/* gal 1 */}
          <div className=' flex justify-center items-center w-60 sm:w-52 md:w-56 lg:w-52  xl:w-80 xl:hidden group/item'>
            <img
              src={why7}
              alt='logo'
              width={1000}
              height={1000}
              className='   '
            />
          </div>
          <div className='hidden xl:flex justify-center items-center w-60 sm:w-52 md:w-56 lg:w-52  xl:w-full xl:h-full xl:row-span-3  group/item'>
            <img
              src={why777}
              alt='logo'
              width={1000}
              height={1000}
              className='   '
            />
          </div>
          {/* gal 2 */}
          <div className=' flex justify-center items-center w-60 sm:w-52 md:w-56 lg:w-52  xl:w-80  xl:hidden group/item'>
            <img
              src={why8}
              alt='logo'
              width={1000}
              height={1000}
              className='   '
            />
          </div>
          <div className='hidden xl:flex xl:w-full xl:col-span-2   group/item'>
            <img
              src={why88}
              alt='logo'
              width={1000}
              height={1000}
              className='   '
            />
          </div>
          {/* gal 3 */}
          <div className=' flex justify-center items-center w-60 sm:w-52 md:w-56 lg:w-52  xl:w-full  group/item'>
            <img
              src={why66}
              alt='logo'
              width={1000}
              height={1000}
              className='   '
            />
          </div>
          {/* gal 4 */}
          <div className=' flex justify-center items-center w-60 sm:w-52 md:w-56 lg:w-52  xl:w-full  group/item'>
            <img
              src={why5}
              alt='logo'
              width={1000}
              height={1000}
              className='   '
            />
          </div>
          {/* gal 5 */}
          <div className=' flex justify-center items-center w-60 sm:w-52 md:w-56 lg:w-52  xl:w-full  group/item'>
            <img
              src={why4}
              alt='logo'
              width={1000}
              height={1000}
              className='   '
            />
          </div>
          {/* gal 6 */}
          <div className=' flex justify-center items-center w-60 sm:w-52 md:w-56 lg:w-52  xl:w-80 xl:hidden group/item'>
            <img
              src={why3}
              alt='logo'
              width={1000}
              height={1000}
              className='   '
            />
          </div>
          {/* gal 7 */}
          <div className=' flex justify-center items-center w-60 sm:w-52 md:w-56 lg:w-52  xl:w-full  group/item'>
            <img
              src={why1}
              alt='logo'
              width={1000}
              height={1000}
              className='   '
            />
          </div>
          <div className='hidden xl:flex xl:w-full xl:col-span-2   group/item'>
            <img
              src={why11}
              alt='logo'
              width={1000}
              height={1000}
              className='   '
            />
          </div>
          {/* gal 8 */}
          <div className=' flex justify-center items-center w-60 sm:w-52 md:w-56 lg:w-52  xl:w-full  group/item'>
            <img
              src={why2}
              alt='logo'
              width={1000}
              height={1000}
              className='   '
            />
          </div>
          {/* gal 9*/}
          <div className=' flex justify-center items-center  w-60 sm:w-52 md:w-56 lg:w-52  xl:w-full  group/item'>
            <img
              src={why9}
              alt='logo'
              width={1000}
              height={1000}
              className='   '
            />
          </div>
          {/* gal 99 */}
          <div className='  xl:flex  justify-center items-center  w-60 sm:w-52 md:w-56 lg:w-52  xl:w-full  group/item'>
            <img
              src={why101}
              alt='logo'
              width={1000}
              height={1000}
              className=' w-full  '
            />
          </div>
          {/* gal 10 */}
          <div className=' flex justify-center  xl:hidden items-center w-60 sm:w-52 md:w-56 lg:w-52  xl:w-full  group/item'>
            <img
              src={why10}
              alt='logo'
              width={1000}
              height={1000}
              className='   '
            />
          </div>
          {/* gal 100 */}
          <div className=' hidden xl:flex  justify-center items-center xl:col-span-2 w-60 sm:w-52 md:w-56 lg:w-52  xl:w-full  group/item'>
            <img
              src={why100}
              alt='logo'
              width={1000}
              height={1000}
              className='   '
            />
          </div>
        </div>
      </section>
      {/* testimonies */}
      <section className='section-center space-y-6 md:space-y-12 xl:space-y-20'>
        {/* header */}
        <div className='text-center space-y-2'>
          <h1 className='font-bold font-mono sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl'>
            Our Testimonies
          </h1>
          <p className='text-xs sm:text-sm md:text-base md:max-w-sm flex justify-center items-center mx-auto xl:text-lg lg:max-w-md '>
            Read up on what our customers have to say about our product and
            services
          </p>
        </div>

        {/* carouse */}
        <div className='w-full '>
          <Carousel />
        </div>
      </section>
      {/* Get in touch*/}
      <section className='section-center space-y-6 md:space-y-12 xl:space-y-20'>
        {/* header */}
        <div className='text-center space-y-2'>
          <h1 className='font-bold font-mono sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl'>
            Get in Touch
          </h1>
          <p className='text-xs sm:text-sm md:text-base md:max-w-sm flex justify-center items-center mx-auto xl:text-lg lg:max-w-md '>
            Feel free to get in touch with us in any of the under listed mediums
          </p>
        </div>
        {/* how */}
        <div className='flex flex-col justify-center items-center space-y-6 sm:grid sm:grid-cols-2 sm:space-y-0 sm:gap-6 md:grid-cols-3'>
          {/* how1 */}
          <div className='bg-white shadow-md px-4 py-6 space-y-2 w-60 sm:w-52 sm:h-48 md:h-52 lg:h-60  lg:w-64 xl:w-full flex flex-col justify-start items-center'>
            {/* icon */}
            <div className='bg-babyblack  w-10 h-10 rounded-full flex justify-center items-center'>
              <HiPhoneMissedCall className='text-white text-xl' />
            </div>
            <h1 className='font-bold text-lg lg:text-xl text-center '>
              Call Us
            </h1>
            <p className='text-center text-xs w-40 xl:w-full lg:text-base  '>
              +2347010347348 <br />
              +2347065399592
            </p>
          </div>
          {/* how2 */}
          <div className='bg-white shadow-md px-4 py-6 space-y-2 w-60 sm:w-52 sm:h-48 md:h-52 lg:h-60 lg:w-64 xl:w-full flex flex-col justify-start items-center'>
            {/* icon */}
            <div className='bg-babyblack  w-10 h-10 rounded-full flex justify-center items-center'>
              <ImLocation2 className='text-white text-xl' />
            </div>

            <h1 className='font-bold text-lg text-center lg:text-xl'>
              Visit Us
            </h1>
            <p className='text-center text-xs w-full lg:text-base '>
              HOMEPOINT PLAZA ,KM 34 Lekki Epe Expressway Oribanwa Phase II
              Ibeju Lekki, Lagos
            </p>
          </div>
          {/* how3 */}
          <div className='bg-white shadow-md px-4 py-6 space-y-2 w-60 sm:w-52 sm:h-48 md:h-52  lg:h-60 lg:w-64 xl:w-full  flex flex-col justify-start items-center'>
            {/* icon */}
            <div className='bg-babyblack  w-10 h-10 rounded-full flex justify-center items-center'>
              <MdMarkEmailUnread className='text-white text-xl' />
            </div>
            <h1 className='font-bold text-lg lg:text-xl text-center'>
              Write us
            </h1>
            <p className='text-center text-xs lg:text-base w-40 lg:w-full'>
              mightykens@gmail.com
            </p>
          </div>
        </div>
      </section>
      {/* Footer */}
      <Footer />
    </>
  )
}

export default Aboutus
