import React from 'react'

export const Products = [
  {
    id: 1,
    name: 'COMMERCIAL WASHER EXTRACTOR',
    category: '',
    brand: 'Domus',
    image: require('../images/p1.jpeg'),
    description: (
      <p>
        User friendly and totally programmable 7’’ touch II usb connection. PC
        software free of charge for programming , traceability, data analysis
        videos on display ( ads instruction, etc. ) 37 languages 8 dosing
        signals as standard. DMS - 11/ 14/20/28 TOUCH II G FACTOR 200 Hard
        mounted washer.
        <br />
        <br />
        Anchoring on the floor with anchoring base. Panels in skinplate. R.P.M
        between 836 - 929 Vat and drum in AISI 304 Stainless Steel. 3 water
        inlets. Savings due to high extraction of water ( 65% residual humidity
        ) <br />
        <br />
        Available Capacities: 11kg, 14kg, 20kg, 28kg 39kg, 66kg, and 88kg .
      </p>
    ),
  },
  {
    id: 2,
    name: 'COMMERCIAL TUMBLE DRYER',
    category: '',
    brand: 'Domus',
    image: require('../images/p2.jpeg'),
    description: (
      <p>
        DTM COMFORT. DTM-12/ 14/ 18/ 26/ 31/ 37/ 50/ 67/ 83. <br />
        <br />
        New M Programmer:push buttons to control temperature and timer. Grey
        skinplate body, Stainless steel look. Big opening door ( opening of 180
        ) REVERSE ACTION: Standard in all models. STAINLESS STEEL DRUM standard.
        <br />
        <br />
        DOUBLE FLOW: mixed axial-radial airflow. SOFT DRY: the new drum with
        stamped holes. FULL SCREEN FILTER: new filter drawer with wide surface
        and improved airflow. COLD DOWN: anti-wrinkle at the end of cycle.
        Models available in electric, gas or steam heating. <br />
        <br /> Available Capacities: 12kg, 14kg, 28kg, 26kg, 31kg, 37kg, 50kg,
        67kg and 83kg.
      </p>
    ),
  },
  {
    id: 3,
    name: 'WALL/FLATWORK',
    category: '',
    brand: 'Domus',
    image: require('../images/p3.jpeg'),
    description: (
      <p>
        Roll width: 1 metre to 2 metres. Panels in skin plate. Top panels in
        stainless steel. Chest in anodized aluminium. Automatic lífting of the
        chest by photocell. Fingerguard. Electronic control: digital screen.
        Accurate temperature control. Electric heating. Roll covered in
        polyester and cotton felt in standard model. <br /> <br /> Nomex model:
        possibility to work at higher temperatures, <br /> <br /> Available
        Models: PR-1025, 1225, 1425, 2025 ( roll 250 mm)
      </p>
    ),
  },
  {
    id: 4,
    name: 'WALL/ FLATWORK IRONER ',
    category: '',
    brand: 'Domus',
    image: require('../images/p4.jpeg'),
    description: (
      <p>
        PR Ø180mm Front feed and return. <br /> <br />
        Roll width: 1 metre and 1,2 metres. Panels in skin plate. Top panels in
        stainless steel. Chest in anodized aluminium. Manual lífting of the
        chest. Fingerguard. <br /> <br />
        Electronic control: start/stop. Temperature selection. Electric heating.
        Roll covered in polyester and cotton felt in standard model. <br />{' '}
        <br /> Nomex model: possibility to work at higher temperatures, UP to
        190 c <br /> <br />
        Available models: PR-1018, 1218, ( roll 180 mm )
      </p>
    ),
  },
  {
    id: 5,
    name: 'VACUUM IRONING TABLES ',
    category: '',
    brand: 'Domus',
    image: require('../images/p5.jpeg'),
    description: (
      <p>
        {' '}
        TP-A | TPC-A Without boiler With boiler TP-A Vacuum ironing table +
        iron, with electrically heated board and suction. Work table dimensions
        1200 x 400 x 250 mm. Adjustable temperature of the board by means of a
        thermostat. Built-in single phase suction unit. Works by means of a
        pedal. Electric heated board. Needs to be connected to an external steam
        supply. Voltage: 230V I +N 50 Hz. TPC-A Vacuum ironing table + iron with
        suction and electrically heated board, controlled by a thermostat. Work
        table dimensions 1200 x 400 x 250 mm. Built-in electric steam boiler.
        Iron included. Second iron as option. Automatic water feeding, automatic
        pressure control. Feeding pump and emptying valve. Works by means of a
        pedal. Electrical heating. <br /> <br /> Voltage: 400V III +N / 230V III
        / 230V I +N 50 Hz. TP-A
      </p>
    ),
  },
  {
    id: 6,
    name: 'VACUUM IRONING TABLE TPC-08 A',
    category: '',
    brand: 'Domus',
    image: require('../images/p6.jpeg'),
    description: (
      <p>
        Ironing table with suction unit and boiler Utility ironing table with
        electrically heated board and suction unit. Version with built-in
        electric steam boiler, water pump, water feeding tank and electric steam
        iron. Built-in suction unit controlled by foot pedal. Adjustable board
        temperature by thermostat. Pedals on both sides of the table. Electric
        boiler with water feeding and automatic steam. Product working pressure
        2 .6 bar Voltage: 230V I + N 50 Hz.{' '}
      </p>
    ),
  },
  {
    id: 7,
    name: 'FORM FINISHER',
    category: '',
    brand: 'Domus',
    image: require('../images/p7.jpeg'),
    description: (
      <p>
        MAC-C | MAC-A Without boiler With boiler Utility form-finisher ideal for
        the finishing of coats, jackets, dresses, etc. Automated working cycles.
        Electronic card. Possibility to set nine different cycles, and
        regulation of steaming and drying time. Adjustable shoulder width and
        height of the dummy. Rotation 360o of dummy support (frame) and
        controls. MAC-C without boiler - to be connected to a central steam
        supply. MAC-A autonomous,with built-in steam boiler, and automatic water
        feeding and pressure control. Voltage: 230V III / 400V III 50
      </p>
    ),
  },
  {
    id: 8,
    name: 'FINISHING CABIINER',
    category: '',
    brand: 'Domus',
    image: require('../images/p8.jpeg'),
    description: (
      <p>
        BOX-C | BOX-A Without boiler With boiler 40 3 40 3 1.115x630x1.950
        1.115x630x1.950 11.765 15.135 Simple and easy to operate. The compact
        overall dimensions allow the installation and operation even in small
        premises. The finishing cycle is carried out without the slightest waste
        of steam, providing an improved working environment. The steam BOX is
        supplied as standard with one trouser finishing device and one form
        finisher for jackets, coats, dresses, etc. Thanks to a very easy fitting
        system, the cabinet can be transformed and equipped with two trouser
        finishing devices or two form finishers. The working cycle is automatic
        and can be adjusted by three timers. It comes with a new easy to use
        computer control system, capable of running up to nine fully automatic
        programs. BOX-C: without boiler. BOX-A: autonomous, with built-in
        Electric. Automatic steam boiler.
      </p>
    ),
  },
  {
    id: 9,
    name: 'MEDICAL BARRIER WASHER',
    category: '',
    brand: 'Domus',
    image: require('../images/p9.jpeg'),
    description: (
      <p>
        Pass-through barrier washer. G Factor 350. New TOUCH II microprocessor
        with a 7'' touch screen. Panels in skinplate and top panel in stainless
        steel. Vat and drum in stainless steel AISI 304. Heating: electric and
        steam, dual heating. 2 water inlets. 8 dosing signals. Central drain
        standard. Automatic positioning of drum. ASM-16/22 TOUCH II <br />{' '}
        <br />
        Available capacities: 18kg and 25kg
      </p>
    ),
  },
  {
    id: 10,
    name: 'MEDICAL BARRIER WASHER. ',
    category: '',
    brand: 'Domus',
    image: require('../images/p10.jpeg'),
    description: (
      <p>
        DHB BIG CAPACITY Pass-through barrier washer extractors with 2 opposed
        doors (180o) with sanitary separating frame. G Factor 375. New TOUCH II
        microprocessor with a 7'' touch screen. Panels in grey skinplate and top
        panel in stainless steel. Vat and drum in stainless steel AISI 304.
        Screen on both sides of the machine. Automatic positioning of drum with
        motor brake as standard. 3 water inlets. <br /> <br /> Heating: electric
        and steam, dual heating. <br /> <br /> DHB-27/35/50/70 TOUCH II
        AVAILABLE CAPACITIES: 30kg, 39kg, 54kg, and 78kg.
      </p>
    ),
  },
  {
    id: 11,
    name: 'COOKING RANGE WORKSTATION ',
    category: '',
    brand: 'Pimak',
    image: require('../images/p11.jpeg'),
    description: (
      <p>
        PIMAK COOKING RANGE WORKSTATION below are the machines that made up the
        workstation: <br /> 1) 4burner gas cooker with oven <br /> 2) 4 hot
        plate electric cooker with oven <br /> 3) standing pasta cooker <br />{' '}
        4) standing griddle <br /> 5) standing fryer <br /> 6) boiling pan{' '}
        <br /> 7) tilting pan
      </p>
    ),
  },
  {
    id: 12,
    name: 'DISH WASHER  ',
    category: '',
    brand: 'Pimak',
    image: require('../images/p12.jpeg'),
    description: (
      <p>
        {' '}
        Model: M036-1
        <br />
        Energy type: Electric
        <br />
        Power KW: 1.5
        <br />
        Capacity ( Plate / Hour ): 1000 plates
        <br />
        Dimension: 650x820x1400mm
        <br />
        Weight KG: 95
      </p>
    ),
  },
  {
    id: 13,
    name: 'COOKING RANGE WITH OVEN ',
    category: '',
    brand: 'Pimak',
    image: require('../images/p13.jpeg'),
    description: (
      <p>
        1) MODEL: 90S-M015-6F <br />
        Gas consumption (m3/h-kg/h): 5.630. <br />
        Capacity: 6+1 <br />
        Dimension: 1200x900x850mm <br />
        Weight (KG): 170 <br /> <br />
        2) MODEL: M015-6 <br />
        Gas consumption (m3/h-kg/h): 5.628-4.196 <br />
        Capacity: 6+1 <br />
        Dimension: 1500x1000x850mm <br />
        Weight (KG): 200 <br /> <br />
        3) MODEL: KU-M015-2.6 <br />
        Gas consumption (m3/h-kg/h): 6.133-3.729 <br />
        Capacity: 6+2 <br />
        Dimension:2000x1000x850mm <br />
        Weight (KG): 220 <br /> <br />
        4) MODEL: 70S-M015-6F <br />
        Capacity: 6+1 <br />
        Dimension: 1200x700x850mm <br />
        Weight (KG): 140
      </p>
    ),
  },
  {
    id: 14,
    name: 'GAS COOKING RANGE WITH OVEN  ',
    category: '',
    brand: 'Pimak',
    image: require('../images/p14.jpeg'),
    description: (
      <p>
        {' '}
        `1) MODEL: 90S-M015-4F <br />
        Gas consumption (m3/h-kg/h): 4.148 <br />
        Capacity: 4+1 <br />
        Dimension: 800x900x850mm <br />
        Weight (KG): 147 <br /> <br />
        2) MODEL: M015-4 <br />
        Capacity: 4+1 <br />
        Dimension: 1000x1000x850mm <br />
        Weight (KG): 160 <br /> <br /> 3) MODEL: 70S-M015-4F <br />
        Capacity: 4+1 <br />
        Dimension: 800x700x850mm <br />
        Weight (KG): 100
      </p>
    ),
  },
  {
    id: 15,
    name: ' COMMERCIAL FREE STANDING GAS FRYER  ',
    category: '',
    brand: 'Pimak',
    image: require('../images/p15.jpeg'),
    description: (
      <p>
        1) MODEL: FR1/70SD-M073-1 <br />
        LITER CAPACITY: 12L+12L <br />
        DIMENSION: 800x700x850mm <br />
        Weight (KG): 65 <br /> <br />
        2) MODEL: 90S-M062G <br />
        LITER CAPACITY : 24L+24L <br />
        DIMENSION: 800x900x850mm <br />
        Weight (KG): 92
      </p>
    ),
  },
  {
    id: 16,
    name: 'COMMERCIAL FREE STANDING GAS FRYER ( 1TANK 2BASKET )',
    category: '',
    brand: 'Pimak',
    image: require('../images/p16.jpeg'),
    description: (
      <p>
        1) MODEL: 90S-M061G <br /> LITER CAPACITY: 24LITER <br /> DIMENSION:
        400x900x850mm <br /> Weight (KG): 60 <br /> <br /> 2) MODEL:
        FR/70SD-M073 <br /> LITER CAPACITY : 12L <br /> DIMENSION: 400x700x850mm{' '}
        <br /> Weight (KG): 38
      </p>
    ),
  },
  {
    id: 17,
    name: 'COMMERCIAL GAS STOCK POT ',
    category: '',
    brand: 'Pimak',
    image: require('../images/p17.jpeg'),
    description: (
      <p>
        1) Model: M018
        <br />
        Capacity Cooker: Single flame
        <br />
        Dimension: 400x500x500mm <br /> Weight KG: 30 <br />
        Gas Consumption: 0.317-0.231 <br /> <br />
        2) Model: M018-1 <br /> Capacity Cooker: Double flame <br />
        Dimension: 600x700x500mm <br /> Weight KG: 48 <br /> Gas Consumption:
        1.323-0.986 <br /> <br />
        3) Model: M018-2 <br /> Capacity Cooker: Triple flame <br /> Dimension:
        700x800x500mm <br /> Weight KG: 65 <br /> Gas Consumption: 2.21-1.57`
      </p>
    ),
  },
  {
    id: 18,
    name: 'ELECTRIC HOT PLATE STOCK POT ',
    category: '',
    brand: 'Pimak',
    image: require('../images/p18.jpeg'),
    description: (
      <p>
        1) Model: M018E Voltge (Volt/ 50HZ ) <br /> Dimension: 400x500x500mm{' '}
        <br /> Weight KG: 18 <br /> Capacity/ hot plate size: 300x300 POWER ( KW
        ): 4 <br />
        <br /> 2) Model: M018-1E Voltge (Volt/ 50HZ ) <br /> Dimension:
        600x700x500mm <br />
        Weight KG: 40 <br /> Capacity/ hot plate size: 400x400mm <br />
        POWER ( KW ): 5
      </p>
    ),
  },
  {
    id: 19,
    name: 'COMMERCIAL FREE STANDING ELECTRIC FRYER ( 1TANK 2BASKET ) ',
    category: '',
    brand: 'Pimak',
    image: require('../images/p19.jpeg'),
    description: (
      <p>
        1) MODEL: 90S-M061E <br />
        LITER CAPACITY: 24LITER <br />
        DIMENSION: 400x900x850mm <br /> Weight (KG): 48 <br /> <br />
        2) MODEL: 70SE-M073 <br />
        LITER CAPACITY : 12L
        <br />
        DIMENSION: 400x700x850mm <br />
        Weight (KG): 37
      </p>
    ),
  },
  {
    id: 20,
    name: 'COMMERCIAL FREE STANDING ELECTRIC FRYER ( 2TANK 4BASKET ) ',
    category: '',
    brand: 'Pimak',
    image: require('../images/p20.jpeg'),
    description: (
      <p>
        1) MODEL: 90S-M062E <br />
        LITER CAPACITY: 48LITERS <br /> DIMENSION: 800X900X850mm <br />
        Weight (KG): 70 <br /> POWER KW: 21 <br /> <br /> 2) MODEL: 70SDE-M073-1{' '}
        <br />
        LITER CAPACITY : 24LITERS <br /> DIMENSION: 800X700X850mm <br /> Weight
        (KG): 60 <br /> POWER KW: 18
      </p>
    ),
  },
  {
    id: 21,
    name: 'COMMERCIAL ELECTRIC PASTA COOKER ( 6 BASKETS) ',
    category: '',
    brand: 'Pimak',
    image: require('../images/p21.jpeg'),
    description: (
      <p>
        1) MODEL: 70SD-M184 <br />
        POWER KW: 7.5 <br />
        LITER CAPACITY: 10LITERS <br />
        DIMENSION: 400X700X85mm
      </p>
    ),
  },
  {
    id: 22,
    name: 'COMMERCIAL FREE STANDING ELECTRIC GRIDDLE ( HALF RIBBED /  SMOOTH ) ',
    category: '',
    brand: 'Pimak',
    image: require('../images/p22.jpeg'),
    description: (
      <p>
        1) MODEL: 90SD-M065E <br />
        DIMENSION: 800X900X850mm <br />
        Weight (KG):150 <br />
        POWER KW: 9 <br />
        <br />
        2) MODEL: 70DE-M179 ( HALF RIBBED / SMOOTH ) <br />
        DIMENSION: 800X700X850mm <br />
        Weight (KG): 90 <br />
        POWER KW: 6
      </p>
    ),
  },
  {
    id: 23,
    name: 'COMMERCIAL FREE STANDING GAS GRIDDLE ( HALF RIBBED /  SMOOTH ) ',
    category: '',
    brand: 'Pimak',
    image: require('../images/p23.jpeg'),
    description: (
      <p>
        Basic Features <br />
        • LPG or natural gas use <br />
        • Gas safety valve <br />
        • Stainless steel pipe type burner <br />
        • Stainless steel body <br />
        • Custom made plate <br />
        •Corrugated or flat plate on other models <br />
        • Hygienic and safe <br />
        • Durable
        <br />
        <br />
        CATEGORIES <br />
        1) MODEL: IZ3/90SD-M171-1 <br />
        DIMENSION: 800X900X850mm <br />
        Weight(KG): 150 <br /> <br />
        2) MODEL: IZ1/70SD-M171-2 ( HALF RIBBED / SMOOTH ) <br />
        DIMENSION:800X700X850mm <br />
        Weight (KG): 89
      </p>
    ),
  },
  {
    id: 24,
    name: 'COMMERCIAL FREE STANDING LAVASTONE  ',
    category: '',
    brand: 'Pimak',
    image: require('../images/p24.jpeg'),
    description: (
      <p>
        GAS TYPE <br />
        1) MODEL: LT3/90SD-M170-3 <br />
        DIMENSION: 800X900X850mm <br />
        Weight (KG): 160 <br /> <br /> 2) MODEL: LT3/90SD-M170-1 <br />
        DIMENSION: 515x900x850mm <br />
        Weight (KG): 90
      </p>
    ),
  },
  {
    id: 25,
    name: 'COMMERCIAL FREE STANDING HOT PLATE COOKER WITH OVEN.',
    category: '',
    brand: 'Pimak',
    image: require('../images/p25.jpeg'),
    description: (
      <p>
        ELECTRIC HOT PLATE 1) MODEL: 90SF-M068 <br />
        VOLTAGE:400V/50HZ <br />
        DIMENSION: 800X900X850mm <br />
        Weight (KG): 135 <br /> POWER KW: 24 <br /> CAPACITY: 4+1 <br /> <br />
        2)MODEL: MX26-4 <br />
        DIMENSION: 1000x1000x850mm <br />
        Weight (KG): 150 <br /> POWER KW: 21 <br />
        CAPACITY: 4+1 <br /> <br />
        3) MODEL: MX26-6 <br /> DIMENSION: 1500x1000x850mm <br />
        WEIGHT:195 <br />
        POWER KW: 29 <br /> CAPACITY: 6+1
      </p>
    ),
  },
  {
    id: 26,
    name: 'COMMERCIAL COUNTER TOP HOT PLATE COOKER.',
    category: '',
    brand: 'Pimak',
    image: require('../images/p26.jpeg'),
    description: (
      <p>
        ELECTRIC HOT PLATE <br /> <br />
        1) MODEL: 70S-M182-4SE <br />
        VOLTAGE:400V/50HZ <br />
        DIMENSION: 800x700x30mm <br /> Weight (KG): 30 <br />
        POWER KW: 8 <br /> CAPACITY COOKER: 4
      </p>
    ),
  },
  {
    id: 27,
    name: 'COMMERCIAL FREE STANDING HOT PLATE COOKER ',
    category: '',
    brand: 'Pimak',
    image: require('../images/p27.jpeg'),
    description: (
      <p>
        ELECTRIC HOT PLATE <br />
        <br />
        1) MODEL: 70SDE-M182-1 <br /> VOLTAGE:400V/50HZ <br />
        DIMENSION: 800x700x850mm <br /> Weight (KG): 50 <br /> POWER KW: 8{' '}
        <br /> CAPACITY COOKER: 4
      </p>
    ),
  },
  {
    id: 28,
    name: 'COMMERCIAL COUNTER TOP GAS COOKER.',
    category: '',
    brand: 'Pimak',
    image: require('../images/p28.jpeg'),
    description: (
      <p>
        GAS COOKER <br /> <br />
        1) MODEL: OC3/70MX-4S <br /> DIMENSION: 800x700x300mm <br />
        Weight (KG): 45 <br /> CAPACITY COOKER: 4 <br /> <br /> 2) MODEL:
        OC4/70MX-6S <br /> DIMENSION: 1200x700x300mm <br /> Weight (KG): 72{' '}
        <br /> CAPACITY COOKER: 6
      </p>
    ),
  },
  {
    id: 29,
    name: 'COMMERCIAL COUNTER TOP FRYER',
    category: '',
    brand: 'Pimak',
    image: require('../images/p29.jpeg'),
    description: (
      <p>
        ELECTRIC FRYER <br /> <br />
        1) MODEL: M169-1 <br />
        VOLTAGE:220-400V/50HZ <br />
        DIMENSION: 700x600x300mm <br />
        Weight (KG): 35 <br /> POWER KW: 16.5 <br /> CAPACITY LITERS: 8+8 <br />{' '}
        <br />
        2) MODEL: 70SE-M073-1 <br /> VOLTAGE: 230-400V/50HZ <br /> DIMENSION:
        800x700x300mm <br /> WEIGHT: 35 <br />
        POWER KW: 18 <br />
        CAPACITY LITERS: 12+12
      </p>
    ),
  },
  {
    id: 30,
    name: 'COMMERCIAL COUNTER TOP FRYER',
    category: '',
    brand: 'Pimak',
    image: require('../images/p30.jpeg'),
    description: (
      <p>
        ELECTRIC FRYER <br /> <br />
        1) MODEL: 70SE-M073 <br />
        VOLTAGE:220-400V/50HZ <br />
        DIMENSION: 400x700x300mm <br /> Weight (KG): 20 <br /> POWER KW: 9{' '}
        <br /> CAPACITY LITERS: 12 <br /> <br />
        2) MODEL: M169 <br /> VOLTAGE: 230-400V/50HZ <br /> DIMENSION:
        400x600x300mm <br /> WEIGHT: 23 <br />
        POWER KW: 8.25 <br /> CAPACITY LITERS: 8
      </p>
    ),
  },
  {
    id: 31,
    name: 'COMMERCIAL COUNTER TOP FRYER',
    category: '',
    brand: 'Pimak',
    image: require('../images/p31.jpeg'),
    description: (
      <p>
        ELECTRIC FRYER <br /> <br />
        1) MODEL: M062-1 ( Eko Model) <br />
        VOLTAGE:220-400V/50HZ <br /> DIMENSION: 600x600x300mm <br /> Weight
        (KG): 16 <br /> POWER KW: 7 <br />
        CAPACITY LITERS: 8+8
      </p>
    ),
  },
  {
    id: 32,
    name: 'COMMERCIAL COUNTER TOP FRYER',
    category: '',
    brand: 'Pimak',
    image: require('../images/p32.jpeg'),
    description: (
      <p>
        ELECTRIC FRYER <br /> <br />
        1) MODEL: M062 ( Eko Model) <br />
        VOLTAGE:220-400V/50HZ <br /> DIMENSION: 400x600x300mm <br /> Weight
        (KG): 12 <br />
        POWER KW: 3.5 <br /> CAPACITY LITERS: 8
      </p>
    ),
  },
  {
    id: 33,
    name: 'COMMERCIAL COUNTER TOP GRIDDLE ',
    category: '',
    brand: 'Pimak',
    image: require('../images/p33.jpeg'),
    description: (
      <p>
        ELECTRIC GRIDDLE <br /> <br />
        1) MODEL: M064-70 <br />
        VOLTAGE:220-400V/50HZ <br />
        DIMENSION: 700x600x300mm <br /> POWER KW: 5 <br /> WEIGHT KG: 54 <br />{' '}
        <br /> 2) MODEL: M064-60 <br />
        VOLTAGE: 230-400V/50HZ <br />
        DIMENSION: 600x600x300mm <br /> WEIGHT KG: 45 <br /> POWER KW: 4 <br />{' '}
        <br />
        3) MODEL: M064-40 <br />
        VOLTAGE: 230-400V/50HZ <br /> DIMENSION: 400x600x300mm <br />
        WEIGHT KG: 30kg <br /> POWER KW: 2.5 <br /> <br /> GAS TYPE <br />
        <br />
        1) MODEL: IZ4/M171-40 <br />
        VOLTAGE:230V/50HZ <br /> DIMENSION: 400x600x300mm <br /> WEIGHT KG: 30kg{' '}
        <br /> <br />
        2) MODEL: IZ5/M171-70 <br />
        VOLTAGE: 230V/50HZ <br /> DIMENSION: 700x600x300mm <br />
        WEIGHT KG: 45kg
      </p>
    ),
  },
  {
    id: 34,
    name: 'COMMERCIAL FREE STANDING GAS COOKER WITH CABINET ',
    category: '',
    brand: 'Pimak',
    image: require('../images/p34.jpeg'),
    description: (
      <p>
        GAS COOKER <br /> <br />
        1) MODEL: OC6/90SMX-4D <br />
        DIMENSION: 800x900x850mm <br />
        CAPACITY BURNER: 4 <br />
        WEIGHT KG: 108kg <br /> <br />
        2) MODEL: OC3/70MX-4D <br /> DIMENSION: 800x700x850mm <br />
        WEIGHT KG: 75kg <br /> CAPACITY BURNER: 4 <br /> <br />
        3) MODEL: OC4/70MX-6D <br />
        DIMENSION: 1200x700x850mm <br />
        WEIGHT KG: 96kg <br /> CAPACITY BURNER: 6
      </p>
    ),
  },
  {
    id: 35,
    name: 'COMMERCIAL FREE STANDING ELECTRIC HOT PLATE COOKER WITH CABINET',
    category: '',
    brand: 'Pimak',
    image: require('../images/p35.jpeg'),
    description: (
      <p>
        ELECTRIC HOT PLATE <br /> <br />
        1) MODEL: 90SD-M068 <br />
        DIMENSION:800x900x850mm <br />
        CAPACITY BURNER: 4 <br />
        WEIGHT KG: 100kg
      </p>
    ),
  },
  {
    id: 36,
    name: 'COMMERCIAL BOILING PAN',
    category: '',
    brand: 'Pimak',
    image: require('../images/p36.jpeg'),
    description: (
      <p>
        GAS TYPE <br /> <br />
        1) MODEL: 90S-KK-80 <br />
        DIMENSION: 800x900x850mm <br />
        CAPACITY LITERS: 80L <br /> WEIGHT KG: 140kg <br /> <br />
        2) MODEL: 90S-KKG-100 <br />
        DIMENSION:800x900x850mm <br /> WEIGHT KG: 155kg <br />
        CAPACITY LITERS: 100L <br /> <br />
        3) MODEL: 90S-KKG-150 <br /> DIMENSION: 800x900x850mm <br /> WEIGHT KG:
        180kg <br /> CAPACITY LITERS: 150L <br /> <br /> ELECTRIC TYPE <br />
        1) MODEL: 90S-KKE-80 <br /> DIMENSION: 800x900x850mm <br /> WEIGHT KG:
        135kg <br />
        CAPACITY LITERS: 80L <br /> <br /> 2) MODEL: 90S-KKE-100 <br />
        DIMENSION: 800x900x850mm <br /> WEIGHT KG: 150kg <br /> CAPACITY LITERS:
        100L <br /> <br /> 3)MODEL: 90S-KKE-150 <br /> DIMENSION: 800x900x850mm{' '}
        <br /> WEIGHT KG: 175kg <br /> CAPACITY LITERS: 150L
      </p>
    ),
  },
  {
    id: 37,
    name: 'COMMERCIAL TILTING BRATT PAN',
    category: '',
    brand: 'Pimak',
    image: require('../images/p37.jpeg'),
    description: (
      <p>
        GAS TYPE <br /> <br />
        1) MODEL: 90S-DTG-80 <br /> DIMENSION: 800x900x850mm <br />
        CAPACITY LITERS: 80L <br /> WEIGHT KG: 173kg <br /> <br />
        2) MODEL: 90S-DTG-120 <br /> DIMENSION: 1200x900x850mm <br /> WEIGHT KG:
        230kg <br /> CAPACITY LITERS: 120L <br /> <br /> ELECTRIC TYPE <br /> 1
        ) MODEL: 90S-DTE-80 <br /> DIMENSION: 800x900x850mm <br /> WEIGHT KG:
        163kg <br /> CAPACITY LITERS: 80L <br /> <br /> 2 ) MODEL: 90S-DTE-120{' '}
        <br /> DIMENSION: 1200x900x850mm <br /> WEIGHT KG: 220kg <br /> CAPACITY
        LITERS: 120L
      </p>
    ),
  },
  {
    id: 38,
    name: 'CONVECTION OVENS',
    category: '',
    brand: 'Pimak',
    image: require('../images/p38.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br /> <br />
        1) MODEL: DFKE06 <br />
        DIMENSION: 955x866x820mm <br />
        CAPACITY/TRAY QUANTITY: 1/1 - 6 STEPS <br />
        WEIGHT KG: 90KG <br /> POWER KW: 10.6KW <br /> <br />
        2) MODEL: DFKE10 <br /> DIMENSION: 955x860x1050mm <br /> WEIGHT KG:
        140kg <br /> CAPACITY /TRAY QUANTITY: 1/1 - 10TRAYS <br /> POWER KW:
        19.6 KW <br /> <br /> 3) MODEL: DFKE20 DIMENSION: 1155x1020x1050mm{' '}
        <br />
        WEIGHT KG: 160KG <br /> CAPACITY/ TRAY-QUANTITY: 1/1 - 20trays <br />
        POWER KW: 24.6KW <br /> <br /> 4) MODEL: DFKE40 <br />
        DIMENSION: 1155x1085xx1770mm <br /> WEIGHT KG: 200KG <br /> CAPACITY/
        TRAY-QUANTITY: 1/1 40TRAYS <br />
        POWER KW: 49.2KW
      </p>
    ),
  },
  {
    id: 39,
    name: 'CONVECTION OVENS',
    category: '',
    brand: 'Pimak',
    image: require('../images/p39.jpeg'),
    description: (
      <p>
        GAS TYPE <br /> <br />
        1) MODEL: DKFG10 <br /> DIMENSION: 955x770x1050mm <br /> WEIGHT KG:
        124KG <br /> CAPACITY /TRAY QUANTITY: 1/1 - 10TRAYS <br />
        POWER KW: 14KW <br /> <br /> 2) MODEL: DKFG20 <br /> DIMENSION:
        1155X1030X1050mm <br /> WEIGHT KG: 170KG <br />
        CAPACITY/ TRAY-QUANTITY: 1/1 - 20trays <br /> POWER KW: 18KW <br />{' '}
        <br />
        3) MODEL: DKFG40 <br /> DIMENSION: 1155X109X178mm <br /> WEIGHT KG:
        200KG <br />
        CAPACITY/ TRAY-QUANTITY: 1/1 40TRAYS <br />
        POWER KW: 34KW
      </p>
    ),
  },
  {
    id: 40,
    name: 'PIZZA OVENS, CAKE AND PASTRY OVENS ',
    category: '',
    brand: 'Pimak',
    image: require('../images/p40.jpeg'),
    description: (
      <p>
        GAS TYPE <br /> 1) MODEL: M013-4G <br />
        DIMENSION: 1064x880x670mm <br />
        WEIGHT KG: 46KG <br /> CAPACITY: 4 LU <br />
        INNER DIMENSION: 650x650mm <br />
        POWER KW: 7.4/6364 <br /> <br />
        2)MODEL: M013-6G <br /> DIMENSION: 1375x880x670mm <br /> WEIGHT KG: 62KG{' '}
        <br /> CAPACITY: 6 LI
        <br />
        INNER DIMENSION: 965x650 <br /> POWER KW: 9.6/8256
      </p>
    ),
  },
  {
    id: 41,
    name: 'PIZZA OVENS, CAKE AND PASTRY OVENS  ',
    category: '',
    brand: 'Pimak',
    image: require('../images/p41.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br /> <br /> 1) MODEL: M012-4 <br /> DIMENSION:
        900x820x505mm
        <br /> INNER DIMENSION: 620x620mm <br />
        WEIGHT KG: 80KG <br />
        CAPACITY: 4 LU <br /> POWER KW: 5KW <br /> <br />
        2) MODEL: M012-6 <br /> DIMENSION: 1200x820x505mm <br /> INNER
        DIMENSION: 920x620mm <br />
        WEIGHT KG: 106KG <br /> CAPACITY: 6 LI <br />
        POWER KW: 6 <br /> <br /> 3) MODEL: M014-4 <br /> DIMENSION:
        900x820x805mm <br />
        INNER DIMENSION: 620x620mm <br />
        WEIGHT KG: 120KG <br /> CAPACITY: 4 + 4 LU <br />
        POWER KW: 10KW <br /> <br /> 4) MODEL: M014-6 <br /> DIMENSION:
        1200x820x805mm <br /> INNER DIMENSION: 920x620mm <br /> WEIGHT KG: 158KG{' '}
        <br />
        CAPACITY: 6 + 6 LI <br /> POWER KW: 12KW
      </p>
    ),
  },
  {
    id: 42,
    name: 'PASTRIES CONVECTION OVEN ',
    category: '',
    brand: 'Pimak',
    image: require('../images/p42.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        1) MODEL: PTS04 <br />
        DIMENSION: 805x830x570mm <br />
        WEIGHT KG: 68KG <br />
        CAPACITY /TRAY b=QUANTITY: 600x400mm 4TRAYS <br />
        POWER KW:6.4KW
      </p>
    ),
  },
  {
    id: 43,
    name: 'PASTRIES CONVECTION OVENS ',
    category: '',
    brand: 'Pimak',
    image: require('../images/p43.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br /> <br />
        1) MODEL: PTS04 <br />
        DIMENSION: 805x870x1030mm <br />
        WEIGHT KG: 120KG <br />
        CAPACITY /TRAY QUANTITY: 600x400mm 6TRAYS <br />
        POWER KW: 10.4KW <br /> <br /> 2) MODEL: PTS09 <br />
        DIMENSION: 805x870x1350mm <br />
        WEIGHT KG: 180KG <br />
        CAPACITY/ TRAY-QUANTITY: 600x400mm 9TRAYS <br />
        POWER KW: 15KW `
      </p>
    ),
  },
  {
    id: 44,
    name: 'CHICKEN ROTISSERIE  ',
    category: '',
    brand: 'Pimak',
    image: require('../images/p44.jpeg'),
    description: (
      <p>
        GAS TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: PI / M001 <br />
        DIMENSION: 720x500x650mm <br />
        POWER KW: 5 / 4300 <br />
        CAPACITY OF CHICKEN: 2/6 <br />
        MOTOR POWER/ Volt/ watt: 230/22 <br /> <br />
        2)MODEL: PI / M002 <br />
        DIMENSION: 790x500x650mm <br /> POWER KW: 5 / 4300 <br />
        CAPACITY OF CHICKEN: 3/9 <br />
        MOTOR POWER/ Volt/ watt: 230/22 <br />
        WEIGHT: 51KG <br /> <br />
        3) MODEL: M003 <br />
        DIMENSION: 880x500x650mm <br />
        POWER KW: 7.5/6450 <br />
        CAPACITY OF CHICKEN: 3/12 <br />
        MOTOR POWER/ Volt/ watt: 230/22 <br /> WEIGHT: 53KG <br /> <br />
        4) MODEL: M004 <br />
        DIMENSION:1030x500x650mm <br />
        POWER KW: 7.5/6450 <br />
        CAPACITY OF CHICKEN: 3/15 <br />
        MOTOR POWER/ Volt/ watt: 230/22 <br /> WEIGHT: 55KG
      </p>
    ),
  },
  {
    id: 45,
    name: 'CHICKEN ROTISSERIE  ',
    category: '',
    brand: 'Pimak',
    image: require('../images/p45.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br />
        1) MODEL: M001-E <br />
        DIMENSION: 720x500x650mm <br />
        POWER KW: 4KW <br />
        CAPACITY OF CHICKEN: 2/6 <br />
        MOTOR POWER/ Volt/ watt: 230/22 <br />
        WEIGHT: 50KG <br /> <br /> 2) MODEL: M002-E <br />
        DIMENSION: 790x500x650mm <br />
        POWER KW: 4KW <br />
        CAPACITY OF CHICKEN: 3/9 <br />
        MOTOR POWER/ Volt/ watt: 230/22 <br />
        WEIGHT: 54KW <br /> <br />
        3)MODEL: M003-E <br />
        DIMENSION: 880x500x650mm <br />
        POWER KW: 4KW <br />
        CAPACITY OF CHICKEN: 3/12 <br />
        MOTOR POWER/ Volt/ watt: 230/22 <br />
        WEIGHT: 56KG <br /> <br />
        4) MODEL: M004-E <br />
        DIMENSION: 1030x500x650mm <br />
        POWER KW: 4.5KW <br />
        CAPACITY OF CHICKEN: 3/15 <br />
        MOTOR POWER/ Volt/ watt: 230/22 <br />
        WEIGHT: 58KG
      </p>
    ),
  },
  {
    id: 46,
    name: 'CHICKEN ROTISSERIE  ',
    category: '',
    brand: 'Pimak',
    image: require('../images/p46.jpeg'),
    description: (
      <p>
        GAS TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: PI / M007B <br />
        DIMENSION: 920x600x1700mm <br />
        POWER KW: 7.5/6450 <br />
        CAPACITY OF CHICKEN: 5/20 <br />
        MOTOR POWER/ Volt/ watt: 230/40 <br />
        WEIGHT: 138KG <br /> <br />
        2) MODEL: PI 2/ M008B <br />
        DIMENSION: 1070x600x1700mm <br />
        POWER KW: 8/6880 <br />
        CAPACITY OF CHICKEN: 5/25 <br />
        MOTOR POWER/ Volt/ watt: 230/40 <br />
        WEIGHT: 138KG <br /> <br />
        3) MODEL:PI3/M009 <br />
        DIMENSION: 920x600x1900mm <br />
        POWER KW: 16/13760 <br />
        CAPACITY OF CHICKEN: 5+5/20+20 <br />
        MOTOR POWER/ Volt/ watt: 230/40 <br /> WEIGHT: 160KG <br /> <br />
        4) MODEL: PI4/M009B <br />
        DIMENSION: 1070x600x1900mm <br />
        POWER KW: 16/13760 <br />
        CAPACITY OF CHICKEN: 5+5/25+25 <br />
        MOTOR POWER/ Volt/ watt: 230/40 <br />
        WEIGHT: 170KG
      </p>
    ),
  },
  {
    id: 47,
    name: 'CHICKEN ROTISSERIE  ',
    category: '',
    brand: 'Pimak',
    image: require('../images/p47.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br />
        <br />
        1) MODEL: PI / M007E <br />
        DIMENSION: 920x600x1700mm <br />
        POWER KW: 8.5KW <br />
        CAPACITY OF CHICKEN: 5/20 <br />
        MOTOR POWER/ Volt/ watt: 230/40 WEIGHT: 122KG <br /> <br />
        2) MODEL: M008-E <br />
        DIMENSION: 1070x600x1700mm <br />
        POWER KW: 9.5KW <br />
        CAPACITY OF CHICKEN: 5/25 <br /> MOTOR POWER/ Volt/ watt: 230/40 <br />
        WEIGHT: 130KG <br /> <br /> 3) MODEL:M009-E <br />
        DIMENSION: 920x600x1900mm <br />
        POWER KW: 14KW <br />
        CAPACITY OF CHICKEN: 5+5/20+20 <br />
        MOTOR POWER/ Volt/ watt: 230/40 <br />
        WEIGHT: 140KG <br /> <br />
        4) MODEL: M009B-E <br />
        DIMENSION: 1070x600x1900mm <br />
        POWER KW: 14KG <br />
        CAPACITY OF CHICKEN: 5+5/25+25 <br />
        MOTOR POWER/ Volt/ watt: 230/40 <br /> WEIGHT: 153KG
      </p>
    ),
  },
  {
    id: 48,
    name: 'SHAWARMA GRILL ',
    category: '',
    brand: 'Pimak',
    image: require('../images/p48.jpeg'),
    description: (
      <p>
        GAS TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: D07/M77-1 (4P) <br />
        DIMENSION:470x750x117Mm <br /> POWER KW: 13/11180 <br />
        MOTOR POWER (VOLTAGE/WATT): 230/26 <br />
        WEIGHT: 40KG <br /> <br /> 2) MODEL: D08/M77-1 (5P) <br />
        DIMENSION:470x750x1350mm <br />
        POWER KW: 16.25/13975 <br />
        MOTOR POWER (VOLTAGE/WATT): 230/26 <br />
        WEIGHT: 44KG <br />
        <br />
        3) MODEL: D06/M77-(3A) <br />
        DIMENSION:710x500x800mm <br />
        POWER KW: 9.75/8385 <br />
        MOTOR POWER (VOLTAGE/WATT): 230/26 <br />
        WEIGHT: 40KG <br /> <br />
        4) MODEL: D07/M77-(4A) <br />
        DIMENSION:500x710x1050mm <br />
        POWER KW: 13/11180 <br />
        MOTOR POWER (VOLTAGE/WATT): 230/26 <br />
        WEIGHT: 45KG <br /> <br />
        5) MODEL: D08/M77-(5A) <br />
        DIMENSION:500x710x1100mm <br />
        POWER KW: 16.25/13975 <br />
        MOTOR POWER (VOLTAGE/WATT): 230/26 <br />
        WEIGHT: 52KG
      </p>
    ),
  },
  {
    id: 49,
    name: 'SHAWARMA GRILL  ',
    category: '',
    brand: 'Pimak',
    image: require('../images/p49.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br />
        1) MODEL: M077-3C <br />
        DIMENSION:500x700x920mm <br />
        POWER KW: 4.8KW <br />
        MOTOR POWER (VOLTAGE/WATT): 230/14 <br /> WEIGHT: 50KG <br /> <br />
        2) MODEL: M077-4C <br />
        DIMENSION:500x700x1150mm <br />
        POWER KW: 6.4KW <br />
        MOTOR POWER (VOLTAGE/WATT): 230/14 <br />
        WEIGHT: 52KG <br /> <br /> 3) MODEL: M077-3C <br />
        DIMENSION:500x710x920mm <br /> POWER KW: 4.8KW <br />
        MOTOR POWER (VOLTAGE/WATT): 230/14 <br />
        WEIGHT: 55KG
      </p>
    ),
  },
  {
    id: 50,
    name: 'SHAWARMA TOASTER  ',
    category: '',
    brand: 'Pimak',
    image: require('../images/p50.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br />
        <br />
        1) MODEL: M071-1 <br />
        DIMENSION: 540x400x300mm <br />
        POWER: 2.2KW <br />
        WEIGHT: 28KG <br />
        CAPACITY/ SLICE: 20 <br />
        VOLTAGE/ HZ: 230V/ 50Hz
      </p>
    ),
  },
  {
    id: 51,
    name: 'SHAWARMA TOASTER   ',
    category: '',
    brand: 'Pimak',
    image: require('../images/p51.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: M071–2 <br /> DIMENSION: 540x380x300mm <br />
        POWER: 2.3KW <br />
        WEIGHT: 30KG <br />
        CAPACITY/ SLICE: 20 <br />
        VOLTAGE/ HZ: 230V/ 50Hz
      </p>
    ),
  },
  {
    id: 52,
    name: 'Salamander    ',
    category: '',
    brand: 'Pimak',
    image: require('../images/p52.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: M020 <br />
        DIMENSION: 450x450x500mm <br />
        POWER:2.5KW <br />
        WEIGHT: 28kg <br />
        CAPACITY (Lift): 1 <br />
        VOLTAGE/ HZ: 230V/ 50Hz <br /> <br />
        2) MODEL:M020-1 <br />
        DIMENSION: 600x600x500mm <br />
        POWER: 5kw <br />
        WEIGHT: 38kg <br />
        CAPACITY (Lift): 1 <br />
        VOLTAGE/ HZ: 230V/ 50Hz
      </p>
    ),
  },
  {
    id: 53,
    name: 'CREPE  MACHINE  ',
    category: '',
    brand: 'Pimak',
    image: require('../images/p53.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: M098 <br />
        DIMENSION: 760x460x150mm <br />
        POWER: 2.2kw X 2 <br />
        WEIGHT: 30kg <br />
        CAPACITY: 2 x 0.35 <br />
        VOLTAGE/ HZ: 230V/ 50Hz <br /> <br />
        GAS TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: M098-G <br />
        DIMENSION: 760x460x170mm <br />
        POWER: 4 + 4 <br />
        WEIGHT: 30kg <br /> CAPACITY: 2 x 0.35
      </p>
    ),
  },
  {
    id: 54,
    name: 'CREPE  MACHINE   ',
    category: '',
    brand: 'Pimak',
    image: require('../images/p54.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: M097 <br />
        DIMENSION: 380x460x150mm <br />
        POWER: 2.2kw <br />
        WEIGHT: 17kg <br />
        CAPACITY: 1 x 0.35 <br />
        VOLTAGE/ HZ: 230V/ 50Hz <br /> <br />
        GAS TYPE <br />
        CATEGORIES <br /> <br /> 2) MODEL: M097-G <br />
        DIMENSION: 380x460x170mm <br />
        POWER KW: 4 <br />
        WEIGHT: 17Kg <br />
        CAPACITY: 1 x 0.35
      </p>
    ),
  },
  {
    id: 55,
    name: 'TABLE FRYER   ',
    category: '',
    brand: 'Pimak',
    image: require('../images/p55.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br />
        <br />
        1) MODEL: M059 <br />
        DIMENSION: 530x430x335mm <br />
        POWER KW: 4.5 <br />
        WEIGHT: 12kg <br />
        CAPACITY: 5 + 5L <br />
        VOLTAGE: 230v/50hz <br /> <br />
        2) MODEL: M060-1 <br />
        DIMENSION: 650x455x335mm <br />
        POWER KW: 7kw <br />
        WEIGHT: 18kg <br />
        CAPACITY: 8 + 8L <br />
        VOLTAGE: 230v/50hz
      </p>
    ),
  },
  {
    id: 56,
    name: 'TABLE FRYER   ',
    category: '',
    brand: 'Pimak',
    image: require('../images/p56.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: M057 <br />
        DIMENSION: 270x430x335mm <br />
        POWER KW: 2.25 <br />
        WEIGHT: 8kg <br />
        CAPACITY: 5L <br />
        VOLTAGE: 230v/50hz <br /> <br />
        2) MODEL: M060 <br />
        DIMENSION: 325x455x33mm <br />
        POWER KW:3.5 <br />
        WEIGHT: 13kg <br />
        CAPACITY: 8L <br />
        VOLTAGE: 230v/50hz
      </p>
    ),
  },
  {
    id: 57,
    name: 'MEAT MINCING MACHINE   ',
    category: '',
    brand: 'Bogazici',
    image: require('../images/p57.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: BKM.22E ECO <br />
        DIMENSION: 760x290x320mm <br />
        POWER KW: 1.1/1.5 <br />
        WEIGHT: 38kg <br />
        CAPACITY (Kg/h): 200 <br />
        VOLTAGE: 230v/50hz <br />
        <br />
        2) MODEL: BKM.32E ECO <br />
        DIMENSION: 760x290x320mm <br />
        POWER KW: 1.1/1.5 <br />
        WEIGHT:43kg <br />
        CAPACITY (Kg/h): 300 <br />
        VOLTAGE: 230v/50hz <br /> <br />
        3) MODEL: BKM.32 <br /> DIMENSION: 870x330x400mm <br />
        POWER KW/HP: 2.2-3/2.9-4 <br />
        WEIGHT: 65kg <br />
        CAPACITY (Kg/h): 600 <br />
        VOLTAGE: 230v/50hz
      </p>
    ),
  },
  {
    id: 58,
    name: 'MEAT MINCING MACHINE   ',
    category: '',
    brand: 'Bogazici',
    image: require('../images/p58.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: BPKM.42 <br /> DIMENSION: 960x470x480mm <br />
        POWER KW/HP: 5.5/7.4 <br />
        WEIGHT: 140kg <br />
        CAPACITY (Kg/h): 800 <br />
        VOLTAGE: 380v/50hz <br /> <br />
        2) MODEL: BPKM.42A <br />
        DIMENSION: 1000x540x930mm <br />
        POWER KW/HP: 5.5/7.4 <br />
        WEIGHT: 155kg <br />
        CAPACITY (Kg/h): 800 <br />
        VOLTAGE: 380v/50hz
      </p>
    ),
  },
  {
    id: 59,
    name: 'MEAT CUTTING MACHINE    ',
    category: '',
    brand: 'Bogazici',
    image: require('../images/p59.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: BSZK.2 <br />
        DIMENSION: 540x350x390mm <br />
        POWER KW/HP: 0.55/0.7 <br />
        WEIGHT: 20kg <br />
        CAPACITY (KG):2 <br />
        VOLTAGE: 220v/50hz <br /> <br />
        2) MODEL: BSZK.8 <br />
        DIMENSION: 700x470x520mm <br />
        POWER KW/HP: 3/4 <br />
        WEIGHT: 50kg <br />
        CAPACITY (Kg): 8 <br />
        VOLTAGE: 220v/50hz `
      </p>
    ),
  },
  {
    id: 60,
    name: 'BONE SAW MACHINE    ',
    category: '',
    brand: 'Bogazici',
    image: require('../images/p60.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: BKTA <br />
        DIMENSION: 650x600x990mm <br />
        POWER KW/HP: 1.1/1.5 <br />
        WEIGHT: 80KG <br />
        CAPACITY (MM): 300 <br />
        VOLTAGE: 220v/50hz <br /> <br />
        2)MODEL: BKT <br />
        DIMENSION: 480x680x1600mm <br />
        POWER KW/HP: 1.1/1.5 <br />
        WEIGHT: 70kg <br />
        CAPACITY (MM): 300 <br />
        VOLTAGE: 220v/50hz `
      </p>
    ),
  },
  {
    id: 61,
    name: 'SPIRAL DOUGH MIXERS   ',
    category: '',
    brand: 'Bogazici',
    image: require('../images/p61.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: BSH.35 <br />
        DIMENSION: 1070x550x1060mm <br />
        POWER KW/HP: 0.35/0.5 <br />
        WEIGHT: 255kg <br />
        CAPACITY/ ( FLOUR/DOUGH KG: 25/35 VOLTAGE: 380v/50hz <br /> <br />
        2) MODEL: BSH.50 <br />
        DIMENSION: 1150x600x1060mm <br />
        POWER KW/HP: 1.5-2.5/2-3.4 <br />
        WEIGHT: 260kg <br />
        CAPACITY/ ( FLOUR/DOUGH KG: 35/50 <br />
        VOLTAGE: 380v/50hz <br /> <br />
        3) MODEL: BSH.75 <br />
        DIMENSION: 1200x700x1300mm <br />
        POWER KW/HP: 3.5-5.5/4.7-7.4 <br />
        WEIGHT: 320kg <br />
        CAPACITY/ ( FLOUR/DOUGH KG: 50/75 <br />
        VOLTAGE: 380v/50hz <br /> <br />
        4) MODEL: BSH-135 <br />
        DIMENSION: 1600x900x1570mm <br />
        POWER KW/HP: 5.5-7.5/7.4-10.1 <br />
        WEIGHT: 400kg CAPACITY/ ( FLOUR/DOUGH KG: 100/135 <br />
        VOLTAGE: 380v/50hz <br /> <br />
        5) MODEL: BSH-235 <br /> DIMENSION: 1700x1000x1570mm <br />
        POWER KW/HP: 5.5-7.5/7.4-10.1 <br />
        WEIGHT: 420kG <br /> CAPACITY/ ( FLOUR/DOUGH KG: 150/235 <br /> VOLTAGE:
        380v/50hz
      </p>
    ),
  },
  {
    id: 62,
    name: 'PLANETARY MIXERS   ',
    category: '',
    brand: 'Bogazici',
    image: require('../images/p62.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: BM.10S <br />
        DIMENSION: 540x415x660mm <br />
        POWER KW/HP: 0.55/0.7 <br />
        WEIGHT: 54kg <br />
        CAPACITY (LITRES): 10 <br />
        VOLTAGE: 220/50hz <br /> <br />
        2) MODEL: BM.20S <br /> DIMENSION: 740x470x830mm <br />
        POWER KW/HP: 0.6-0.9/0.8-1.2 <br />
        WEIGHT: 94kg <br />
        CAPACITY (LITRES): 20 <br />
        VOLTAGE: 220/50hz <br /> <br />
        3) MODEL: BM.40S <br />
        DIMENSION: 840x600x1050m <br />
        POWER KW/HP: 1-1.7/1.3-2.3 <br />
        WEIGHT: 155kg <br />
        CAPACITY (LITRES): 20L <br />
        VOLTAGE: 220/50hz <br /> <br />
        3) MODEL: BM.60 <br />
        DIMENSION: 840x630x1050mm <br />
        POWER KW/HP: 1-1.7/1.3-2.3 <br />
        WEIGHT: 160kg <br />
        CAPACITY (LITRES): 60L <br />
        VOLTAGE: 220/50hz
      </p>
    ),
  },
  {
    id: 63,
    name: 'PIZZA DOUGH ROLLER   ',
    category: '',
    brand: 'Bogazici',
    image: require('../images/p63.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: BHA.40B <br />
        DIMENSION: 540x450x640mm <br />
        POWER KW/HP: 0.25/0.3 <br />
        WEIGHT: 45 <br />
        CAPACITY (CM): 40 <br />
        VOLTAGE: 220/50hz <br /> <br />
        2) MODEL: BHA.40TB <br />
        DIMENSION: 520x430x400mm <br />
        POWER KW/HP: 0.25/0.3 <br />
        WEIGHT: 30kg <br />
        CAPACITY (CM): 40 <br />
        VOLTAGE: 220/50hz
      </p>
    ),
  },
  {
    id: 64,
    name: 'PIZZA DOUGH ROLLER   ',
    category: '',
    brand: 'Bogazici',
    image: require('../images/p64.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: BHA.40 <br />
        DIMENSION: 540x450x640mm <br />
        POWER KW/HP: 0.25/0.3 <br />
        WEIGHT: 45KG <br />
        CAPACITY (CM): 40 <br />
        VOLTAGE: 220/50hz
      </p>
    ),
  },
  {
    id: 65,
    name: 'POTATO PEELING MACHINE   ',
    category: '',
    brand: 'Bogazici',
    image: require('../images/p65.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: BPS.10 <br />
        DIMENSION: 750x430x870mm <br />
        POWER KW/HP: 0.55/0.7 <br />
        WEIGHT: 46kg <br />
        CAPACITY (KG): 10kg <br />
        VOLTAGE: 220v/50hz <br /> <br />
        2) MODEL: BPS.25 <br />
        DIMENSION: 820x520x990mm <br />
        POWER KW/HP: 1.10/1.5 <br />
        WEIGHT: 72kg <br />
        CAPACITY (KG): 25kg <br />
        VOLTAGE: 220v/50hz <br /> <br />
        3) MODEL: BPS.35 <br />
        DIMENSION: 820x520x1080mm <br />
        POWER KW/HP: 1.50/2.0 <br />
        WEIGHT: 75kg <br />
        CAPACITY (KG): 35kg <br />
        VOLTAGE: 220v/50hz <br /> <br />
        4) MODEL: BPS.50 <br />
        DIMENSION: 960x630x1060mm <br />
        POWER KW/HP: 2.20/2.9 <br />
        WEIGHT: 100kg <br />
        CAPACITY (KG): 50kg <br />
        VOLTAGE: 220v/50hz
      </p>
    ),
  },
  {
    id: 66,
    name: 'FRUITS AND VEGETABLE SLICING MACHINE   ',
    category: '',
    brand: 'Bogazici',
    image: require('../images/p66.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: BSU <br />
        DIMENSION: 740x410x510mm <br />
        POWER KW/HP: 0.25/0.3 <br />
        WEIGHT: 25kg <br />
        CAPACITY (KG/H) 300 <br />
        VOLTAGE: 220v/50hz
      </p>
    ),
  },
  {
    id: 67,
    name: 'FRUITS AND VEGETABLE SLICING MACHINE   ',
    category: '',
    brand: 'Bogazici',
    image: require('../images/p67.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: BSA <br />
        DIMENSION: 790x530x920mm <br />
        POWER KW/HP: 0.25/0.3 <br />
        WEIGHT: 30kg <br />
        CAPACITY (KG/H) 300 <br />
        VOLTAGE: 220v/50hz
      </p>
    ),
  },
  {
    id: 68,
    name: ' DOUBLE DOOR REFRIGERATOR    ',
    category: '',
    brand: 'Dampak',
    image: require('../images/p68.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: 22BF2S-GN <br />
        DIMENSION: 1390x820x2100mm <br />
        POWER (W): 660 <br />
        WEIGHT: 205kg <br />
        CAPACITY (LT.) 1400 <br />
        Liters VOLTAGE: 220v/50hz <br />
        TEMPERATURE: +2/+8 <br /> <br />
        2) MODEL: 22DDF2S-GN <br />
        DIMENSION: 1390x820x2100mm <br />
        POWER (W): 795 <br />
        WEIGHT: 208kg <br />
        CAPACITY (LT.) 1400 Liters <br />
        VOLTAGE: 220v/50hz <br /> TEMPERATURE: -15/-18
      </p>
    ),
  },
  {
    id: 69,
    name: ' SINGLE DOOR REFRIGERATOR    ',
    category: '',
    brand: 'Dampak',
    image: require('../images/p69.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: 22DBF1S-GN <br />
        DIMENSION: 690x820x2100mm <br />
        POWER (W): 420 <br />
        WEIGHT KG: 112 <br />
        CAPACITY (LT.) 700 <br />
        VOLTAGE: 220v/50hz <br />
        TEMPERATURE: +2/+8 <br /> <br />
        2) MODEL: 22DDF1S-GN <br />
        DIMENSION: 690x820x2100mm <br />
        POWER (W): 500 <br />
        WEIGHT KG: 113 <br />
        CAPACITY (LT.) 700 <br />
        VOLTAGE: 220v/50hz <br />
        TEMPERATURE: -15/-18
      </p>
    ),
  },
  {
    id: 70,
    name: ' REFRIGERATOR WITH TWO GLASS DOORS   ',
    category: '',
    brand: 'Dampak',
    image: require('../images/p70.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: 22DBF2C-GN <br />
        DIMENSION: 1390x820x2100mm <br />
        POWER (W): 660 <br />
        WEIGHT KG: 240 <br />
        CAPACITY (LT.) 1400 Liters <br />
        VOLTAGE: 220v/50hz <br /> TEMPERATURE: +2/+8
      </p>
    ),
  },
  {
    id: 71,
    name: ' DOUBLE GLASS DOORS MEAT HANGING SHOWCASE   ',
    category: '',
    brand: 'Dampak',
    image: require('../images/p71.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: 22DBS2C-GN.45 <br />
        DIMENSION: 1390x820x210mm <br />
        POWER (W): 725 <br />
        WEIGHT KG: 270 <br />
        CAPACITY (LT.) 1342 <br />
        VOLTAGE: 220v/50hz <br />
        TEMPERATURE: -2/ +8
      </p>
    ),
  },
  {
    id: 72,
    name: ' SINGLE GLASS DOOR MEAT HANGING SHOWCASE   ',
    category: '',
    brand: 'Dampak',
    image: require('../images/p72.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: 22DBS1C-GN.45 <br />
        DIMENSION: 690x820x2100mm <br />
        POWER (W): 660 <br />
        WEIGHT KG: 160 <br />
        CAPACITY (LT.) 602 <br />
        VOLTAGE: 220v/50hz <br />
        TEMPERATURE: -2/ +8
      </p>
    ),
  },
  {
    id: 73,
    name: ' UNDER-COUNTER REFRIGERATOR    ',
    category: '',
    brand: 'Dampak',
    image: require('../images/p73.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: 22TBF2S-70 <br />
        DIMENSION: 1500x700x850mm <br />
        POWER (W): 420 <br />
        WEIGHT KG: 110 <br />
        CAPACITY (LT.) 295 <br />
        VOLTAGE: 220v/50hz <br />
        TEMPERATURE: +2/+8 <br /> <br />
        2) MODEL: 22TDF2S-70 <br />
        DIMENSION: 1500x700x850mm <br />
        POWER (W): 1000 <br />
        WEIGHT KG: 112 <br />
        CAPACITY (LT.) 295 <br />
        VOLTAGE: 220v/50hz <br />
        TEMPERATURE: -15/-18
      </p>
    ),
  },
  {
    id: 74,
    name: ' 3DOORS UNDER-COUNTER REFRIGERATOR    ',
    category: '',
    brand: 'Dampak',
    image: require('../images/p74.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br />
        <br />
        1) MODEL: 22TBF3S-70 <br />
        DIMENSION: 2000x700x850mm <br />
        POWER (W): 400 <br />
        WEIGHT KG: 137 <br />
        CAPACITY (LT.) 455 <br />
        VOLTAGE: 220v/50hz <br />
        TEMPERATURE: +2/+8 <br /> <br />
        2) MODEL: 22TDF3S-70 <br />
        DIMENSION: 2000x700x850mm <br />
        POWER (W): 1000 <br />
        WEIGHT KG: 139 <br />
        CAPACITY (LT.) 455 <br /> VOLTAGE: 220v/50hz <br />
        TEMPERATURE: -15/-18
      </p>
    ),
  },
  {
    id: 75,
    name: 'DRAWERS UNDER-COUNTER REFRIGERATOR    ',
    category: '',
    brand: 'Dampak',
    image: require('../images/p75.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: 22TBF0S-70 <br />
        DIMENSION: 1500x700x850mm <br />
        POWER (W): 420 <br />
        WEIGHT KG: 125 <br />
        CAPACITY (LT.) 108 <br />
        VOLTAGE: 220v/50hz <br />
        TEMPERATURE: +2/+8
      </p>
    ),
  },
  {
    id: 76,
    name: '  DRAWERS UNDER-COUNTER REFRIGERATOR    ',
    category: '',
    brand: 'Dampak',
    image: require('../images/p76.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: 22TBF0S-70.6C <br />
        DIMENSION: 2000x700x850mm <br />
        POWER (W): 460 <br />
        WEIGHT KG: 145 <br />
        CAPACITY (LT.) 135 <br />
        VOLTAGE: 220v/50hz <br />
        TEMPERATURE: +2/+8
      </p>
    ),
  },
  {
    id: 77,
    name: ' 3DOORS PIZZA PREPARATION REFRIGERATOR    ',
    category: '',
    brand: 'Dampak',
    image: require('../images/p77.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: 22TBF3S-PA70 <br />
        DIMENSION: 2000x700x109mm <br />
        POWER (W): 450 <br />
        WEIGHT KG: 130 <br />
        CAPACITY (LT.) 450 <br />
        VOLTAGE: 220v/50hz <br />
        TEMPERATURE: +2/+8
      </p>
    ),
  },
  {
    id: 78,
    name: ' 2DOORS PIZZA PREPARATION REFRIGERATOR    ',
    category: '',
    brand: 'Dampak',
    image: require('../images/p78.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: 22TBF2S-PA70 <br />
        DIMENSION: 1500x700x109mm <br />
        POWER (W): 450 <br />
        WEIGHT KG: 115 <br />
        CAPACITY (LT.) 290 <br />
        VOLTAGE: 220v/50hz <br />
        TEMPERATURE: +2/+8
      </p>
    ),
  },
  {
    id: 79,
    name: ' BANQUET TROLLEY   ',
    category: '',
    brand: 'Dampak',
    image: require('../images/p79.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: 22B001S-GN <br />
        DIMENSION: 675x800x1720mm <br />
        VOLTAGE (Volt/Hz): 220-240 /50Hz <br />
        WEIGHT KG: 93 <br />
        CAPACITY (LT.) 462 <br />
        TEMPERATURE: +60/ +70 <br />
        TRAYS: 15 <br /> <br />
        2) MODEL: 22B001S-GN1/1 <br /> DIMENSION: 500x775x1720mm <br />
        VOLTAGE (Volt/Hz): 220-240 /50Hz <br /> WEIGHT KG: 105 <br />
        CAPACITY (LT.) <br />
        TEMPERATURE: +60/ +70 <br />
        TRAYS: 13
      </p>
    ),
  },
  {
    id: 80,
    name: ' BANQUET TROLLEY',
    category: '',
    brand: 'Dampak',
    image: require('../images/p80.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br />
        1) MODEL: 22B002S-GN <br />
        DIMENSION: 1290x800x1720mm <br />
        VOLTAGE (Volt/Hz): 220-240 /50Hz <br />
        WEIGHT KG: 170 <br /> CAPACITY (LT.) 925 <br />
        TEMPERATURE: +60/ +70 <br /> TRAYS: 30
      </p>
    ),
  },
  {
    id: 81,
    name: ' MIDDLE TYPE REFRIGERATED SALAD BAR ',
    category: '',
    brand: 'Dampak',
    image: require('../images/p81.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: 22B002S-GN <br />
        DIMENSION: 1290x800x1720mm <br />
        VOLTAGE (Volt/Hz): 220-240 /50Hz <br /> WEIGHT KG: 170 <br />
        CAPACITY (LT.) 925 <br />
        TEMPERATURE: +60/ +70 <br /> TRAYS: 30
      </p>
    ),
  },
  {
    id: 82,
    name: ' COFFEE MACHINE ( 3 GROUP )',
    category: '',
    brand: 'Iberital',
    image: require('../images/p82.jpeg'),
    description: '',
  },
  {
    id: 83,
    name: '  COFFEE MACHINE ( 1 GROUP )',
    category: '',
    brand: 'Iberital',
    image: require('../images/p83.jpeg'),
    description: '',
  },
  {
    id: 84,
    name: ' COFFEE MACHINE ( 2 GROUP )',
    category: '',
    brand: 'Iberital',
    image: require('../images/p84.jpeg'),
    description: '',
  },
  {
    id: 85,
    name: 'UNDER-COUNTER  TWO GLASS DOOR REFRIGERATOR',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p85.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br /> CATEGORIES <br /> <br />
        1) MODEL: TGO-4L2G <br />
        DIMENSION: 1500x760x850mm <br />
        VOLTAGE: 220v/50hz <br />
        TEMPERATURE: 2-8 <br /> <br />
        2) MODEL: TGO-4L2G <br />
        DIMENSION:1200x760x850mm <br />
        VOLTAGE: 220v/50hz <br />
        TEMPERATURE: 2-8
      </p>
    ),
  },
  {
    id: 86,
    name: 'UNDER-COUNTER  THREE GLASS DOOR REFRIGERATOR ',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p86.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: TGO-4L2G <br />
        DIMENSION: 1800x760x850mm <br />
        VOLTAGE: 220v/50hz <br />
        TEMPERATURE: 2-8
      </p>
    ),
  },
  {
    id: 87,
    name: 'GAS DECK OVEN',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p87.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: 3deck 9trays <br />
        DIMENSION: 1625x805x1630mm <br />
        VOLTAGE: 220v/50hz <br />
        ( STACKABLE WITH GOLD MIRROR STAINLESS STEEL) <br />
        <br />
        2) MODEL: 3deck 12trays <br />
        DIMENSION: 1625x1030x1630mm <br />
        VOLTAGE: 220v/50hz <br />
        ( STACKABLE WITH GOLD MIRROR STAINLESS STEEL) <br /> <br />
        3)MODEL: 3deck 15trays <br />
        DIMENSION: 1625x1230x1630mm <br /> VOLTAGE: 220v/50hz <br />
        ( WITH GOLD MIRROR STAINLESS STEEL) <br />
        STACKABLE: not applicable <br /> <br />
        4) MODEL: 3deck 18trays <br />
        DIMENSION: 1625x1430x1630mm <br />
        VOLTAGE: 220v/50hz <br /> ( WITH GOLD MIRROR STAINLESS STEEL) <br />
        STACKABLE: not applicable <br /> <br />
        5) MODEL: 3deck 6trays <br />
        DIMENSION: 1625x805x1630mm <br /> VOLTAGE: 220v/50hz <br /> ( STACKABLE
        WITH GOLD MIRROR STAINLESS STEEL)
      </p>
    ),
  },
  {
    id: 88,
    name: 'GAS DECK OVEN',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p88.jpeg'),
    description: (
      <p>
        GAS TYPE <br />
        CATEGORIES <br />
        1) MODEL: 2deck 4trays <br />
        DIMENSION: 1200x805x1165mm <br />
        VOLTAGE: 220v/50hz <br />( STACKABLE WITH GOLD MIRROR STAINLESS STEEL)
      </p>
    ),
  },
  {
    id: 89,
    name: 'COOKING RANGE STATION ',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p89.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br />
        <br />
        1) MODEL: STATION HP <br />
        DIMENSION: <br />
        VOLTAGE: 220/380v/ 50Hz
      </p>
    ),
  },
  {
    id: 90,
    name: 'GAS SHAWARMA GRILL',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p90.jpeg'),
    description: (
      <p>
        GAS TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: 2 + 2burner twin <br />
        VOLTAGE: 220v/50hz
      </p>
    ),
  },
  {
    id: 91,
    name: 'GAS SHAWARMA GRILL',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p91.jpeg'),
    description: (
      <p>
        GAS TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: 3 burners <br /> DIMENSION: 530x630x920mm <br />
        VOLTAGE: 220v/50hz <br /> <br />
        Gas type: <br />
        LPG WEIGHT: 31kg
      </p>
    ),
  },
  {
    id: 92,
    name: ' GAS SHAWARMA GRILL',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p92.jpeg'),
    description: (
      <p>
        GAS TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: 2 burners <br />
        DIMENSION: 480x630x790mm <br />
        VOLTAGE: 220v/50hz <br /> <br />
        Gas type: <br />
        LPG WEIGHT: 27kg
      </p>
    ),
  },
  {
    id: 93,
    name: 'ELECTRIC SHAWARMA GRILL',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p93.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br /> CATEGORIES <br /> <br />
        1) MODEL: 3 burners <br />
        DIMENSION: 480x630x790mm <br />
        VOLTAGE: 220v/50hz <br />
        WEIGHT: 27kg <br />
        ELECTRIC TYPE <br />
        CATEGORIES <br />
        1) MODEL: 3 burners <br />
        DIMENSION: 480x630x790mm <br />
        VOLTAGE: 220v/50hz <br />
        WEIGHT: 27kg
      </p>
    ),
  },
  {
    id: 94,
    name: 'GAS DECK OVEN',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p94.jpeg'),
    description: (
      <p>
        GAS TYPE <br />
        CATEGORIES <br />
        <br />
        1) MODEL: 3deck 9trays <br />
        DIMENSION: 1625x805x1630mm <br />
        VOLTAGE: 220v/50hz <br />
        ( STAINLESS STEEL BODY) <br /> <br />
        2) MODEL: 3deck 12trays <br />
        DIMENSION: 1625x1030x1630mm <br />
        VOLTAGE: 220v/50hz <br />
        ( STAINLESS STEEL BODY ) <br />
        <br />
        3) MODEL: 3deck 15trays <br /> DIMENSION: 1625x1230x1630mm <br />
        VOLTAGE: 220v/50hz <br />
        ( STAINLESS STEEL BODY) <br /> <br />
        4) MODEL: 3deck 18trays <br />
        DIMENSION: 1625x1430x1630mm <br />
        VOLTAGE: 220v/50hz <br />
        ( STAINLESS STEEL BODY) <br /> <br />
        5) MODEL: 3deck 6trays <br /> DIMENSION: 1200x805x1630mm <br /> VOLTAGE:
        220v/50hz <br /> ( STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 95,
    name: 'GAS DECK OVEN',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p95.jpeg'),
    description: (
      <p>
        1) MODEL: 2deck 4trays <br />
        DIMENSION: 1200x805x1165mm <br />
        VOLTAGE: 220v/50hz <br />( STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 96,
    name: 'ELECTRIC DECK OVEN',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p96.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: 3deck 6trays <br />
        DIMENSION: 1250x805x1450mm <br /> VOLTAGE: 380v/50Hz <br />
        ( STAINLESS STEEL BODY) <br /> <br />
        2) MODEL: 3deck 9trays <br />
        DIMENSION: 1685x805x1450mm <br />
        VOLTAGE: 380v/50Hz <br />
        ( STAINLESS STEEL BODY) <br /> <br />
        3) MODEL: 3deck 12trays <br />
        DIMENSION: 1685x1045x1450mm <br />
        VOLTAGE: 380v/50Hz <br />
        ( STAINLESS STEEL BODY) <br /> <br />
        4) MODEL: 3deck 15trays <br />
        DIMENSION: 1685x1220x1450mm <br />
        VOLTAGE: 380v/50Hz <br />
        POWER KW: 30 <br />
        ( STAINLESS STEEL BODY) <br /> <br />
        5) MODEL: 3deck 18trays <br />
        DIMENSION: 1685x1420x1450mm <br />
        VOLTAGE: 380v/50Hz <br />
        POWER KW: 30 <br /> ( STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 97,
    name: 'ELECTRIC DECK OVEN',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p97.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br /> <br />
        3) MODEL: 4deck 16trays <br /> DIMENSION: 1685x1020x1845mm <br />
        VOLTAGE: 380v/50Hz <br />
        POWER KW: 40 <br /> ( STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 98,
    name: 'ELECTRIC DECK OVEN',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p98.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: 3deck 6trays <br />
        DIMENSION: 1250x805x1450mm <br />
        VOLTAGE: 380v/50Hz <br />
        POWER KW: 19 <br />
        ( GOLD MIRROR STAINLESS STEEL BODY) <br /> <br />
        2) MODEL: 3deck 9trays <br />
        DIMENSION: 1685x805x1450mm <br />
        VOLTAGE: 380v/50Hz <br /> POWER KW: 24 <br />( GOLD MIRROR STAINLESS
        STEEL BODY) <br /> <br />
        3) MODEL: 3deck 12trays <br /> DIMENSION: 1685x1045x1450mm <br />{' '}
        VOLTAGE: 380v/50Hz <br />
        POWER KW: 30 <br /> ( GOLD MIRROR STAINLESS STEEL BODY) <br /> <br />
        4) MODEL: 3deck 15trays <br />
        DIMENSION: 1685x1220x1450mm <br />
        VOLTAGE: 380v/50Hz <br /> POWER KW: 30 <br /> ( GOLD MIRROR STAINLESS
        STEEL BODY) <br /> <br /> 5) MODEL: 3deck 18trays <br />
        DIMENSION: 1685x1420x1450mm <br /> VOLTAGE: 380v/50Hz <br /> POWER KW:
        30 <br /> ( GOLD MIRROR STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 99,
    name: 'GAS DECK OVEN',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p99.jpeg'),
    description: (
      <p>
        GAS TYPE <br /> <br />
        1) MODEL: 4deck 16trays <br />
        DIMENSION: 1685x1020x2100mm <br />
        VOLTAGE: 220v/50Hz <br />( STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 100,
    name: 'GAS DECK OVEN',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p100.jpeg'),
    description: (
      <p>
        GAS TYPE <br /> CATEGORIES <br /> <br />
        1) MODEL: 1deck 2trays <br />
        DIMENSION: 1200x805x610 <br />
        VOLTAGE: 220v/50hz <br />( GOLD MIRROR STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 101,
    name: 'ELECTRIC DECK OVEN',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p101.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: 2deck 4trays <br />
        DIMENSION: 1250x805x1300mm <br /> VOLTAGE: 380v/50Hz <br />
        POWER KW: 13 <br />( GOLD MIRROR STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 102,
    name: 'GAS DECK OVEN',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p102.jpeg'),
    description: (
      <p>
        GAS TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: 1deck 2trays <br />
        DIMENSION: 1200x805x610 <br />
        VOLTAGE: 220v/50hz <br />( STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 103,
    name: 'ELECTRIC DECK OVEN',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p103.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: 1Deck 2trays <br />
        DIMENSION: 1250x805x610mm <br />
        VOLTAGE: 380v/50Hz <br />
        POWER KW: 6 <br />( GOLD MIRROR STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 104,
    name: 'ELECTRIC DECK OVEN',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p104.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: 1Deck 2trays <br />
        DIMENSION: 1250x805x610mm <br />
        VOLTAGE: 220v/50Hz <br />
        POWER KW: 6 <br />( STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 105,
    name: 'ELECTRIC 4 HOT PLATE COOKER WITH OVEN',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p105.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: 4 HOT PLATE <br />
        DIMENSION: 800x900x920mm <br />
        VOLTAGE: 380v/50Hz <br />
        POWER KW: 4x4+4.8 <br />( STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 106,
    name: 'ELECTRIC 6 HOT PLATE COOKER WITH OVEN',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p106.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br />
        1) MODEL: 6 HOT PLATE <br />
        VOLTAGE: 380v/50Hz <br />
        POWER KW: 4x6+4.8 <br />( STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 107,
    name: '4 BURNER GAS COOKER WITH CABINET ',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p107.jpeg'),
    description: (
      <p>
        GAS TYPE <br />
        CATEGORIES <br />
        <br />
        1) MODEL: 4 BURNERS <br />
        DIMENSION: 800x900x920mm <br />
        LPG2800-3700Pa: 2.06Kg/h <br />
        POWER KW: 7.8x4 <br />( STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 108,
    name: '8 BURNER GAS COOKER WITH DOUBLE OVEN',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p108.jpeg'),
    description: (
      <p>
        GAS TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: 8 BURNERS <br />
        DIMENSION: 1600x900x920mm <br />
        OVEN DIMENSION: 560x700x270mm X2 <br />
        LPG2800-3700Pa: 3.55KG/h <br />
        POWER KW: 7.5x4+5.5x4+5.8KW <br />( STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 109,
    name: 'STANDING GAS FRYER WITH CABINET ',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p109.jpeg'),
    description: (
      <p>
        GAS TYPE <br /> CATEGORIES <br /> <br />
        1) MODEL: 1 TANK 2BASKET OR 1BASKET <br />
        DIMENSION: 400x900x920mm <br />
        LPG2800-3700Pa: 1.23Kg/h <br />
        CAPACITY: 26L <br />( STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 110,
    name: 'STANDING GAS FRYER WITH CABINET ',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p110.jpeg'),
    description: (
      <p>
        GAS TYPE <br />
        CATEGORIES <br />
        1) MODEL: 2 TANK 4BASKET OR 2BASKET <br />
        DIMENSION: 800x900x920mm <br />
        LPG2800-3700Pa: 2.46Kg/h <br />
        CAPACITY: 26LX2=52L <br />( STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 111,
    name: '6 BURNER GAS COOKER WITH OVEN ',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p111.jpeg'),
    description: (
      <p>
        GAS TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: 6 BURNERS <br />
        DIMENSION: 1200x900x920mm <br /> OVEN DIMENSION:560x700x270mm <br />
        LPG2800-3700Pa: 2.96Kg/h <br />( STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 112,
    name: '4 BURNER GAS COOKER WITH OVEN',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p112.jpeg'),
    description: (
      <p>
        GAS TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: 4 BURNERS <br />
        DIMENSION: 800x900x920mm <br />
        OVEN DIMENSION:460x645x270mm <br />
        LPG2800-3700Pa: 2.62Kg/h <br />( STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 113,
    name: '4 BURNER GAS COOKER WITH CABINET  ',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p113.jpeg'),
    description: (
      <p>
        GAS TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: 4 BURNERS <br />
        DIMENSION: 800x900x920mm <br />
        LPG2800-3700Pa: 2.06Kg/h <br />( STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 114,
    name: '6 BURNER GAS COOKER WITH CABINET  ',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p114.jpeg'),
    description: (
      <p>
        GAS TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: 6 BURNERS <br />
        DIMENSION: 1200X900X920 <br />
        LPG2800-3700Pa: 2.06Kg/h <br />( STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 115,
    name: '4 BURNER GAS COOKER WITH OVEN/ GRIDDLE ',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p115.jpeg'),
    description: (
      <p>
        GAS TYPE <br />
        CATEGORIES <br />
        1) MODEL: 4 BURNERS/ GRIDDLE <br />
        DIMENSION: 1200X900X920 <br />
        OVEN DIMENSION: 560x700x270mm <br />( STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 116,
    name: ' 4 BURNER GAS COOKER WITH OVEN ',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p116.jpeg'),
    description: (
      <p>
        GAS TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: 4 BURNERS <br />
        DIMENSION: 800x900x920mm <br />
        OVEN DIMENSION:460x645x270mm br LPG2800-3700Pa: 2.62Kg/h <br />(
        STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 117,
    name: 'STANDING GAS LAVA ROCK GRILL WITH CABINET  ',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p117.jpeg'),
    description: (
      <p>
        GAS TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: GAS LAVA ROCK <br />
        DIMENSION: 800x900x920mm <br />( STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 118,
    name: 'STANDING GAS GRIDDLE WITH CABINET  ',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p118.jpeg'),
    description: (
      <p>
        1) MODEL: GAS GRIDDLE <br />
        DIMENSION: 800x900x920mm <br />( STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 119,
    name: 'STANDING ELECTRIC GRIDDLE WITH CABINET  ',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p119.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: ELECTRIC GRIDDLE <br />
        DIMENSION: 800x900x920mm <br />( STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 120,
    name: 'STANDING ELECTRIC LAVA ROCK GRILL WITH CABINET ',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p120.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: ELECTRIC LAVA ROCK <br />
        DIMENSION: 800x900x920mm <br /> ( STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 121,
    name: 'STANDING ELECTRIC PASTA COOKER WITH CABINET  ',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p121.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: ELECTRIC PASTA COOKER <br />
        DIMENSION: 800x900x920mm <br />( STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 122,
    name: 'STANDING ELECTRIC FRYER WITH CABINET ',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p122.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: 2 TANK 4BASKET OR 2BASKET <br />
        DIMENSION: 800x900x920mm <br />
        CAPACITY: 26LX2=52L <br />( STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 123,
    name: 'STANDING ELECTRIC FRYER WITH CABINET ',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p123.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: 1TANK 2BASKET OR 1BASKET <br />
        DIMENSION: 400x900x920mm <br />
        CAPACITY: 26L <br />( STAINLESS STEEL BODY)
      </p>
    ),
  },

  {
    id: 124,
    name: 'TILTING BRATT PAN',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p124.jpeg'),
    description: (
      <p>
        CATEGORIES <br /> <br />
        1) MODEL: GAS TILTING BRATT PAN <br />
        DIMENSION: 800x900x920mm <br />
        LPG2800-3700Pa: 1.51Kg/h <br /> PRESSURE: 21kpa <br />
        CAPACITY: 80L <br /> <br />
        2) MODEL:ELECTRIC TILTING BRATT PAN <br />
        DIMENSION: 800x900x920mm <br />
        POWER KW: 12 <br />
        VOLTAGE: 380V <br />
        CAPACITY: 80L
      </p>
    ),
  },
  {
    id: 125,
    name: 'BOILING PAN',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p125.jpeg'),
    description: (
      <p>
        CATEGORIES <br /> <br />
        1) MODEL: GAS BOILING PAN <br />
        DIMENSION: 800x900x920mm <br />
        LPG2800-3700Pa: 1.78Kg/h <br />
        PRESSURE: 25kpa <br />
        AVAILABLE CAPACITIES : 100L and 150L <br /> <br />
        2) MODEL: ELECTRIC BOILING PAN <br />
        DIMENSION: 800x900x920mm <br />
        VOLTAGE: 380V <br />
        POWER KW: 24 <br />
        PRESSURE: 50kpa <br />
        AVAILABLE CAPACITIES : 100L and 150L
      </p>
    ),
  },
  {
    id: 126,
    name: 'STANDING ELECTRIC PASTA COOKER WITH CABINET',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p126.jpeg'),
    description: (
      <p>
        ELECTRIC TYPE <br />
        CATEGORIES <br /> <br />
        1) MODEL: ELECTRIC PASTA COOKER <br />
        DIMENSION: 400x900x920mm <br />( STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 127,
    name: 'TABLE LAVA ROCK GRILL  ',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p127.jpeg'),
    description: (
      <p>
        1) MODEL: ELECTRIC LAVA ROCK <br />
        DIMENSION: 650x612x530mm <br />
        VOLTAGE: 220-240V/50Hz <br />
        ( STAINLESS STEEL BODY) <br /> <br />
        2) MODEL: Gas LAVA ROCK <br />
        DIMENSION: 650x612x530mm <br />
        GAS TYPE: LPG <br />( STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 128,
    name: 'TABLE 4 HOT PLATE ELECTRIC COOKER ',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p128.jpeg'),
    description: (
      <p>
        1) MODEL: 4 HOT PLATE COOKER <br />
        DIMENSION: 600x600x350mm <br />
        VOLTAGE: 220-240V/50Hz <br />( STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 129,
    name: 'TABLE GRIDDLE ',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p129.jpeg'),
    description: (
      <p>
        1) MODEL: GAS GRIDDLE <br />
        DIMENSION: 600x612x530mm <br />
        GAS TYPE: LPG <br />
        ( STAINLESS STEEL BODY) <br /> <br />
        2) MODEL: ELECTRIC GRIDDLE <br />
        DIMENSION: 600x600x350mm <br />
        VOLTAGE: 220-240V/50Hz <br />
        POWER KW: 5kw <br /> ( STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 130,
    name: 'TABLE FRYER',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p130.jpeg'),
    description: (
      <p>
        1) MODEL: GAS FRYER <br />
        DIMENSION: 680x615x630mm <br />
        CAPACITY: 2 TANK 2 BASKETS <br />
        GAS TYPE: LPG <br />
        CAPACITIES AVAILABLE: (18L + 18L) (13L + 13L) ( 16L + 16L) ( 10L + 10L)
        and (8L + 8L) <br />
        ( STAINLESS STEEL BODY) <br /> <br />
        2) MODEL: ELECTRIC FRYER <br />
        DIMENSION: 680x615x630mm <br />
        CAPACITY: 2 TANK 2 BASKETS GAS <br />
        TYPE: LPG <br /> CAPACITIES AVAILABLE: (18L + 18L) (13L + 13L) ( 16L +
        16L) ( 10L + 10L) and (8L + 8L) <br />( STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 131,
    name: 'TABLE FRYER',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p131.jpeg'),
    description: (
      <p>
        1) MODEL: GAS FRYER <br />
        DIMENSION: 340x615x630mm <br />
        CAPACITY: 1 TANK 1 BASKET <br />
        GAS TYPE: LPG <br />
        CAPACITIES AVAILABLE: (18L) (13L) (16L) (12L) (8L) (10L) <br />
        ( STAINLESS STEEL BODY) <br /> <br />
        2) MODEL: ELECTRIC FRYER <br />
        DIMENSION: 340x615x630mm <br />
        CAPACITY: 1 TANK 1 BASKET <br /> TYPE: ELECTRIC CAPACITIES <br />
        AVAILABLE: (18L) (13L) (16L) (12L) (8L) (10L) <br /> ( STAINLESS STEEL
        BODY)
      </p>
    ),
  },
  {
    id: 132,
    name: 'TABLE FRYER',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p132.jpeg'),
    description: (
      <p>
        1) MODEL: GAS FRYER <br />
        DIMENSION: 592x410x340mm <br />
        CAPACITY: 2 TANK 2 BASKET <br />
        GAS TYPE: LPG <br />
        CAPACITIES AVAILABLE: (18L + 18L (13L+ 13L) (16L + 16L) (12L + 12L) (8L
        + 8L) (10L + 10L) ( 11L + 11L ) <br />
        ( STAINLESS STEEL BODY) 2) <br /> <br />
        MODEL: ELECTRIC FRYER <br />
        DIMENSION: 592x410x340mm <br />
        CAPACITY: 2 TANK 2 BASKET <br />
        SOURCE: ELECTRIC <br />
        CAPACITIES AVAILABLE: (18L + 18L (13L+ 13L) (16L + 16L) (12L + 12L) (8L
        + 8L) (10L + 10L) ( 11L + 11L ) <br /> ( STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 133,
    name: 'TABLE FRYER',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p133.jpeg'),
    description: (
      <p>
        1) MODEL: GAS FRYER <br />
        DIMENSION: 370x440x340mm <br />
        CAPACITY: 1 TANK 1 BASKET <br />
        GAS TYPE: LPG <br />
        CAPACITIES AVAILABLE: (18L) (13L) (16L) (12L) (8L) (10L) (11L) <br />
        ( STAINLESS STEEL BODY) <br /> <br />
        2) MODEL: ELECTRIC FRYER <br />
        DIMENSION: 370x440x340mm <br /> CAPACITY: 1 TANK 1 BASKET <br />
        SOURCE : ELECTRIC <br />
        CAPACITIES AVAILABLE: (18L) (13L) (16L) (12L) (8L) (10L) (11L) <br />(
        STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 134,
    name: 'STANDING FRYER',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p134.jpeg'),
    description: (
      <p>
        1) MODEL: GAS FRYER <br />
        DIMENSION: 590x500x960mm <br />
        CAPACITY: 2 TANK 2 BASKET <br />
        GAS TYPE: LPG <br />
        CAPACITIES AVAILABLE: (18L) (20L) (25L) (30L) <br />
        ( STAINLESS STEEL BODY) <br /> <br />
        2) MODEL: ELECTRIC FRYER <br />
        DIMENSION: 590x500x960mm <br />
        CAPACITY: 2 TANK 2 BASKET <br /> SOURCE : ELECTRIC <br />
        CAPACITIES AVAILABLE: (18L) (20L) (25L) (30L) <br />( STAINLESS STEEL
        BODY)
      </p>
    ),
  },
  {
    id: 135,
    name: 'STANDING PRESSURE FRYER',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p135.jpeg'),
    description: (
      <p>
        1) MODEL: GAS FRYER <br />
        DIMENSION: 460x960x1230mm <br />
        CAPACITY: 25L <br />
        GAS TYPE: LPG <br />
        VOLTAGE: 220V/50Hz <br />
        WORKING PRESSURE: 0.085mp <br /> ( STAINLESS STEEL BODY) <br /> <br />
        2) MODEL: ELECTRIC PRESSURE FRYER <br />
        DIMENSION: 460x960x1230mm <br />
        CAPACITY: 24L <br />
        SOURCE: ELECTRIC <br /> VOLTAGE: 380V/50Hz <br />
        WORKING PRESSURE: 0.085mpa <br />( STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 136,
    name: 'TABLE PRESSURE FRYER',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p136.jpeg'),
    description: (
      <p>
        1) MODEL: ELECTRIC PRESSURE FRYER <br />
        DIMENSION: 350x400x500mm <br />
        CAPACITY: 16L <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/50Hz <br />( STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 137,
    name: 'TABLE GRIDDLE ( SMOOTH)',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p137.jpeg'),
    description: (
      <p>
        1) MODEL: GAS GRIDDLE (SMOOTH) <br />
        DIMENSION: 600x612x530mm <br />
        GAS TYPE: LPG <br />
        ( STAINLESS STEEL BODY) <br /> <br />
        2) MODEL: ELECTRIC GRIDDLE ( SMOOTH) <br />
        DIMENSION: 600x600x350mm <br /> VOLTAGE: 220-240V/50Hz <br />
        POWER KW: 5kw <br /> ( STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 138,
    name: 'TABLE GRIDDLE WITH FRYER',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p138.jpeg'),
    description: (
      <p>
        1) MODEL: GAS GRIDDLE WITH FRYER <br />
        DIMENSION: 760x650x550mm <br /> GAS TYPE: LPG <br />
        ( STAINLESS STEEL BODY) <br /> <br />
        2) MODEL: ELECTRIC GRIDDLE WITH FRYER <br />
        DIMENSION: 760x650x550mm <br /> VOLTAGE: 220-240V/50Hz <br /> POWER KW:
        8kw <br /> ( STAINLESS STEEL BODY)
      </p>
    ),
  },
  {
    id: 139,
    name: 'FOOD DISPLAY WARMER',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p139.jpeg'),
    description: (
      <p>
        1) MODEL: HW-6P-B <br />
        DIMENSION: 1200x760x860mm <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        POWER: 3KW <br />
        N/W: 94Kw <br /> <br />
        2) MODEL: HW-6P <br />
        DIMENSION: 1500x760x860mm <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        POWER: 3.8Kw <br />
        N/W: 112Kg <br /> <br />
        3) MODEL: HW-6P-A <br /> DIMENSION: 1800x760x860mm <br /> VOLTAGE:
        220-240V/ 50Hz <br /> POWER: 4.2kw <br />
        N/W: 152kg <br /> <br /> 4) MODEL: HW-6P-C <br />
        DIMENSION: 2200x760x860mm <br />
        VOLTAGE: 220-240V/ 50Hz <br /> POWER: 5.2kw <br /> N/W: 180kh
      </p>
    ),
  },
  {
    id: 140,
    name: 'FOOD DISPLAY WARMER',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p140.jpeg'),
    description: (
      <p>
        1) MODEL: HW-2P <br />
        DIMENSION: 600x480x630mm <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        POWER: 0.8kw <br />
        N/W: 21kg <br /> <br />
        2) MODEL: HW-3P <br /> DIMENSION: 950x480x630mm <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        POWER: 1.2lw <br /> N/W: 30kg
      </p>
    ),
  },
  {
    id: 141,
    name: 'FOOD DISPLAY WARMER',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p141.jpeg'),
    description: (
      <p>
        1) MODEL: HW-60-1 <br />
        DIMENSION: 660x480x610mm <br /> VOLTAGE: 220-240V/ 50Hz <br />
        POWER: 1.84kw <br />
        N/W: 33kg <br />
        2) MODEL: HW-60-2 <br />
        DIMENSION: 900x480x610mm <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        POWER: 1.84kw <br />
        N/W: 50kg <br /> <br />
        3) MODEL: HW-60-3 <br />
        DIMENSION: 1200x480x610mm <br /> VOLTAGE: 220-240V/ 50Hz <br /> POWER:
        2.2kw <br />
        N/W: 72kg
      </p>
    ),
  },
  {
    id: 142,
    name: 'FOOD WARMER CART',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p142.jpeg'),
    description: (
      <p>
        1) MODEL: HW-11-21/ 22LAYERS <br /> DIMENSION: 1540x975x1795mm <br />
        INNER SIZE: 1350x680x1380mm <br /> VOLTAGE: 220-240V/ 50Hz <br />
        POWER: 2.62Kw <br />
        LAYER DISTANCE: 115mm <br />
        LAYER NUMBER: 22-Layers <br />
        N/W: 33kg
      </p>
    ),
  },
  {
    id: 143,
    name: 'FOOD WARMER CART',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p143.jpeg'),
    description: (
      <p>
        CATEGORIES <br /> <br />
        1) MODEL: HW-11-21 <br /> DIMENSION: 790x970x1750mm <br />
        INNER SIZE: 540x680x1380mm <br />
        VOLTAGE: 220-240V/ 50Hz <br /> POWER: 2.62Kw <br />
        LAYER DISTANCE: 115mm <br /> LAYER NUMBER: 11-Layers
      </p>
    ),
  },
  {
    id: 144,
    name: 'FOOD DISPLAY WARMER ( CURVED GLASS)',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p144.jpeg'),
    description: (
      <p>
        1) MODEL: HW-838-3 <br />
        DIMENSION: 1140x690x710mm <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        POWER: 1.2Kw
      </p>
    ),
  },
  {
    id: 145,
    name: 'POPCORN MACHINE ',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p145.jpeg'),
    description: (
      <p>
        1) MODEL: HP-6C / 8Oz <br />
        DIMENSION: 560x420x730mm <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        POWER: 1.44Kw <br /> <br />
        2) MODEL: HP-12A / 12Oz <br />
        DIMENSION: 500x410x790mm <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        POWER: 1.84Kw <br /> <br />
        3) MODEL: HP-16A/ 16Oz <br />
        DIMENSION: 750x550x1100mm <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        POWER: 2.34Kw
      </p>
    ),
  },
  {
    id: 146,
    name: 'POPCORN CART ',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p146.jpeg'),
    description: (
      <p>
        1) MODEL: HP-CC/ 8Oz <br /> DIMENSION: 450x350x800mm
      </p>
    ),
  },
  {
    id: 147,
    name: 'POPCORN MACHINE/CART',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p147.jpeg'),
    description: (
      <p>
        1) MODEL: HW-P8 / 8Oz <br />
        DIMENSION: 940x450x1540mm <br />
        VOLTAGE: 220-240V/ 50Hz <br /> POWER: 1.44Kw
      </p>
    ),
  },
  {
    id: 148,
    name: 'CANDY FLOSS MACHINE ',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p148.jpeg'),
    description: (
      <p>
        1) MODEL: HEC-03 <br />
        DIMENSION: 460x460x500mm <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        POWER: 0.93Kw <br /> <br />
        2) MODEL: HEC-04 <br /> DIMENSION: 730x730x500mm <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        POWER: 0.93Kw
      </p>
    ),
  },
  {
    id: 149,
    name: 'CANDY FLOSS MACHINE WITH COVER',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p149.jpeg'),
    description: (
      <p>
        1) MODEL: HEC-03 <br />
        DIMENSION: 460x460x500mm <br />
        VOLTAGE: 220-240V/ 50Hz POWER: 0.93Kw <br /> <br />
        2) MODEL: HEC-04/ WITH COVER <br />
        DIMENSION: 730x730x500mm <br />
        VOLTAGE: 220-240V/ 50Hz <br /> POWER: 0.93Kw
      </p>
    ),
  },
  {
    id: 150,
    name: 'BAIN MARIE ( STRAIGHT GLASS)',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p150.jpeg'),
    description: (
      <p>
        1) MODEL: 3PLATE <br />
        DIMENSION: 1128x690x770mm <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        POWER: Kw <br /> <br />
        2) MODEL: 4PLATE <br /> DIMENSION: 1455x690x770mm <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        POWER: 3KW <br /> <br />
        3) MODEL: 5PLATE <br />
        DIMENSION: 1790x690x770mm <br />
        VOLTAGE: 220-240V/ 50Hz <br /> POWER: 3KW <br /> <br /> 4) MODEL: 6PLATE{' '}
        <br />
        DIMENSION: 2125x690x770mm <br />
        VOLTAGE: 220-240V/ 50Hz <br /> POWER: 3KW
      </p>
    ),
  },
  {
    id: 151,
    name: 'STANDING BAIN MARIE ( STRAIGHT GLASS)',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p151.jpeg'),
    description: (
      <p>
        1) MODEL: 4PLATE <br />
        DIMENSION: 1455x670x1400mm <br />
        VOLTAGE:220-240V/ 50Hz <br />
        POWER: 5KW <br /> <br />
        2) MODEL: 5PLATE <br />
        DIMENSION: 1790x670x1400mm <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        POWER: 5KW <br /> <br />
        3) MODEL: 6PLATE <br />
        DIMENSION: 2125X670X1400mm <br />
        VOLTAGE: 220-240V/ 50Hz <br /> POWER: 5KW
      </p>
    ),
  },
  {
    id: 152,
    name: 'BAIN MARIE ( CURVED GLASS )',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p152.jpeg'),
    description: (
      <p>
        1) MODEL: 3PLATE <br />
        DIMENSION: 1128x690x770mm <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        POWER: Kw <br /> <br />
        2) MODEL: 4PLATE <br />
        DIMENSION: 1455x690x770mm <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        POWER: 3KW <br /> <br />
        3) MODEL: 5PLATE <br />
        DIMENSION: 1790x690x770mm <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        POWER: 3KW <br /> <br />
        4) MODEL: 6PLATE <br />
        DIMENSION: 2125x690x770mm <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        POWER: 3KW
      </p>
    ),
  },
  {
    id: 153,
    name: 'CHICKEN ROTISSERIE ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p153.jpeg'),
    description: (
      <p>
        1) MODEL: HGJ-3P <br />
        DIMENSION: 1180x490x830mm <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        POWER: 10KW/Hour + 0.2Kw <br /> <br />
        2) MODEL: HGJ-6P/ 6-Rod <br />
        DIMENSION: 1180x490x1380mm <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        POWER: 20Kw/Hour + 0.2Kw <br /> <br />
        3) MODEL: HGJ-9P/ 9-Rod <br />
        DIMENSION: 1180x490x1880mm <br /> VOLTAGE: 220-240V/ 50Hz <br />
        POWER: 30Kw/Hour + 0.2Kw
      </p>
    ),
  },
  {
    id: 154,
    name: 'ELECTRIC CHICKEN ROTISSERIE ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p154.jpeg'),
    description: (
      <p>
        1) MODEL: HEJ-3P/ 3-Rod <br />
        DIMENSION: 1180x490x830mm <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        POWER: 6Kw <br /> <br />
        2) MODEL: HEJ-6P/ 6-Rod <br />
        DIMENSION:1180x490x1380mm <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        POWER: 12.3Kw <br /> <br />
        3) MODEL: HEJ-9P/ 9-Rod <br />
        DIMENSION: 1180x490x1880mm <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        POWER: 18.4KW
      </p>
    ),
  },
  {
    id: 155,
    name: 'ELECTRIC CHICKEN ROTISSERIE ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p155.jpeg'),
    description: (
      <p>
        1) MODEL: HEJ-266 <br />
        DIMENSION: 810x630x610mm <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        POWER: 4.5KW <br />
        4 Basket for 8-12 chickens <br /> <br />
        2) MODEL: HEJ-268 <br />
        DIMENSION: 1010x630x880mm <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        POWER: 6Kw <br />4 Basket for 12-16 chickens
      </p>
    ),
  },
  {
    id: 156,
    name: 'GAS CHICKEN ROTISSERIE ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p156.jpeg'),
    description: (
      <p>
        1) MODEL: HGJ-366 <br />
        DIMENSION: 810x630x610mm <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        SOURCE: GAS LPG <br />
        4 Basket for 8-12 chickens <br /> <br />
        2) MODEL: HGJ-368 <br />
        DIMENSION: 1010x630x880mm <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        SOURCE: GAS LPG <br />4 Basket for 12-16 chickens
      </p>
    ),
  },
  {
    id: 157,
    name: ' PIZZA OVEN 2 DECK',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/157.jpeg'),
    description: (
      <p>
        1) MODEL: HGP-2-4/ 2 DECK <br />
        DIMENSION: 890x790x750mm <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        SOURCE: GAS LPG <br /> <br />
        2) MODEL: HEP-2-4 / 2 Deck <br />
        DIMENSION: 890x790x750mm <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        SOURCE: ELECTRIC <br /> POWER: 8.4KW
      </p>
    ),
  },
  {
    id: 158,
    name: 'PIZZA OVEN 1 DECK',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/158.jpeg'),
    description: (
      <p>
        1) MODEL: HGP-1-2/ 1 DECK <br />
        DIMENSION: 890x790x430mm <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        SOURCE: GAS LPG <br /> <br />
        2) MODEL: HEP-1-2/ 1 Deck <br />
        DIMENSION: 890x790x430mm <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        SOURCE: ELECTRIC <br /> POWER: 4.2Kw
      </p>
    ),
  },
  {
    id: 159,
    name: 'PIZZA CONVEYOR OVEN',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/159.jpeg'),
    description: (
      <p>
        1) MODEL: HEP-12 <br />
        DIMENSION: 1100x570x360mm <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        SOURCE: ELECTRIC <br />
        POWER: 6.7Kw <br />
        WORK STATION DIMENSION: 350x1000mm <br /> <br />
        2) MODEL: HEP-18 <br />
        DIMENSION: 1560x750x360mm <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        SOURCE: ELECTRIC <br />
        POWER: 10.3Kw <br />
        WORK STATION DIMENSION: 350x1000mm <br /> <br />
        3) MODEL: HEP-18 <br />
        DIMENSION: 1560x750x360mm <br /> VOLTAGE: 220-240V/ 50Hz <br />
        SOURCE: Gas <br />
        WORK STATION DIMENSION: 500x1450mm <br /> <br />
        4) MODEL: HGP-12 <br />
        DIMENSION: 1100x570x430mm <br /> VOLTAGE: 220-240V/ 50Hz <br />
        SOURCE: Gas <br />
        WORK STATION DIMENSION: 350x1000mm
      </p>
    ),
  },
  {
    id: 160,
    name: ' ELECTRIC CONVENTION OVEN',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/160.jpeg'),
    description: (
      <p>
        1) MODEL: HEB-8F/ 4TRAYS <br />
        DIMENSION: 835x770x580mm <br /> VOLTAGE: 220-240V/ 50Hz <br /> SOURCE:
        ELECTRIC <br />
        POWER: 6.4KW <br /> <br />
        2) MODEL: HEO-6M-B/ 4TRAYS <br />
        DIMENSION: 680x680x600m <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        SOURCE: ELECTRIC <br />
        POWER: 3kw
      </p>
    ),
  },
  {
    id: 161,
    name: 'ELECTRIC CONVENTION OVEN',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p161.jpeg'),
    description: (
      <p>
        1) MODEL: HEA-5/ 5TRAYS <br />
        DIMENSION: 930x890x830mm <br />
        VOLTAGE: 380V/50Hz <br /> SOURCE: ELECTRIC <br />
        POWER: 7.4KW <br /> <br />
        2) MODEL: HEA-8/ 8TRAYS <br />
        DIMENSION: 930x890x1100mm <br /> VOLTAGE: 380V/50Hz <br />
        SOURCE: ELECTRIC <br /> POWER: 9.4kw <br /> <br />
        3) MODEL: HEA-12/ 12TRAYS <br />
        DIMENSION: 930x890x1550mm <br />
        VOLTAGE: 380V/50Hz <br /> SOURCE: ELECTRIC <br />
        POWER: 12.5KW
      </p>
    ),
  },
  {
    id: 162,
    name: 'ELECTRIC CONVENTION OVEN ( TROLLEY TYPE )',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p162.jpeg'),
    description: (
      <p>
        1) MODEL: HEA-16/ 16trays <br /> DIMENSION: 930x890x1930mm <br />{' '}
        VOLTAGE: 380V/50Hz <br /> SOURCE: ELECTRIC <br /> POWER: 12.5Kw
      </p>
    ),
  },
  {
    id: 163,
    name: 'GAS CONVENTION OVEN',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p163.jpeg'),
    description: (
      <p>
        1) MODEL: HGA-5/ 5TRAYS <br />
        DIMENSION: 930x900x830 + 130mm <br />
        VOLTAGE: 220v/50Hz <br /> SOURCE: GAS <br />
        POWER: 0.4Kw <br />
        Heat Flux: 10kw/ h <br /> <br />
        2) MODEL: HGA-8/ 8TRAYS <br />
        DIMENSION: 930x900x1100 + 130mm <br /> VOLTAGE: 220v/50Hz <br />
        SOURCE: GAS <br />
        POWER: 0.4Kw <br />
        Heat Flux: 12Kw/h <br /> <br />
        3) MODEL: HGA-12/ 12TRAYS <br />
        DIMENSION:930x900x1550 + 130mm <br /> VOLTAGE: 220v/50Hz <br />
        SOURCE: GAS <br /> POWER: 0.8Kw <br /> Heat Flux: 16Kw/h
      </p>
    ),
  },
  {
    id: 164,
    name: 'GAS CONVENTION OVEN ( TROLLEY TYPE )',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p164.jpeg'),
    description: (
      <p>
        1) MODEL: HGA-16/ 16TRAYS <br />
        DIMENSION: 930x900x1930+130mm <br />
        VOLTAGE: 220V/50Hz <br />
        SOURCE: GAS <br />
        POWER: 1.2Kw <br />
        HEAT FLUX: 20kw/ h
      </p>
    ),
  },
  {
    id: 165,
    name: 'COMBI STEAM OVEN ',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p165.jpeg'),
    description: (
      <p>
        1) MODEL: 6 TRAYS <br />
        DIMENSION: 910x820x900mm <br />
        VOLTAGE: 380V/50Hz <br />
        SOURCE: Electric <br />
        POWER: 12.5KW <br /> <br />
        2) MODEL: 10 TRAYS <br />
        DIMENSION:910x820x1080mm <br />
        VOLTAGE: 380V/ 50Hz <br />
        SOURCE: Electric <br />
        POWER: 18.5Kw
      </p>
    ),
  },
  {
    id: 166,
    name: 'LIFT UP AUTO ELECTRIC SALAMANDER',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p166.jpeg'),
    description: (
      <p>
        1) MODEL: HES-844 <br />
        DIMENSION: 600x520x540mm <br />
        VOLTAGE: 220V/50Hz <br />
        SOURCE: Electric <br />
        POWER: 3kw <br /> <br />
        2) MODEL: HES-842 <br />
        DIMENSION:450x520x540mm <br />
        VOLTAGE: 220V/ 50Hz <br />
        SOURCE: Electric <br />
        POWER: 2.8Kw <br /> <br />
        3)MODEL: HES-843 <br />
        DIMENSION: 600x450x520mm <br />
        VOLTAGE: 220V/ 50Hz <br />
        SOURCE:Electric <br />
        POWER: 3Kw
      </p>
    ),
  },
  {
    id: 167,
    name: 'GAS SALAMANDER',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p167.jpeg'),
    description: (
      <p>
        1) MODEL: HGS-745 <br />
        DIMENSION: 880x500x610mm <br />
        VOLTAGE: 220V/50Hz <br />
        SOURCE: GAS <br />
        HEAT FLUX: 9kw/h <br /> <br />
        2) MODEL: HGS-747 <br />
        DIMENSION: 880x440x605mm <br />
        VOLTAGE: 220V/ 50Hz <br />
        SOURCE: GAS <br /> HEAT FLUX: 12Kw/ h
      </p>
    ),
  },

  {
    id: 168,
    name: 'ELECTRIC CONVENTION OVEN',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p168.jpeg'),
    description: (
      <p>
        1) MODEL: HEA-5/ 5TRAYS <br />
        DIMENSION: 930x890x830mm <br />
        VOLTAGE: 380V/50Hz <br />
        SOURCE: ELECTRIC <br />
        POWER: 7.4KW <br /> <br />
        2) MODEL: HEA-8/ 8TRAYS <br />
        DIMENSION: 930x890x1100mm <br />
        VOLTAGE:380V/50Hz <br />
        SOURCE: ELECTRIC <br />
        POWER: 9.4kw <br /> <br />
        3) MODEL: HEA-12/ 12TRAYS <br />
        DIMENSION: 930x890x1550mm <br />
        VOLTAGE: 380V/50Hz <br />
        SOURCE: ELECTRIC <br />
        POWER:12.5KW
      </p>
    ),
  },
  {
    id: 169,
    name: 'ELECTRIC CONVENTION OVEN ( TROLLEY TYPE )',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p169.jpeg'),
    description: (
      <p>
        1) MODEL: HEA-16/ 16trays <br />
        DIMENSION: 930x890x1930mm <br />
        VOLTAGE:380V/50Hz <br />
        SOURCE: ELECTRIC <br />
        POWER: 12.5Kw
      </p>
    ),
  },
  {
    id: 170,
    name: 'GAS CONVENTION OVEN',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p170.jpeg'),
    description: (
      <p>
        1) MODEL: HGA-5/ 5TRAYS <br />
        DIMENSION: 930x900x830 + 130mm <br />
        VOLTAGE: 220v/50Hz <br />
        SOURCE: GAS <br />
        POWER: 0.4Kw <br />
        Heat Flux: 10kw/ h <br /> <br />
        2) MODEL:HGA-8/ 8TRAYS <br />
        DIMENSION: 930x900x1100 + 130mm <br />
        VOLTAGE: 220v/50Hz <br />
        SOURCE: GAS <br />
        POWER: 0.4Kw <br />
        Heat Flux: 12Kw/h <br /> <br />
        3) MODEL: HGA-12/ 12TRAYS <br />
        DIMENSION:930x900x1550 + 130mm <br />
        VOLTAGE: 220v/50Hz <br />
        SOURCE: GAS <br />
        POWER: 0.8Kw <br />
        HeatFlux: 16Kw/h
      </p>
    ),
  },
  {
    id: 171,
    name: 'ELECTRIC SALAMANDER ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p171.jpeg'),
    description: (
      <p>
        1) MODEL: HES-937 <br />
        DIMENSION: 800x450x460mm <br />
        VOLTAGE: 220V/50Hz <br />
        SOURCE: ELECTRIC <br />
        POWER: 4KW <br /> <br />
        2) MODEL: HES-983 <br />
        DIMENSION: 570x390x370mm <br />
        VOLTAGE: 220V/ 50Hz <br />
        SOURCE: ELECTRIC <br />
        POWER: 2.2Kw
      </p>
    ),
  },
  {
    id: 172,
    name: 'SINGLE BURNER STOCK POT COOKER ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p172.jpeg'),
    description: (
      <p>
        1) MODEL: HGS-1 <br />
        DIMENSION: 445x570x590mm <br />
        SOURCE: GAS <br />
        TYPE OF GAS: LPG
      </p>
    ),
  },
  {
    id: 173,
    name: 'SINGLE BURNER STOCK POT COOKER',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p173.jpeg'),
    description: (
      <p>
        1) MODEL: HGS-11 <br />
        DIMENSION: 600x600x530mm <br />
        SOURCE: GAS <br />
        TYPE OF GAS: LPG
      </p>
    ),
  },
  {
    id: 174,
    name: 'DOUBLE BURNER STOCK POT COOKER',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p174.jpeg'),
    description: (
      <p>
        1) MODEL: HGS-12 <br />
        DIMENSION: 1200x600x530mm <br />
        SOURCE: GAS <br />
        TYPE OF GAS: LPG
      </p>
    ),
  },
  {
    id: 175,
    name: 'DOUBLE BURNER STOCK POT COOKER',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p175.jpeg'),
    description: (
      <p>
        1) MODEL: HGR-2 <br />
        DIMENSION: 460x1060x500m <br />
        SOURCE: GAS <br />
        TYPE OF GAS: LPG
      </p>
    ),
  },
  {
    id: 176,
    name: 'HOMEPOINT HOT DOG GRILL',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p176.jpeg'),
    description: (
      <p>
        1) MODEL: ELECTRIC HOT DOG GRILL <br />
        SOURCE: ELECTRIC AVAILABLE <br />
        SIZES: ( 5 Roller) ( 7 Roller) ( 9 Roller ) ( 11 Roller ) <br />
        COVER: OPTIONAL
      </p>
    ),
  },
  {
    id: 177,
    name: 'HOT DOG GRILL ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p177.jpeg'),
    description: (
      <p>
        1) MODEL: ELECTRIC HOT DOG GRILL <br />
        SOURCE: ELECTRIC AVAILABLE <br />
        SIZES: ( 5 Roller) ( 7 Roller) ( 9 Roller ) ( 11 Roller ) <br />
        COVER: OPTIONAL
      </p>
    ),
  },
  {
    id: 178,
    name: 'ELECTRIC CONVEYOR TOASTER',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p178.jpeg'),
    description: (
      <p>
        1) MODEL: HET-150 <br />
        DIMENSION: 500x320x200mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 1.3Kw <br /> <br />
        2) MODEL: HET-300 <br />
        DIMENSION: 510x440x380mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 2.2Kw <br /> <br />
        3) MODEL: HET-450 <br />
        DIMENSION: 560x540x200mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 2.6Kw
      </p>
    ),
  },
  {
    id: 179,
    name: 'DOUBLE HEAD CREPE MAKER',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p179.jpeg'),
    description: (
      <p>
        1) MODEL: HCM-2 <br />
        DIMENSION: 860x470x230mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 6kw <br /> <br />
        2) MODEL: HCM-2G <br />
        DIMENSION: 860x470x230mm <br />
        SOURCE: LPG <br />
        VOLTAGE: 220V/ 50Hz <br />
        HEAT FLUX: 8kw/H
      </p>
    ),
  },
  {
    id: 180,
    name: 'SONGLE  HEAD CREPE MAKER',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p180.jpeg'),
    description: (
      <p>
        1) MODEL: HCM-1 <br />
        DIMENSION: 450x470x230mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 3Kw <br /> <br />
        2) MODEL: HCM-1G <br />
        DIMENSION: 450x470x230mm <br />
        SOURCE: GAS LPG <br />
        HEAT FLUX: 4Kw/ H
      </p>
    ),
  },
  {
    id: 181,
    name: 'DOUBLE PLATE WAFFLE BAKER',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p181.jpeg'),
    description: (
      <p>
        1) MODEL: HWB-2 <br />
        DIMENSION: 500x350x260mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 2Kw <br />
        POWER: 3Kw
      </p>
    ),
  },
  {
    id: 182,
    name: 'SINGLE PLATE WAFFLE BAKER',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p182.jpeg'),
    description: (
      <p>
        1) MODEL: HWB-1 <br />
        DIMENSION: 250x350x260mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 1Kw
      </p>
    ),
  },
  {
    id: 183,
    name: 'DOUBLE PLATE CONE  BAKER',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p183.jpeg'),
    description: (
      <p>
        1) MODEL: HCB-2 <br />
        DIMENSION: 570x380x230mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 2Kw
      </p>
    ),
  },
  {
    id: 184,
    name: 'SINGLE PLATE CONE BAKER',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p184.jpeg'),
    description: (
      <p>
        1) MODEL: HCB-1 <br />
        DIMENSION: 282x380x230mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 1Kw
      </p>
    ),
  },
  {
    id: 185,
    name: 'DOUBLE CONTACT SHAWARMA TOASTER',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p185.jpeg'),
    description: (
      <p>
        1) MODEL: HEG-813 <br />
        DIMENSION: 845x400x210mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 4+4Kw
      </p>
    ),
  },
  {
    id: 186,
    name: 'SINGLE CONTACT SHAWARMA TOASTER',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p186.jpeg'),
    description: (
      <p>
        1) MODEL: HEG-811 <br />
        DIMENSION: 305x400x210mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 1.8Kw
      </p>
    ),
  },
  {
    id: 187,
    name: 'COUNTER TOP FRENCH FRIES DISPLAY WARMER ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p187.jpeg'),
    description: (
      <p>
        1) MODEL: HCW-8 <br />
        DIMENSION: 800x700x650mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 1.6kw <br /> <br />
        2) MODEL: HCW-819 <br />
        DIMENSION: 335x575x465mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 0.75Kw
      </p>
    ),
  },
  {
    id: 188,
    name: 'STANDING FRENCH FRIES DISPLAY WARMER ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p188.jpeg'),
    description: (
      <p>
        1) MODEL: HCW-833 <br />
        DIMENSION: 1000x700x1580mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER:0.75kw
      </p>
    ),
  },
  {
    id: 189,
    name: 'HAMBURGER PATTY PRESS',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p189.jpeg'),
    description: (
      <p>
        1) MODEL: WF-A100/A130 <br />
        DIMENSION: 275x230x300mm <br />
        SOURCE:MANUAL
      </p>
    ),
  },
  {
    id: 190,
    name: 'FOOD WRAPPING MACHINE ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p190.jpeg'),
    description: (
      <p>
        1) MODEL: HWM-430 <br />
        DIMENSION: 430x660x155mm <br />
        SOURCE: ELECTRIC <br />
        POWER: 0.75Kw
      </p>
    ),
  },
  {
    id: 191,
    name: 'POTATO CHIPS CHIPPER',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p191.jpeg'),
    description: (
      <p>
        1) MODEL: HC-02 <br />
        DIMENSION: 430x220x330mm <br />
        SOURCE: MANUAL
      </p>
    ),
  },
  {
    id: 192,
    name: 'POTATO CHIPS CHIPPER',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p192.jpeg'),
    description: (
      <p>
        1) MODEL: HC-01 <br />
        DIMENSION: 330x140x641mm <br />
        SOURCE: MANUAL
      </p>
    ),
  },
  {
    id: 193,
    name: 'MEAT SALTING MACHINE ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p193.jpeg'),
    description: (
      <p>
        1) MODEL: HML: 809 <br />
        DIMENSION: 953x660x914mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 0.75Kw
      </p>
    ),
  },
  {
    id: 194,
    name: '2-HEAD ELECTRIC PLATE WARMER CART',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p194.jpeg'),
    description: (
      <p>
        1) MODEL: HPW-2 <br />
        DIMENSION: 450x950x900mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 0.8Kw
      </p>
    ),
  },
  {
    id: 195,
    name: '1-HEAD ELECTRIC PLATE WARMER CART',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p195.jpeg'),
    description: (
      <p>
        1) MODEL: HPW-2 <br />
        DIMENSION: 450x950x900mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 0.8Kw
      </p>
    ),
  },
  {
    id: 196,
    name: 'DISH WASHING MACHINE ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p196.jpeg'),
    description: (
      <p>
        1) MODEL: HX-C888 <br />
        DIMENSION: 687x750x1470mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 380V/ 50Hz <br />
        POWER SUPPLY: 14Kw <br />
        HIGH TEMPERATURE TANK POWER: 15Kw <br />
        AVERAGE HOURLY CAPACITY ( L ) : 60 BASKET / HOUR
      </p>
    ),
  },
  {
    id: 197,
    name: 'FOOD / PLANETARY MIXER',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p197.jpeg'),
    description: (
      <p>
        1) MODEL: B7 <br />
        DIMENSION: 380x240x405mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        CAPACITY: 7LITERS <br /> DOUGH MIXING QUANTITY: 500G <br />
        EGG MIXING QUANTITY: 500H <br />
        POWER: 0.5KW
      </p>
    ),
  },
  {
    id: 198,
    name: 'FOOD / PLANETARY MIXER',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p198.jpeg'),
    description: (
      <p>
        1) MODEL: B10, B15, B20, B25, B30 <br />
        DIMENSION: 380x240x405mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        CAPACITIES AVAILABLE: 10L, 15L, 20L, 25L, and 30L <br />
        POWER: 0.5KW
      </p>
    ),
  },
  {
    id: 199,
    name: 'FOOD / PLANETARY MIXER',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p199.jpeg'),
    description: (
      <p>
        1) MODEL: B40, B50, B60 <br />
        DIMENSION: 620x640x1250mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 380v/ 50Hz <br />
        CAPACITIES AVAILABLE: 40L, 50L and 60L <br />
        POWER: 1.5Kw
      </p>
    ),
  },
  {
    id: 200,
    name: 'FOOD / PLANETARY MIXER',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p200.jpeg'),
    description: (
      <p>
        1) MODEL: B12, B17, B25, B35, B45 <br />
        DIMENSION: :::: :::: <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        CAPACITIES AVAILABLE: 12L, 17L, 25L, 35L and 45L <br />
        POWER: 0.5KW
      </p>
    ),
  },
  {
    id: 201,
    name: 'SPIRAL DOUGH MIXER',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p201.jpeg'),
    description: (
      <p>
        1) MODEL: YSL-50 <br />
        DIMENSION: 1220x810x1440mm <br />
        SOURCE: ELECTRIC <br />
        BOWL CAPACITY: 130L <br />
        FLOUR CAPACITY: 50Kg <br />
        POWER: 380/50Hz/ 3phase <br /> <br />
        2) MODEL: YSL-100 <br />
        DIMENSION: 1400x1010x1550mm <br />
        SOURCE: ELECTRIC <br />
        BOWL CAPACITY: 260LITERS <br />
        FLOUR CAPACITY: 100Kg <br />
        POWER: 380/50Hz/ 3phase
      </p>
    ),
  },

  {
    id: 202,
    name: 'MEAT MINCER',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p202.jpeg'),
    description: (
      <p>
        1) MODEL: TC-12 <br />
        DIMENSION: 400x190x410mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 0.55Kw <br />
        CAPACITY: 120kg/ h <br /> <br />
        2) MODEL: TC-22 <br />
        DIMENSION: 410x240x450mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 0.75kw <br />
        CAPACITY: 220kg/h <br /> <br />
        3) MODEL: TC-32 <br />
        DIMENSION: 530x270x500mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 1.5KW <br />
        CAPACITY: 320kg/h
      </p>
    ),
  },
  {
    id: 203,
    name: 'MEAT MINCER',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p203.jpeg'),
    description: (
      <p>
        1) MODEL: TC-42 <br />
        DIMENSION: 530x1020x950mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 4Kw <br />
        CAPACITY: 650Kg/ h <br /> <br />
        2) MODEL: TC-52 <br />
        DIMENSION: 800x1350x1220mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 380V/ 50Hz <br />
        POWER: 5.5Kw <br />
        CAPACITY: 1300Kg/ h
      </p>
    ),
  },
  {
    id: 204,
    name: 'MEAT SLICER ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p204.jpeg'),
    description: (
      <p>
        1) MODEL: 220ES-8 <br />
        DIMENSION: 440x380x340mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 0.32Kw <br /> <br />
        2) MODEL: 250ES-10 <br />
        DIMENSION: 490x390x380mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 0.32Kw <br /> <br />
        3) MODEL: 300ES-12 <br />
        DIMENSION: 530x460x460mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 0.42Kw
      </p>
    ),
  },
  {
    id: 205,
    name: 'FRUITS AND VEGETABLE CUTTER',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p205.jpeg'),
    description: (
      <p>
        1) MODEL: VC-55MF <br />
        DIMENSION: 580x300x545mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 3/4HP/0.55Kw
      </p>
    ),
  },
  {
    id: 206,
    name: 'DOUGH SHEETER ',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p206.jpeg'),
    description: (
      <p>
        1) MODEL: LSP520 <br />
        DIMENSION: 2550x880x1180mm <br />
        CONVEYOR BELT SIZE: 500x2000mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 0.55Kw
      </p>
    ),
  },
  {
    id: 207,
    name: 'TABLE DOUGH SHEETER ',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p207.jpeg'),
    description: (
      <p>
        1) MODEL: TSP520 <br />
        DIMENSION: 2080x880x620mm <br />
        CONVEYOR BELT SIZE: 500x2000mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 0.55Kw
      </p>
    ),
  },
  {
    id: 208,
    name: 'BREAD SLICER ',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p208.jpeg'),
    description: (
      <p>
        1) MODEL: TR-M-31 <br />
        DIMENSION: 690x800x850mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 0,25Kw
      </p>
    ),
  },
  {
    id: 209,
    name: ' PIZZA DOUGH SHEETER',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p209.jpeg'),
    description: (
      <p>
        1) MODEL: APD40 <br />
        DIMENSION: 530x530x650mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER:0.37kw <br />
        DOUGH WEIGHT: 50 - 500Kg <br /> <br />
        2) MODEL:APD30 <br />
        DIMENSION: 530x480x560 <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER:0.37kw <br />
        DOUGH WEIGHT: 50 - 500Kg <br />
        PIZZA DIAMETER: 40k
      </p>
    ),
  },
  {
    id: 210,
    name: 'DOUGH MOULDER MACHINE',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p210.jpeg'),
    description: (
      <p>
        1) MODEL: CG-380 <br />
        DIMENSION: 1300x500x1050mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER:0.75Kw
      </p>
    ),
  },
  {
    id: 211,
    name: 'SEMI AUTOMATIC ROUNDER AND DIVIDER',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p211.jpeg'),
    description: (
      <p>
        1) MODEL: FK-30 <br />
        DIMENSION: 650x540x2100mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 380V/50Hz POWER: 1Kw
      </p>
    ),
  },
  {
    id: 212,
    name: ' FULL AUTOMATIC ROUNDER AND DIVIDER',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p212.jpeg'),
    description: (
      <p>
        1) MODEL: FK-30A <br />
        DIMENSION: 650x730x1440mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 380V/50Hz <br />
        POWER: 2Kw
      </p>
    ),
  },
  {
    id: 213,
    name: 'TABLE TOP BONE SAW MACHINE ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p213.jpeg'),
    description: (
      <p>
        1) MODEL: J-210 <br />
        DIMENSION: 525x470x850mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/50Hz <br />
        POWER: 0.65Kw ,
      </p>
    ),
  },
  {
    id: 214,
    name: 'STANDING BONE SAW MACHINE ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p214.jpeg'),
    description: (
      <p>
        1) MODEL: W-360CA <br />
        DIMENSION: 905x780x1860mm <br /> SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/50Hz <br />
        POWER: 1.5KW
      </p>
    ),
  },
  {
    id: 215,
    name: '  AUTOMATIC DOUGH DIVIDER ',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p215.jpeg'),
    description: (
      <p>
        1) MODEL: DD36 <br />
        DIMENSION: 520x420x1230mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/50Hz <br />
        POWER: 0.75Kw
      </p>
    ),
  },
  {
    id: 216,
    name: 'HEAVY DUTY HYDRAULIC DOUGH DIVIDER ',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p216.jpeg'),
    description: (
      <p>
        1) MODEL: RMK-20 <br />
        DIMENSION: 655x785x1085mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 380V/50Hz <br />
        POWER: 1.5KW <br /> <br />
        2) MODEL: RMK-16 <br />
        DIMENSION: 655x785x1085mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 380V/50Hz <br />
        POWER: 1.5KW
      </p>
    ),
  },
  {
    id: 217,
    name: 'POTATO PEELER MACHINE ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p217.jpeg'),
    description: (
      <p>
        1) MODEL: PP-10 <br />
        DIMENSION: 470x470x785mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 0.55Kw <br />
        CAPACITY: 10Kg <br /> <br />
        2) MODEL: PP-15 <br />
        DIMENSION: 520x520x850mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 0.75Kw <br />
        CAPACITY: 15Kg <br /> <br />
        3) MODEL: PP-30 <br />
        DIMENSION: 600x580x1020mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 1.5KW <br />
        CAPACITY: 30Kg
      </p>
    ),
  },
  {
    id: 218,
    name: 'POTATO PEELER MACHINE  WITH UPPER COVER AND DISCHARGE DOOR',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p218.jpeg'),
    description: (
      <p>
        1) MODEL: X-10D <br />
        DIMENSION: 470x470x785mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 0.55Kw <br />
        CAPACITY: 10Kg <br /> <br />
        2) MODEL: X-15 <br />
        DIMENSION: 520x520x850mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 0.75Kw <br />
        CAPACITY: 15Kg <br /> <br />
        3) MODEL: X-30 <br />
        DIMENSION: 600x580x1020mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 1.5KW <br />
        CAPACITY: 30Kg
      </p>
    ),
  },

  {
    id: 219,
    name: 'ICE CREAM DISPLAY FREEZER',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p219.jpeg'),
    description: (
      <p>
        1) MODEL: BQG-1200 <br />
        DIMENSION: 1200x1100x1300mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 1.48Kw <br />
        CAPACITY: 12 PLATE <br /> <br />
        2) MODEL: BQG-1500 <br />
        DIMENSION: 1500x1100x1300mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 1.56Kw <br />
        CAPACITY: 16 Plate <br /> <br />
        3) MODEL: BQG-1800mm <br /> DIMENSION: 1800x1100x1300mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 2.60Kw <br />
        CAPACITY: 20 Plate
      </p>
    ),
  },
  {
    id: 220,
    name: 'ROTATING CAKE DISPLAY',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p220.jpeg'),
    description: (
      <p>
        1) MODEL: HCL-400L <br />
        DIMENSION: 640x640x1890 <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 0.53Kw <br />
        CAPACITY: 4 STEPS
      </p>
    ),
  },
  {
    id: 221,
    name: 'STANDING CURVED GLASS CAKE DISPLAY',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p221.jpeg'),
    description: (
      <p>
        1) MODEL: WD-4R <br />
        DIMENSION: 1200x650x1200mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 0.53Kw <br />
        CAPACITY: 4 STEPS <br /> <br />
        2) MODEL: WD-5R <br />
        DIMENSION: 1500x650x1200mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br /> POWER: 0.53Kw <br />
        CAPACITY: 4 STEPS <br /> <br />
        3) MODEL: WD-6R <br />
        DIMENSION: 1800c650x1200mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 0.53Kw <br />
        CAPACITY: 4 STEPS
      </p>
    ),
  },

  {
    id: 222,
    name: 'STANDING  SQUARE GLASS CAKE DISPLAY',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p222.jpeg'),
    description: (
      <p>
        1) MODEL: WDS-4R <br />
        DIMENSION: 1200x650x1200mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 0.53Kw <br />
        CAPACITY: 4 STEPS <br /> <br />
        2) MODEL: WDS-5R <br />
        DIMENSION: 1500x650x1200mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 0.53Kw <br />
        CAPACITY: 4 STEPS <br /> <br />
        3) MODEL: WDS-6R <br />
        DIMENSION: 1800c650x1200mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 0.53Kw <br />
        CAPACITY: 4 STEPS
      </p>
    ),
  },
  {
    id: 223,
    name: 'TABLE CURVED GLASS CAKE DISPLAY ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p223.jpeg'),
    description: (
      <p>
        1) MODEL: ST-90 <br />
        DIMENSION: 900x530x750mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 0.53Kw <br />
        CAPACITY: 2Steps
      </p>
    ),
  },
  {
    id: 224,
    name: ' STANDING SOFT ICE CREAM MACHINE ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p224.jpeg'),
    description: (
      <p>
        1) MODEL: HIM-055 <br />
        DIMENSION: 540x780x1280mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 1.8Kw <br />
        CAPACITY OUTPUT: 25L/h <br /> <br />
        2) MODEL: HIM-055 ( PRE-COOLING ) <br />
        DIMENSION: 540x780x1280mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 1.8Kw <br />
        CAPACITY OUTPUT: 25L/h
      </p>
    ),
  },
  {
    id: 225,
    name: ' TABLE TOP SOFT ICE CREAM MACHINE',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p225.jpeg'),
    description: (
      <p>
        1) MODEL: HIM-088 <br />
        DIMENSION: 733x530x828mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 1.8Kw <br />
        CAPACITY OUTPUT: 25L/h <br />
        NUMBER OF NOZZLE: 3 <br /> <br />
        2) MODEL: HIM-088 ( PRE-COOLING ) <br />
        DIMENSION: 733x530x828mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 1.8Kw <br />
        CAPACITY OUTPUT: 25L/h <br />
        NUMBER OF NOZZLE: 3
      </p>
    ),
  },
  {
    id: 226,
    name: ' STANDING HARD ICE CREAM MACHINE  ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p226.jpeg'),
    description: (
      <p>
        1) MODEL: IIM-18 <br />
        DIMENSION: 900x700x1300mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 1.8Kw <br />
        CAPACITY OUTPUT: 18-25L/H
      </p>
    ),
  },
  {
    id: 227,
    name: ' TABLE TOP HARD ICE CREAM MACHINE  ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p227.jpeg'),
    description: (
      <p>
        1) MODEL: IIM-16 <br />
        DIMENSION: 733x530x828 <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 1.8Kw <br />
        CAPACITY OUTPUT: 18-25L/H
      </p>
    ),
  },
  {
    id: 228,
    name: ' BAKERY WATER CHILLER MACHINE ',
    category: ' BAKERY',
    brand: 'Homepoint',
    image: require('../images/p228.jpeg'),
    description: (
      <p>
        1) MODEL: RML-200 <br />
        DIMENSION: 810x600x1285mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 8Kw <br />
        CAPACITY: 200L <br /> <br />
        2) MODEL: RML-100 <br />
        DIMENSION: 80x580x950mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 5KW <br />
        CAPACITY: 100L
      </p>
    ),
  },
  {
    id: 229,
    name: ' STANDING  SQUARE GLASS  SNACK DISPLAY ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p229.jpeg'),
    description: (
      <p>
        1) MODEL: WK-4 <br />
        DIMENSION: 1200x650x1200mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 3KW <br />
        CAPACITY: 4 STEPS <br /> <br />
        2) MODEL: WK-4R <br />
        DIMENSION: 1500x650x1200mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 3Kw <br />
        CAPACITY: 4 STEPS <br /> <br />
        3) MODEL: WK-4R <br />
        DIMENSION: 1800c650x1200mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 3Kw <br />
        CAPACITY: 4 STEPS
      </p>
    ),
  },
  {
    id: 230,
    name: ' STANDING CURVED GLASS SNACK DISPLAY ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p230.jpeg'),
    description: (
      <p>
        1) MODEL: WKS-4R <br />
        DIMENSION: 1200x650x1200mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 3Kw <br />
        CAPACITY: 4 STEPS <br /> <br />
        2) MODEL: WKS-5R <br />
        DIMENSION: 1500x650x1200mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 3Kw CAPACITY: 4 STEPS <br /> <br />
        3) MODEL: WKS-6R <br />
        DIMENSION: 1800c650x1200mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 3Kw <br />
        CAPACITY: 4 STEPS
      </p>
    ),
  },
  {
    id: 231,
    name: ' FRIED / ROLL ICE CREAM MACHINE  ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p231.jpeg'),
    description: (
      <p>
        1) MODEL: FIM-A30 <br />
        DIMENSION: 830x440x700mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 1.8Kw <br />
        OUTPUT: 25Kg/hour
      </p>
    ),
  },
  {
    id: 232,
    name: ' FRIED / ROLL ICE CREAM MACHINE ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p232.jpeg'),
    description: (
      <p>
        1) MODEL: FIM-A15 <br />
        DIMENSION: 535x535x700mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 1.5KW <br />
        OUTPUT: 13Kg/hour
      </p>
    ),
  },
  {
    id: 233,
    name: ' ICE CUBE MACHINE  ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p233.jpeg'),
    description: (
      <p>
        1) MODEL: ST-700 <br />
        DIMENSION: 760x810x1640mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        CUBE: 234 ICE BIN <br />
        CAPACITY: 150Kg <br />
        ICE SIZE: 22*22*22mm <br />
        CAPACITY: 320Kg ICE MACHINE <br /> <br />
        2) MODEL: ST-1000 <br />
        DIMENSION: 760x810x1760mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        CUBE: 342 ICE BIN <br />
        CAPACITY: 150Kg <br />
        ICE SIZE: 22*22*22mm <br />
        CAPACITY: 450Kg ICE MACHINE
      </p>
    ),
  },
  {
    id: 234,
    name: ' ICE CUBE MACHINE  ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p234.jpeg'),
    description: (
      <p>
        1) MODEL: K-40 <br />
        DIMENSION: 450x530x740mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        CUBE: 32 <br />
        CAPACITY: 20kg ICE MACHINE <br /> <br />
        2) MODEL: K-50 <br />
        DIMENSION: 450x530x740mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        CUBE: 45 CAPACITY: <br /> <br />
        3) MODEL: AP-100P <br />
        DIMENSION: 490x560x860mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        CUBE: 55 <br />
        CAPACITY: 60KG ICE MACHINE <br /> <br />
        4) MODEL: AP-1300 <br />
        DIMENSION: 490x560x860mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br /> CUBE: 100 <br />
        CAPACITY: 150 ICE MACHINE
      </p>
    ),
  },
  {
    id: 235,
    name: ' MANUAL JUICE DISPENSER  ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p235.jpeg'),
    description: (
      <p>
        1) MODEL: JM-82 <br />
        DIMENSION: 350x540x580mm <br />
        CAPACITY: 8Lx2= 16L <br /> <br />
        2) MODEL: JM-83 <br />
        DIMENSION: 350x820x580mm <br />
        CAPACITY: 8Lx3= 24L
      </p>
    ),
  },
  {
    id: 236,
    name: ' AUTOMATIC JUICE DISPENSER MACHINE  ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p236.jpeg'),
    description: (
      <p>
        1) MODEL: JM-183 <br />
        DIMENSION: 750x470x750mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 0.32Kw <br />
        CAPACITY: 18Lx3
      </p>
    ),
  },
  {
    id: 237,
    name: 'AUTOMATIC  2TANK JUICE DISPENSER MACHINE ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p237.jpeg'),
    description: (
      <p>
        1) MODEL: JM-182 <br />
        DIMENSION: 510x470x750mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 0.28kw <br />
        CAPACITY: 18Lx2
      </p>
    ),
  },
  {
    id: 238,
    name: ' AUTOMATIC  1TANK JUICE DISPENSER MACHINE  ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p238.jpeg'),
    description: (
      <p>
        1) MODEL: JM-181 <br />
        DIMENSION: 480x360x750mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 0.28kw <br />
        CAPACITY: 18Lx1
      </p>
    ),
  },
  {
    id: 239,
    name: ' 3 TANK SLUSH MACHINE  ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p239.jpeg'),
    description: (
      <p>
        1) MODEL: SL-123 <br />
        DIMENSION: 660x550x870mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 0.6Kw <br />
        CAPACITY: 12Lx3
      </p>
    ),
  },
  {
    id: 240,
    name: ' 2 TANK SLUSH MACHINE  ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p240.jpeg'),
    description: (
      <p>
        1) MODEL: SL-122 <br />
        DIMENSION: 470x550x870mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 0.5KW <br />
        CAPACITY: 12Lx2
      </p>
    ),
  },
  {
    id: 241,
    name: ' 1 TANK SLUSH MACHINE  ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p241.jpeg'),
    description: (
      <p>
        1) MODEL: SL-121 <br />
        DIMENSION: 350x620x840mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 0.4Kw <br />
        CAPACITY: 12Lx1
      </p>
    ),
  },
  {
    id: 242,
    name: ' ICE CRUSHER MACHINE  ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p242.jpeg'),
    description: (
      <p>
        1) MODEL: HCM-6S <br />
        DIMENSION: 425x200x305mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 300W <br />
        CAPACITY: 65Kg/h
      </p>
    ),
  },
  {
    id: 243,
    name: ' AUTO ORANGE JUICER MACHINE ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p243.jpeg'),
    description: (
      <p>
        1) MODEL: 2000E <br />
        DIMENSION: 400x330x780mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 0.12Kw <br />
        CAPACITY: 20 Oranges/Min
      </p>
    ),
  },
  {
    id: 244,
    name: ' CENTRIFUGAL JUICER MACHINE  ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p244.jpeg'),
    description: (
      <p>
        1) MODEL: G-150 <br />
        DIMENSION: 420x360x510mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 0.55kw <br />
        CAPACITY: 10kg/Min
      </p>
    ),
  },
  {
    id: 245,
    name: 'COMMERCIAL BLENDER WITH SOUND COVER ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p245.jpeg'),
    description: (
      <p>
        1) MODEL: CM-999Z <br />
        DIMENSION: 200x220x500mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 1.8kw <br />
        CAPACITY: 2.7L
      </p>
    ),
  },
  {
    id: 246,
    name: ' COMMERCIAL BLENDER/ SMOOTHIE  ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p246.jpeg'),
    description: (
      <p>
        1) MODEL: CM-333 <br />
        DIMENSION: 270x235x530mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 1.8kw <br />
        CAPACITY: 4L
      </p>
    ),
  },
  {
    id: 247,
    name: ' COMMERCIAL ELECTRIC BOILER',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p247.jpeg'),
    description: (
      <p>
        1) MODEL: CM-25 <br />
        DIMENSION: 315x490mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 2.0Kw <br />
        CAPACITY: 25L <br /> <br />
        2) MODEL: CM-30 <br />
        DIMENSION: 315x540mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 2.0Kw <br />
        CAPACITY: 30L <br /> <br />
        3) MODEL: CM-35 <br />
        DIMENSION: 315x62mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 2.0Kw <br />
        CAPACITY: 35L <br /> <br />
        4) MODEL: CM-45 <br />
        DIMENSION: 315x670mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 2.5KW <br />
        CAPACITY: 45L
      </p>
    ),
  },
  {
    id: 248,
    name: ' CHAFING DISH ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p248.jpeg'),
    description: <p>AVAILABLE IN CANDLE AND ELECTRIC</p>,
  },
  {
    id: 249,
    name: ' CHOCOLATE FOUNTAIN  ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p249.jpeg'),
    description: (
      <p>
        1) MODEL: CF-8060 <br />
        AVAILABLE SIZES: 3Layers, 4Layers, 5Layers and 6Layers <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 0.2Kw <br />
        WORKING CAPACITY: 2Kgs
      </p>
    ),
  },
  {
    id: 250,
    name: ' TABLE VACUUM SEALER MACHINE ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p250.jpeg'),
    description: (
      <p>
        1) MODEL: DM-400T CHAMBER <br />
        DIMENSION: 440x420x75mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER:0.75Kw <br />
        DIMENSION: 550x640x600mm
      </p>
    ),
  },
  {
    id: 251,
    name: ' STANDING VACUUM SEALER MACHINE  ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p251.jpeg'),
    description: (
      <p>
        1) MODEL: DM-600S CHAMBER <br />
        DIMENSION: 660x660x75mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER:1.5KW <br />
        DIMENSION: 1100x750x800mm
      </p>
    ),
  },
  {
    id: 252,
    name: ' COMMERCIAL 3 TANK SINK ',
    category: 'STAINLESS STEEL PRODUCTS',
    brand: 'Homepoint',
    image: require('../images/p252.jpeg'),
    description: (
      <p>
        1) MODEL: 3 TANK <br />
        DIMENSION: 1750X670X950mm
      </p>
    ),
  },
  {
    id: 253,
    name: ' COMMERCIAL 2 TANK SINK',
    category: 'STAINLESS STEEL PRODUCTS',
    brand: 'Homepoint',
    image: require('../images/p253.jpeg'),
    description: (
      <p>
        1) MODEL: 2 TANK <br />
        DIMENSION: 1200X670X950mm
      </p>
    ),
  },
  {
    id: 254,
    name: ' COMMERCIAL 1 TANK PORT SINK',
    category: 'STAINLESS STEEL PRODUCTS',
    brand: 'Homepoint',
    image: require('../images/p254.jpeg'),
    description: (
      <p>
        1) MODEL: 1 TANK <br />
        DIMENSION: 1200X670X950mm
      </p>
    ),
  },
  {
    id: 255,
    name: ' COMMERCIAL SINK ',
    category: 'STAINLESS STEEL PRODUCTS',
    brand: 'Homepoint',
    image: require('../images/p255.jpeg'),
    description: '',
  },
  {
    id: 256,
    name: ' COMMERCIAL 1 TANK SINK WITH SIDE TRAY/UNDER-SHELVE',
    category: 'STAINLESS STEEL PRODUCTS',
    brand: 'Homepoint',
    image: require('../images/p256.jpeg'),
    description: (
      <p>
        1) MODEL: 1 TANK <br />
        DIMENSION: 1200X670X950mm
      </p>
    ),
  },
  {
    id: 257,
    name: ' COMMERCIAL 1 TANK SINK WITH SIDE TRAY/UNDER-SHELVE ',
    category: 'STAINLESS STEEL PRODUCTS',
    brand: 'Homepoint',
    image: require('../images/p257.jpeg'),
    description: (
      <p>
        1) MODEL: 1 TANK <br />
        DIMENSION: 1200X670X950mm
      </p>
    ),
  },
  {
    id: 258,
    name: ' COMMERCIAL 2 TANK SINK WITH SIDE TRAY/ UNDER-SHELVE',
    category: 'STAINLESS STEEL PRODUCTS',
    brand: 'Homepoint',
    image: require('../images/p258.jpeg'),
    description: (
      <p>
        1) MODEL: 2 TANK <br />
        DIMENSION: 1750X670X950mm
      </p>
    ),
  },
  {
    id: 259,
    name: ' STAINLESS STEEL TABLE ',
    category: 'STAINLESS STEEL PRODUCTS',
    brand: 'Homepoint',
    image: require('../images/p259.jpeg'),
    description: (
      <p>
        1) SIZES AVAILABLE: 900x700x950mm 1200x700x950mm 1500x700x950mm
        1800x700x950mm 2100x700x950mm 2400x700x950mm
      </p>
    ),
  },
  {
    id: 260,
    name: 'STAINLESS STEEL TABLE WITH SPLASH-BACK',
    category: 'STAINLESS STEEL PRODUCTS',
    brand: 'Homepoint',
    image: require('../images/p260.jpeg'),
    description: (
      <p>
        1) SIZES AVAILABLE: 900x700x950mm 1200x700x950mm 1500x700x950mm
        1800x700x950mm 2100x700x950mm 2400x700x950mm
      </p>
    ),
  },
  {
    id: 261,
    name: 'STAINLESS STEEL TABLE WITH 3 STEPS',
    category: 'STAINLESS STEEL PRODUCTS',
    brand: 'Homepoint',
    image: require('../images/p261.jpeg'),
    description: (
      <p>
        1) SIZES AVAILABLE: 900x700x950mm 1200x700x950mm 1500x700x950mm
        1800x700x950mm 2100x700x950mm 2400x700x950mm
      </p>
    ),
  },
  {
    id: 262,
    name: ' STAINLESS STEEL WALL SHELVE ( DOUBLE STEP)',
    category: 'STAINLESS STEEL PRODUCTS',
    brand: 'Homepoint',
    image: require('../images/p262.jpeg'),
    description: (
      <p>1) SIZES AVAILABLE: 1000X300X400mm 1200x300x400mm 1400x300x400mm</p>
    ),
  },
  {
    id: 263,
    name: 'STAINLESS STEEL WALL SHELVE ( SINGLE STEP)',
    category: 'STAINLESS STEEL PRODUCTS',
    brand: 'Homepoint',
    image: require('../images/p263.jpeg'),
    description: (
      <p>
        1) SIZES AVAILABLE: 900x350x300mm 1200x350x300mm 1500x350x300mm
        1800x350x300mm
      </p>
    ),
  },
  {
    id: 264,
    name: ' STAINLESS STEEL SHELVE ( 4 STEPS )',
    category: 'STAINLESS STEEL PRODUCTS',
    brand: 'Homepoint',
    image: require('../images/p264.jpeg'),
    description: (
      <p>
        1) SIZES AVAILABLE: 900x500x1550mm 1200x500x1550mm 1500x500x1550mm
        1800x500x1550mm
      </p>
    ),
  },
  {
    id: 265,
    name: ' STAINLESS STEEL SHELVE ( 4 STEPS )',
    category: 'STAINLESS STEEL PRODUCTS',
    brand: 'Homepoint',
    image: require('../images/p265.jpeg'),
    description: (
      <p>
        1) SIZES AVAILABLE: 900x500x1550mm 1200x500x1550mm 1500x500x1550mm
        1800x500x1550mm
      </p>
    ),
  },
  {
    id: 266,
    name: ' STAINLESS STEEL SHELVE ( 5 STEPS )',
    category: 'STAINLESS STEEL PRODUCTS',
    brand: 'Homepoint',
    image: require('../images/p266.jpeg'),
    description: (
      <p>
        1) SIZES AVAILABLE: 900x500x1800mm 1200x500x1800mm 1500x500x1800mm
        1800x500x1800mm
      </p>
    ),
  },
  {
    id: 267,
    name: 'CHROME WIRE SHELVE ( 4 STEPS )',
    category: 'STAINLESS STEEL PRODUCTS',
    brand: 'Homepoint',
    image: require('../images/p267.jpeg'),
    description: (
      <p>
        1) SIZES AVAILABLE: 900x500x1800mm 1200x500x1800mm 1500x500x1800mm
        1800x500x1800mm ( 6 STEPS WITH WHEEL) <br /> <br />
        2) SIZES AVAILABLE: 900x500x1800mm 1200x500x1800mm 1500x500x1800mm
        1800x500x1800mm ( 8 STEPS WITH WHEEL) <br /> <br />
        3) SIZES AVAILABLE 900x500x1800mm 1200x500x1800mm 1500x500x1800mm
        1800x500x1800mm
      </p>
    ),
  },
  {
    id: 268,
    name: ' STAINLESS STEEL TROLLEY ( 3 STEPS )',
    category: 'STAINLESS STEEL PRODUCTS',
    brand: 'Homepoint',
    image: require('../images/p268.jpeg'),
    description: <p>1) SIZES AVAILABLE: 950x500x950mm 850x450x900mm</p>,
  },
  {
    id: 269,
    name: '  BREAD PAN',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p269.jpeg'),
    description: '',
  },
  {
    id: 270,
    name: 'OVEN TRAY ',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p270.jpeg'),
    description: <p>SIZE: 600x400x30mm</p>,
  },
  {
    id: 271,
    name: ' NON STICK OVEN TRAY ',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p271.jpeg'),
    description: <p>SIZE: 600x400x30mm</p>,
  },
  {
    id: 272,
    name: 'DIESEL/ELECTRIC/ GAS ROTARY OVEN',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p272.jpeg'),
    description: (
      <p>
        1) MODEL: RMTY-32 <br />
        DIMENSION: 2190x2055x2400mm <br /> <br />
        2) MODEL: RMTY-64 <br />
        DIMENSION: 2190x2580x2400mm
      </p>
    ),
  },
  {
    id: 273,
    name: ' UNDER-COUNTER  TWO DOOR REFRIGERATOR /FREEZER ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p273.jpeg'),
    description: (
      <p>
        1) MODEL: TGO-4L2 ( FRIDGE ) <br />
        DIMENSION: 1500x760x850mm <br />
        VOLTAGE: 220v/50hz <br />
        TEMPERATURE: +2- +8 <br /> <br />
        2) MODEL: TGO-4L2 ( FREEZER <br />
        DIMENSION: 1500x760x850mm <br />
        VOLTAGE: 220v/50hz <br />
        TEMPERATURE: -5 ~ -15 <br /> <br />
        3) MODEL: TGO-4L2 ( FREEZER ) <br />
        DIMENSION: 1200x760x850mm <br />
        VOLTAGE: 220v/50hz <br />
        TEMPERATURE: -5 ~ -15 <br /> <br />
        4) MODEL: TGO-4L2 ( FRIDGE ) <br />
        DIMENSION: 1200x760x850mm <br />
        VOLTAGE: 220v/50hz <br /> TEMPERATURE: 2 ~ -8 5) <br />
        MODEL: TGO-4L2 ( FREEZER ) <br />
        DIMENSION: 1800x760x850mm <br />
        VOLTAGE: 220v/50hz <br />
        TEMPERATURE: -5 ~ -15 <br /> <br />
        6) MODEL: TGO-4L2 ( FRIDGE) <br />
        DIMENSION: 1800x760x850mm <br />
        VOLTAGE: 220v/50hz <br />
        TEMPERATURE: 2~ -8
      </p>
    ),
  },
  {
    id: 274,
    name: ' COMMERCIAL DOUBLE DOOR GLASS FREEZER/ FRIDGE ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p274.jpeg'),
    description: (
      <p>
        1) MODEL: BN-UC1300F2G ( FREEZER ) <br />
        DIMENSION: 1480x830x2010mm <br />
        CAPACITY: 1500L <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        TEMPERATURE: -15~ -18 <br /> <br />
        2)MODEL: BN-UC1300R2G ( FRIDGE ) <br />
        DIMENSION: 1480x830x2010mm <br />
        CAPACITY: 1500L <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        TEMPERATURE: -2~ +8
      </p>
    ),
  },
  {
    id: 275,
    name: ' COMMERCIAL DOUBLE DOOR GLASS FREEZER/ FRIDGE ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p275.jpeg'),
    description: (
      <p>
        1) MODEL: BN-UC650F2 ( FREEZER ) <br />
        DIMENSION: 740x830x2010mm <br />
        CAPACITY: 750L <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        TEMPERATURE: -15~ -18 <br /> <br />
        2) MODEL: BN-UC650R1 ( FRIDGE ) <br />
        DIMENSION: 740x830x2010mm <br />
        CAPACITY: 750L <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        TEMPERATURE: -2~ +8
      </p>
    ),
  },
  {
    id: 276,
    name: ' COMMERCIAL STAINLESS STEEL DOUBLE DOOR FREEZER/ FRIDGE',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p276.jpeg'),
    description: (
      <p>
        1) MODEL: BN-UC1300F2 ( FREEZER ) <br />
        DIMENSION: 1480x830x2010mm <br />
        CAPACITY: 1500L <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        TEMPERATURE: -15~ -18 <br /> <br />
        2) MODEL: BN-UC1300R2 ( FRIDGE ) <br />
        DIMENSION: 1480x830x2010mm <br />
        CAPACITY: 1500L <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        TEMPERATURE: -2~ +8
      </p>
    ),
  },
  {
    id: 277,
    name: ' COMMERCIAL STAINLESS STEEL SINGLE DOOR FREEZER/ FRIDGE',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p277.jpeg'),
    description: (
      <p>
        1) MODEL: BN-UC650F1 ( FREEZER ) <br />
        DIMENSION: 740x830x2010mm <br />
        CAPACITY: 750L <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        TEMPERATURE: -15~ -18 <br /> <br />
        2) MODEL: BN-UC650R1 ( FRIDGE ) <br />
        DIMENSION: 740x830x2010mm <br />
        CAPACITY: 750L <br />
        VOLTAGE: 220-240V/ 50Hz <br />
        TEMPERATURE: -2~ +8
      </p>
    ),
  },
  {
    id: 278,
    name: ' DOUGH PROOFER ( TROLLEY TYPE )',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p278.jpeg'),
    description: (
      <p>
        1) MODEL: MTY-64 ( DOUBLE DOOR/ 2TROLLEY) <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 5KW <br />
        CAPACITY: 2bags <br /> <br />
        2) MODEL: MTY-32 ( SINGLE DOOR/ 1TROLLEY) <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 3Kw <br />
        CAPACITY: 1Bag
      </p>
    ),
  },
  {
    id: 279,
    name: 'DOUGH PROOFER ',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p279.jpeg'),
    description: (
      <p>
        1) MODEL: FM-32 <br />
        DIMENSION: 1000x690x1660mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 2.8Kw <br />
        CAPACITY: 32TRAYS <br /> <br />
        2) MODEL: FM-16 <br />
        DIMENSION: 490x690x1880mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 2.8Kw <br />
        CAPACITY: 16Trays
      </p>
    ),
  },
  {
    id: 280,
    name: ' OPEN MEAT DISPLAY CHILLER',
    category: 'SUPERMARKET',
    brand: 'Homepoint',
    image: require('../images/p280.jpeg'),
    description: (
      <p>
        1) MODEL: SXG-25 <br /> DIMENSION: 2500x1180x900mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        TEMPERATURE: -2~+8 <br /> <br />
        2) MODEL: SXG-2000 <br />
        DIMENSION: 2000x1180x900mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        TEMPERATURE: -2~+8
      </p>
    ),
  },
  {
    id: 281,
    name: ' CURVED GLASS MEAT DISPLAY CHILLER ',
    category: 'SUPERMARKET',
    brand: 'Homepoint',
    image: require('../images/p281.jpeg'),
    description: (
      <p>
        1) MODEL: LG-25B <br />
        DIMENSION: 2500x1180x900mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        TEMPERATURE: -2~+8 <br /> <br />
        2) MODEL: LG-2000B <br />
        DIMENSION: 2000x1180x900mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        TEMPERATURE: -2~+8
      </p>
    ),
  },
  {
    id: 282,
    name: 'OPEN CHILLER ',
    category: 'SUPERMARKET',
    brand: 'Homepoint',
    image: require('../images/p282.jpeg'),
    description: (
      <p>
        1) MODEL: FMB-2500 <br />
        DIMENSION: 2500x1080x1680mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        TEMPERATURE: -2~+8 <br /> <br />
        2) MODEL: FMB-2000 <br />
        DIMENSION: 2000x1080x1680mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        TEMPERATURE: -2~+8
      </p>
    ),
  },
  {
    id: 283,
    name: ' OPEN CHILLER ',
    category: 'SUPERMARKET',
    brand: 'Homepoint',
    image: require('../images/p283.jpeg'),
    description: (
      <p>
        1) MODEL: FMG-2500 <br />
        DIMENSION: 2500x1080x2000mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        TEMPERATURE: -2~+8 <br /> <br />
        2) MODEL: FMG-2000 <br />
        DIMENSION: 2000x1080x2000mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        TEMPERATURE: -2~+8 <br /> <br />
        3) MODEL: FMG-1500 <br />
        DIMENSION: 1500x1080x2000mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        TEMPERATURE: -2~+8
      </p>
    ),
  },
  {
    id: 284,
    name: ' SALAD/PIZZA FRIDGE ',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p284.jpeg'),
    description: (
      <p>
        1) MODEL: PZ-150 <br />
        DIMENSION: 1500x760x1050mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        TEMPERATURE: -2~+8 <br /> <br />
        2) MODEL: PZ-180 ( 3 doors ) <br />
        DIMENSION: 1800x750x1050mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        TEMPERATURE: -2~+8
      </p>
    ),
  },
  {
    id: 285,
    name: ' BACK BAR COOLER',
    category: 'CATERING ',
    brand: 'Homepoint',
    image: require('../images/p285.jpeg'),
    description: (
      <p>
        1) MODEL: PZ:135 ( 3 DOORS ) <br />
        DIMENSION: 1350x535x840mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        TEMPERATURE: -2~+10 <br /> <br />
        2) MODEL: PZ:135 ( 2 DOORS ) <br />
        DIMENSION: 900x535x840mm <br />
        SOURCE: ELECTRIC <br />
        VOLTAGE: 220V/ 50Hz <br />
        TEMPERATURE: -2~+10
      </p>
    ),
  },
  {
    id: 286,
    name: 'SUPERMARKET SHELVE ( SINGLE FACE )',
    category: 'SUPERMARKET',
    brand: 'Homepoint',
    image: require('../images/p286.jpeg'),
    description: (
      <p>
        SIZE: 900x400x2100mm <br /> SIZE: 900x400x1950mm
      </p>
    ),
  },
  {
    id: 287,
    name: ' SUPERMARKET SHELVE ( DOUBLE FACE )',
    category: 'SUPERMARKET',
    brand: 'Homepoint',
    image: require('../images/p287.jpeg'),
    description: <p>SIZE: 900x900x1800mm</p>,
  },
  {
    id: 288,
    name: ' SUPERMARKET CASHIER DESK',
    category: 'SUPERMARKET',
    brand: 'Homepoint',
    image: require('../images/p288.jpeg'),
    description: <p>AVAILABLE SIZES: 1.2M, 1.5m and 1.8m</p>,
  },
  {
    id: 289,
    name: ' SUPERMARKET ALUMINIUM TROLLEY ',
    category: 'SUPERMARKET',
    brand: 'Homepoint',
    image: require('../images/p289.jpeg'),
    description: <p>AVAILABLE CAPACITIES: 60L, 80L, 100L and 120L</p>,
  },
  {
    id: 290,
    name: ' SUPERMARKET PLASTIC TROLLEY',
    category: 'SUPERMARKET',
    brand: 'Homepoint',
    image: require('../images/p290.jpeg'),
    description: '',
  },
  {
    id: 291,
    name: 'SUPERMARKET HAND PLASTIC BASKET',
    category: 'SUPERMARKET',
    brand: 'Homepoint',
    image: require('../images/p291.jpeg'),
    description: '',
  },
  {
    id: 292,
    name: ' ISLAND FREEZER ',
    category: 'SUPERMARKET',
    brand: 'Homepoint',
    image: require('../images/p292.jpeg'),
    description: (
      <p>
        SIZES AVAILABLE: 1850x850x750mm 2100x850x750mm <br />
        TEMPERATURE: -18~ -22
      </p>
    ),
  },
  {
    id: 293,
    name: ' STAINLESS STEEL TABLE WITH OVER-SHELVE',
    category: 'STAINLESS STEEL PRODUCTS',
    brand: 'Homepoint',
    image: require('../images/p293.jpeg'),
    description: (
      <p>
        AVAILABLE SIZES: 1200x600x850+550mm 1500x600x850+550mm
        1800x600x850+550mm
      </p>
    ),
  },
  {
    id: 294,
    name: ' COOKIES / BREAD DISPLAY SHOWCASE ( DOUBLE FACE )',
    category: 'SUPERMARKET',
    brand: 'Homepoint',
    image: require('../images/p294.jpeg'),
    description: `
`,
  },
  {
    id: 295,
    name: ' STAINLESS STEEL WALL CABINET WITH SLIDING DOOR',
    category: 'STAINLESS STEEL PRODUCTS',
    brand: 'Homepoint',
    image: require('../images/p295.jpeg'),
    description: (
      <p>AVAILABLE SIZES: 1200x450x600mm 1500x450x600mm 1800x450x600mm</p>
    ),
  },
  {
    id: 296,
    name: 'COMMERCIAL WASHING MACHINE',
    category: 'LAUNDRY',
    brand: 'Homepoint',
    image: require('../images/p296.jpeg'),
    description: (
      <p>
        1) MODEL= XGQ-15F <br />
        CAPACITY: 15KG DRUM <br />
        DIMENSION: 700x400mm <br />
        DRUM VOLUME: 155L <br />
        DRUM SPEED: 58/750 <br />
        RPM MOTOR POWER: 2.2Kw <br />
        INVERTER POWER: 2.2KW <br />
        HEATING POWER: 6Kw <br />
        COLD / HOT WATER INLET: 20mm <br />
        DRAIN PIPE:60MM <br />
        DIMENSION: 950x1040x1560mm <br /> <br />
        2) MODEL= XGQ-20F <br />
        CAPACITY: 20Kg <br />
        DRUM DIMENSION: 700x520mm <br />
        DRUM VOLUME: 200LITERS <br />
        DRUM SPEED: 48/700 <br />
        RPM MOTOR POWER: 2.2Kw <br />
        INVERTER POWER: 2.2KW <br />
        HEATING POWER: 10Kw <br />
        COLD / HOT WATER INLET: 20mm <br />
        DRAIN PIPE: 60MM <br />
        DIMENSION: 950x1200x1560mm <br /> <br />
        3) MODEL=XGQ-25F CAPACITY: 25KG <br />
        DRUM DIMENSION: 800x500mm <br /> DRUM VOLUME: 251L <br />
        DRUM SPEED: 48 / 650 <br />
        RPM MOTOR POWER: 2.2Kw <br />
        INVERTER POWER: 2.2KW <br />
        HEATING POWER: 15Kw <br />
        COLD / HOT WATER INLET: 20mm <br />
        DRAIN PIPE: 60MM <br /> DIMENSION: 1090x1170x1580mm <br /> <br />
        4) MODEL= XGQ-30F <br />
        CAPACITY: 30KG <br />
        DRUM DIMENSION:800x580mm <br />
        DRUM VOLUME: 291L <br />
        DRUM SPEED: 36/700mm <br />
        MOTOR POWER: 2.2Kw <br />
        INVERTER POWER: 2.2KW <br />
        HEATING POWER: 24Kw <br />
        COLD / HOT WATER INLET: 20mm <br />
        DRAIN PIPE: 76mm <br />
        DIMENSION: 1420x1450x1800mm
      </p>
    ),
  },
  {
    id: 297,
    name: ' COMMERCIAL TUMBLE DRYER',
    category: 'LAUNDRY',
    brand: 'Homepoint',
    image: require('../images/p297.jpeg'),
    description: (
      <p>
        1) MODEL= HG-15 <br /> CAPACITY: 15KG <br />
        DRUM DIMENSION: 750x670mm <br />
        DRUM VOLUME: 295L <br />
        MOTOR POWER: 0.5Kw <br />
        INVERTER POWER: 0.5Kw <br />
        HEATING POWER: 15Kw <br />
        DIMENSION: 840x1220x1510mm <br /> <br />
        2) MODEL= HG-20 <br />
        CAPACITY: 20Kg <br />
        DRUM DIMENSION: 890x620mm <br />
        DRUM VOLUME: 386L <br />
        MOTOR POWER: 0.5Kw <br />
        INVERTER POWER: 0.5Kw <br />
        HEATING POWER: 19.8kw <br />
        DIMENSION: 1000x1220x1510mm <br /> <br />
        3) MODEL= HG-25 <br />
        CAPACITY: 25kg <br />
        DRUM DIMENSION: 970x640mm <br />
        DRUM VOLUME: 444L <br />
        MOTOR POWER: 0.5Kw <br />
        INVERTER POWER: 0.5Kw <br />
        HEATING POWER: 24Kw <br />
        DIMENSION: 1060x1270x1860mm <br /> <br />
        4) MODEL= HG-35 <br />
        CAPACITY: 35kg <br /> DRUM DIMENSION: 970x890mm <br />
        DRUM VOLUME: 630L <br />
        MOTOR POWER: 0.5Kw <br />
        INVERTER POWER: 0.5Kw <br />
        HEATING POWER:33Kw <br />
        DIMENSION: 1060x1570x1800mm
      </p>
    ),
  },
  {
    id: 298,
    name: ' POWER DISTRIBUTION TRANSFORMER',
    category: 'NEPA POWER DISTRIBUTION TRANSFORMERS ',
    brand: 'Homepoint',
    image: require('../images/p298.jpeg'),
    description: (
      <p>
        AVAILABLE CAPACITIES/ 11KVA/ 33KVA <br />
        1) 50kva <br />
        2) 100kva <br />
        3) 200kva <br /> 4) 300kva <br /> 5) 400kva <br />
        6) 500kva <br /> 7) 600kva
      </p>
    ),
  },
  {
    id: 299,
    name: ' SUPERMARKET GLASS DISPLAY/ BEVERAGE FRIDGE',
    category: 'SUPERMARKET',
    brand: 'Homepoint',
    image: require('../images/p299.jpeg'),
    description: (
      <p>
        AVAILABLE SIZES <br />
        1) Double doors <br />
        2) Three doors <br />
        3) Four doors <br />
        4) Five doors
      </p>
    ),
  },
  {
    id: 300,
    name: ' SUPERMARKET GLASS DISPLAY/ BEVERAGE FREEZER',
    category: 'SUPERMARKET',
    brand: 'Homepoint',
    image: require('../images/p300.jpeg'),
    description: (
      <p>
        AVAILABLE SIZES <br />
        1) Double doors <br />
        2) Three doors <br />
        3) Four doors <br />
        4) Five doors
      </p>
    ),
  },
  {
    id: 301,
    name: ' WAREHOUSE SHELVE',
    category: 'SUPERMARKET',
    brand: 'Homepoint',
    image: require('../images/p301.jpeg'),
    description: (
      <p>
        {' '}
        AVAILABLE SIZES <br /> 1) 1800x600x2500mm{' '}
      </p>
    ),
  },
  {
    id: 302,
    name: 'FINISHING TABLE WITH IRON AND BOILERE',
    category: 'LAUNDRY',
    brand: 'Homepoint',
    image: require('../images/p302.jpeg'),
    description: '',
  },
  {
    id: 303,
    name: 'STAINLESS STEEL UNDER FRIDGE WITH DRAWERS ',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p303.jpeg'),
    description: (
      <p>
        {' '}
        AVAILABLE SIZES: <br /> 1) 1800x700x850+150mm <br /> 2)
        1400x700x850+150mm{' '}
      </p>
    ),
  },
  {
    id: 304,
    name: 'STAINLESS STEEL UNDER FREEZER WITH DRAWERS',
    category: 'CATERING',
    brand: 'Homepoint',
    image: require('../images/p304.jpeg'),
    description: (
      <p>
        {' '}
        AVAILABLE SIZES: <br />
        1) 1800x700x850+150mm <br /> 2) 1400x700x850+150mm{' '}
      </p>
    ),
  },
  {
    id: 305,
    name: 'FOOD / PLANETARY MIXER',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p305.jpeg'),
    description: (
      <p>
        1) MODEL: B40, B50, B60 DIMENSION: 620x640x1250mm <br /> SOURCE:
        ELECTRIC <br /> VOLTAGE: 380v/ 50Hz <br /> CAPACITIES AVAILABLE: 40L,
        50L and 60L <br />
        POWER: 1.5Kw
      </p>
    ),
  },
  {
    id: 306,
    name: ' POWER DISTRIBUTION TRANSFORMER',
    category: 'NEPA POWER DISTRIBUTION TRANSFORMERS',
    brand: 'Homepoint',
    image: require('../images/P306.jpeg'),
    description: (
      <p>
        AVAILABLE CAPACITIES/ 11KVA/ 33KVA
        <br />
        1) 50kva <br />
        2) 100kva <br />
        3) 200kva <br /> 4) 300kva <br /> 5) 400kva <br />
        6) 500kva <br /> 7) 600kva
      </p>
    ),
  },
  {
    id: 307,
    name: 'SPIRAL DOUGH MIXER',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p307.jpeg'),
    description: (
      <p>
        1) MODEL: HS-80 <br />
        DIMENSION: 1040x700x1330mm <br />
        SOURCE: ELECTRIC <br />
        BOWL CAPACITY: 80L <br />
        FLOUR CAPACITY: 25Kg <br />
        POWER: 380/50Hz/ 3phase <br />
        <br />
        2) MODEL: HS-130 <br />
        DIMENSION: 1220x810x1440mm <br />
        SOURCE: ELECTRIC BOWL <br />
        CAPACITY: 130L <br />
        FLOUR CAPACITY: 50L <br />
        POWER: 380/50Hz/ 3phase <br /> <br />
        3) MODEL: HS-260 <br />
        DIMENSION: 1400x1010x1550mm <br />
        SOURCE: ELECTRIC BOWL <br />
        CAPACITY: 260LITERS <br />
        FLOUR CAPACITY: 100Kg <br />
        POWER: 380/50Hz/ 3phase
      </p>
    ),
  },
  {
    id: 308,
    name: 'SPIRAL DOUGH MIXER',
    category: 'BAKERY',
    brand: 'Homepoint',
    image: require('../images/p308.jpeg'),
    description: (
      <p>
        1) MODEL: H20 <br />
        DIMENSION: 730x390x900mm <br />
        SOURCE: ELECTRIC <br />
        BOWL CAPACITY: 20L <br />
        FLOUR CAPACITY: 8Kg <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 1.5KW <br /> <br />
        2) MODEL: H30 <br />
        DIMENSION: 750x435x900mm <br />
        SOURCE: ELECTRIC <br />
        BOWL CAPACITY: 30L <br />
        FLOUR CAPACITY: 12.5Kg <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 1.5KW <br /> <br />
        3) MODEL: H40 <br />
        DIMENSION: 840x480x1000mm <br />
        SOURCE: ELECTRIC <br />
        BOWL CAPACITY: 40L <br />
        FLOUR CAPACITY: 16Kg <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 1.5KW <br /> <br />
        4) MODEL: H50 <br />
        DIMENSION: 840x480x1000mm <br />
        SOURCE: ELECTRIC <br />
        BOWL CAPACITY: 50L <br />
        FLOUR CAPACITY: 20Kg <br />
        VOLTAGE: 220V/ 50Hz <br />
        POWER: 3Kg <br /> <br />
        5) MODEL: H60 <br />
        DIMENSION: 840x480x1000mm <br />
        SOURCE: ELECTRIC <br />
        BOWL CAPACITY: 60L <br />
        FLOUR CAPACITY: 25Kg <br />
        VOLTAGE: 220V/ 50Hz <br /> POWER: 3Kw
      </p>
    ),
  },
]
