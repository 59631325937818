import React, { useEffect, useMemo } from 'react'
import { useGlobalContext } from '../Context'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { BiArrowBack } from 'react-icons/bi'
import { useNavigate, useParams } from 'react-router-dom'

function Singleproduct() {
  const navigate = useNavigate()
  const searchparams = useParams()
  console.log(searchparams)
  const productId = searchparams.id

  const { setCurrentpage, allproducts } = useGlobalContext()

  const singleproduct = useMemo(
    () => allproducts.filter((item) => item.id === Number(productId))?.[0],
    [productId, allproducts]
  )
  useEffect(() => {
    setCurrentpage('others')
  }, [setCurrentpage])

  return (
    <>
      <Header />
      {/* dispalay */}
      <section className='relative'>
        <div className=' section-center flex flex-col md:flex-row justify-center items-center space-y-10 md:space-y-0 rounded'>
          {/* image */}
          <div className='relative md:w-1/2 '>
            <img
              src={singleproduct?.image}
              alt='product'
              width={1000}
              height={1000}
              className='w-72 h-72 object-contain sm:w-96 sm:h-96 md:w-80 md:h-80  lg:w-96 lg:h-96 xl:w-[28rem] xl:h-[28rem]'
            />
          </div>
          {/* text */}
          <div className='space-y-10 md:w-1/2'>
            <div className='space-y-3 sm:space-y-4 md:space-y-8 '>
              <h1 className='text-babyblack text-xs font-bold sm:text-base lg:text-lg '>
                Product Name :{' '}
                <span className='font-normal'> {singleproduct?.name}</span>
              </h1>
              <h1 className='text-babyblack font-bold text-xs sm:text-base lg:text-lg'>
                Brand :{' '}
                <span className='font-normal'> {singleproduct?.brand}</span>
              </h1>
              {singleproduct?.description === '' ? (
                ''
              ) : (
                <h1 className='text-babyblack flex items-start gap-2 text-xs font-bold sm:text-base lg:text-lg '>
                  Categorie(s) :{' '}
                  <span className=' font-normal'>
                    {singleproduct?.description}
                  </span>
                </h1>
              )}
            </div>
            <button className='bg-babyblack text-white px-6 py-2 text-xs  md:text-sm  xl:text-base sm:px-8 sm:py-3 rounded shadow flex justify-center items-center mx-auto  md:mx-0  md:py-2 md:px-8 xl:py-3 md:shadow-md  xl:px-10 xl:rounded-md '>
              <a
                href='https://wa.me/2347010347348'
                target='_blank'
                rel='noopener noreferrer'
              >
                Inquire about this product price
              </a>
            </button>
          </div>
        </div>
        <div
          onClick={() => {
            navigate(`/Allproducts`)
          }}
          className='absolute top-0 left-8 bg-babyblack  px-4 py-1 text-white shadow-md  duration-1000 cursor-pointer'
        >
          <BiArrowBack className=' text-xl sm:text-2xl md:tex-3xl lg:text-4xl' />
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Singleproduct
