import { Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import Aboutus from './pages/Aboutus'
import Brands from './pages/Brands'
import Allproducts from './pages/Allproducts'
import Error from './pages/Error'
import Singleproduct from './pages/Singleproduct'
import { BsWhatsapp } from 'react-icons/bs'
function App() {
  return (
    <div className='App'>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='Aboutus' element={<Aboutus />} />
        <Route path='Allproducts' element={<Allproducts />} />
        <Route path='Brands' element={<Brands />} />
        <Route path='/Singleproduct/:id' element={<Singleproduct />} />
        <Route path='*' element={<Error />} />
      </Routes>
      {/* whatsapp feature */}
      <div
        className='bg-green-500 inline-block rounded-full text-white p-3 lg:p-4 fixed
          bottom-10
          right-10 animate-bounce duration-1000 '
      >
        <a
          href='https://wa.me/2347010347348'
          target='_blank'
          rel='noopener noreferrer'
        >
          <BsWhatsapp className='text-3xl xl:text-4xl' />
        </a>
      </div>
    </div>
  )
}

export default App
