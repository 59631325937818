import React, { useState } from 'react'
import { useGlobalContext } from '../Context'
import { Link } from 'react-router-dom'
import logo from '../images/logo.png'

function Header() {
  const [pannel, setPannel] = useState(false)
  const { currentpage } = useGlobalContext()

  return (
    <nav
      className={`${
        currentpage === 'home'
          ? ' relative mx-auto font-sans px-1 md:px-4 lg:px-10 py-2 md:py-2 '
          : ' relative mx-auto font-sans px-1 md:px-4 lg:px-10 py-2 md:py-2 bg-white shadow-md shadow-babyblack'
      }`}
    >
      {/* <!-- Flex Container For Nav Items --> */}
      <div className='flex items-center justify-between px-4 sm:px-6 md:px-0 my-6  '>
        {/* <!-- Logo --> */}
        <div className='z-30 '>
          {pannel ? (
            // mobile open
            <div className='  relative '>""</div>
          ) : (
            <Link to='/'>
              {' '}
              <div className='flex items-center gap-2 cursor-pointer'>
                <div className='  relative '>
                  <img
                    src={logo}
                    alt='logo'
                    width={100}
                    height={100}
                    className='object-cover h-10 w-10 md:w-12 md:h-12 xl:w-16 xl:h-16 rounded-full '
                  />
                </div>
                <p
                  className={`${
                    currentpage === 'home'
                      ? 'font-black text-xl md:text-2xl lg:text-3xl text-white'
                      : 'font-black text-xl md:text-2xl lg:text-3xl text-babyblack'
                  }`}
                >
                  MightyKens
                </p>
              </div>
            </Link>
          )}
        </div>
        {/* <!-- Menu Items --> */}
        <div
          className={`${
            currentpage === 'home'
              ? 'hidden items-center font-sans  font-bold  space-x-8 lg:space-x-12 xl:space-x-20  2xl:space-x-24 text-white md:flex justify-center normal-case'
              : 'hidden items-center font-sans  font-bold  space-x-8 lg:space-x-12 xl:space-x-20  2xl:space-x-24 text-babyblack md:flex justify-center normal-case'
          }`}
        >
          <ul className='text-xs md:text-sm lg:text-base xl:text-lg cursor-pointer'>
            <Link to='/Aboutus'>About us</Link>
          </ul>
          <ul className='text-xs md:text-sm lg:text-base xl:text-lg  cursor-pointer'>
            <Link to='/Allproducts'>All Products</Link>
          </ul>
          <ul className='text-xs md:text-sm lg:text-base xl:text-lg  cursor-pointer'>
            <Link to='/Brands'>Brands</Link>
          </ul>
        </div>
        <div
          className={`${
            currentpage === 'home'
              ? 'sm:px-2 md:px-4 lg:px-6 py-2 lg:py-3 text-babyblack bg-white  rounded-md shadow-md cursor-pointer hidden md:flex '
              : 'sm:px-2 md:px-4 lg:px-6 py-2 lg:py-3 text-babyblack bg-babyblack  rounded-md shadow-md cursor-pointer hidden md:flex '
          }`}
        >
          <p
            className={`${
              currentpage === 'home'
                ? 'text-xs md:text-sm lg:text-base xl:text-lg font-bold text-babyblack'
                : 'text-xs md:text-sm lg:text-base xl:text-lg font-bold text-white'
            }`}
          >
            <a
              href='https://wa.me/2347010347348'
              target='_blank'
              rel='noopener noreferrer'
            >
              Chat with Us
            </a>
          </p>
        </div>

        {/* <!-- Hamburger Button --> */}
        {pannel ? (
          <button
            id='menu-btn'
            className='z-30 open block md:hidden focus:outline-none hamburger'
            onClick={() => setPannel(!pannel)}
          >
            <span className='hamburger-top '></span>
            <span className='hamburger-middle'></span>
            <span className='hamburger-bottom'></span>
          </button>
        ) : (
          <button
            id='menu-btn'
            className='z-30 block md:hidden focus:outline-none hamburger'
            onClick={() => setPannel(!pannel)}
          >
            <span
              className={`${
                currentpage === 'home' ? 'hamburger-top' : 'pagehamburger-top'
              }`}
            ></span>
            <span
              className={`${
                currentpage === 'home'
                  ? 'hamburger-middle'
                  : 'pagehamburger-middle'
              }`}
            ></span>
            <span
              className={`${
                currentpage === 'home'
                  ? 'hamburger-bottom'
                  : 'pagehamburger-bottom'
              }`}
            ></span>
          </button>
        )}
      </div>
      {/* <!-- Mobile Menu --> */}
      {pannel ? (
        <div
          id='menu'
          className='fixed inset-0 z-20  flex-col items-center self-end w-full h-full m-h-screen px-6 py-1 pt-32 pb-4 tracking-widest text-white uppercase divide-y divide-gray-500 bg-opacity-95 bg-babyblack'
        >
          <div className='w-full py-3 text-center'>
            <ul className='text-sm md:text-sm cursor-pointer'>
              <Link to='/'>Home</Link>
            </ul>
          </div>
          <div className='w-full py-3 text-center'>
            <ul className='text-sm md:text-sm cursor-pointer'>
              <Link to='/Aboutus'>About Us</Link>
            </ul>
          </div>
          <div className='w-full py-3 text-center'>
            <ul className='text-sm md:text-sm cursor-pointer'>
              <Link to='/Allproducts'>All Products</Link>
            </ul>
          </div>
          <div className='w-full py-3 text-center'>
            <ul className='text-sm md:text-sm cursor-pointer'>
              <Link to='/Brands'>Brands</Link>
            </ul>
          </div>
        </div>
      ) : (
        ''
      )}
    </nav>
  )
}

export default Header
